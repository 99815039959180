import React, { useState, useEffect } from "react";
import "../App.css";
import { useAuth } from "../context/AuthContext";
import logo from "../img/logo.png";
import imgcarton from "../img/tabla.png";
import imgpremio1 from "../img/premio1.png";
import imgpremio2 from "../img/premio2.png";
import imgpremio3 from "../img/premio3.png";
import imgpremio4 from "../img/premiogordo.png";
import imgmonto from "../img/premiomonto.png";
import imagensala1 from "../img/sala1.png"
import imagensala2 from "../img/sala2.png" 
import imagensala5 from "../img/torneosala.png" 
import imagensala6 from "../img/arca.png"
import imagensala7 from "../img/olla.png"
import useSound from "use-sound";
import flecha from "../img/flecha.gif";
import whatsapp from "../img/whatsapp.png"
import animacion1 from "../img/animacion1.gif";
import animacion2 from "../img/animacion0.gif";

import letrero from "../img/letrero.png.png";

import { Button, Drawer, Radio, Space, Popover } from "antd";
import { ConfigProvider, Flex, Popconfirm } from "antd";

import { Select } from "antd";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//IMPORTAR SONIDOS
import popoff from "../sound/pop-up-off.mp3";
import popon from "../sound/pop-up-on.mp3";
import like from "../sound/rising-pops.mp3";
import bite from "../sound/bite.mp3";
import menuopen from "../sound/menu-open.mp3";
import bienvenido from "../sound/clases/bienvenido.mp3";

import imgtrebol from "../img/trebol1.png"
import imgestrella from "../img/estrella.png"

import { db } from "../firebaseConfig/db";
import { collection } from "firebase/firestore";
import {Axios} from 'axios';
import {
  useCollectionData,
  useCollection,
  useFirestore,
} from "react-firebase-hooks/firestore";
import { doc, setDoc, addDoc, getDocs, deleteDoc } from "@firebase/firestore";

import Confetti from "react-confetti";
import Carton from "../componentes/carton";
import Mostrarcarton from "../componentes/mostrarcarton";

import Swal from 'sweetalert2'
import io from "socket.io-client";
import Temporizador from "../componentes/temporizador";
import Menudeopciones from "./menudeopciones";
const socket = io(process.env.REACT_APP_SOCKET);

const text = <span>Configuracion de Cuenta</span>;



const content = (
  <Menudeopciones />
);

const content2 = (
  <div className="flex flex-row">
    <img src="https://royalinsurancellc.com/bingo/libre1.jpg" className=" border-2 border-gray-500 rounded-lg" width="40px" height="40px" alt="" />
    <img src="https://royalinsurancellc.com/bingo/libre2.jpg" className="ml-2 border-2 border-gray-500 rounded-lg" width="40px" height="35px" alt="" />
    <img src="https://royalinsurancellc.com/bingo/libre3.jpg" className="ml-2 border-2 border-gray-500 rounded-lg" width="40px" height="35px" alt="" />
    <img src="https://royalinsurancellc.com/bingo/libre4.jpg" className="ml-2 border-2 border-gray-500 rounded-lg" width="40px" height="35px" alt="" />
    <img src="https://royalinsurancellc.com/bingo/libre5.jpg" className="ml-2 border-2 border-gray-500 rounded-lg" width="40px" height="35px" alt="" />
    <img src="https://royalinsurancellc.com/bingo/libre6.jpg" className="ml-2 border-2 border-gray-500 rounded-lg" width="40px" height="35px" alt="" />
  </div>
);

const Home2 = () => {
  const { width, height } = "100%";
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [activar, SetActivar] = useState(false);
  const [salir, setSalir] = useState(false);
  const [placement, setPlacement] = useState("bottom");
  

  const [activacion, setActivacion] = useState(0);
 

  if(document.getElementById("bodycolor").className === "body1"){
    document.getElementById("bodycolor").className = "body2";
  }


  const [comenta] = useSound(bite);
  const [principal] = useSound(menuopen);

  const msjsave = (mensajesave, tipodemensaje) => {
    if (tipodemensaje == "save") {
      toast.success(mensajesave, {});
    }
    if (tipodemensaje == "error") {
      toast.error(mensajesave, {});
    }
    if (tipodemensaje == "warning") {
      toast.warning(mensajesave, {});
    }
    if (tipodemensaje == "info") {
      toast.info(mensajesave, {});
    }
  };

  const mensaje = (value, tipo) => {
    msjsave(value, tipo);
  };


  function diasEntreFechas(fecha) {

    const fechaInicio = new Date();
    const fechaFin = new Date(fecha);
    
    const diferencia = fechaFin - fechaInicio;
    
    const dias = Math.ceil(diferencia / (1000 * 60 * 60 * 24));
    
    return parseInt(dias);
}

  const auth = useAuth();
  const { displayName } = auth.user;
  const { email } = auth.user;
  var primerNombre = "";
  if (displayName == undefined) {
  } else {
    let nombre = displayName;

    for (let i = 0; i < nombre.length; i++) {
      if (nombre[i] === " ") {
        i = nombre.length;
      } else {
        primerNombre += nombre[i];
      }
    }
  }
  const photoURL = auth.user.photoURL;
  //console.log(auth.user.photoURL);

  const handleGoogle = (e) => {
    e.preventDefault();
    auth.loginWithGoogle();
  };
  const handleLogout = () => {
    auth.logout();
  };

  const query = collection(db, `cartones/${email}/miscartones`);
  const [docs, loading, error] = useCollectionData(query);
  const [totalp, setTotalp] = useState(0);
  const [totalc, setTotalc] = useState(0);

  const [carton, setCarton] = useState([]);
  const [numcarton, setNumcarton] = useState();
  const [actualizarcarton, setActualizarcarton] = useState(false);
  const [juego, setJuego] = useState([]);
  const [juegos, setJuegos] = useState([]);
  const [relojmaracaibo, setRelojmaracaibo] = useState("");
  const [relojjuego, setRelojjuego] = useState("");
  const [relojmerida, setRelojmerida] = useState("");
  const [relojcaracas, setRelojcaracas] = useState("");
  const [relojsaloangel, setRelojsaltoangel] = useState("");
  const [relojlibre, setRelojlibre] = useState("");
  const [saldo, setSaldo] = useState(0);
  const [cupos, setCupos] = useState(0);
  const [cuposr, setCuposR] = useState(0);


  const [users, setUsers] = useState([]);
  const [totales, setTotales] = useState([]);

  const [totalcomprados, setTotalcomprados] = useState(0);
  const [totalregalos, setTotalregalos] = useState(0);
  const [totalusuarios, setTotalusuarios] = useState(0);
  const [totalprecio, setTotalprecio] = useState(0);

  const [activaintervalo, setActivaintervalo] = useState(false)

  const [showModal2, setShowModal2] = useState(false)


  const registraruser = async () => {
    
    if (email != undefined) {
      const datos = {
        email: email,
        nombre: displayName,
        photo: photoURL,
        referido: "enmarycarrasco@gmail.com",
        usuario: ""
      };

      registrarusuario(datos)
    }
  };


  const registrarusuario = async (datos) => {

   
    //enviar datos
    fetch(`${process.env.REACT_APP_BASE}/nuevouser`, {
    method: 'POST',
    headers: {
    'Content-Type': 'application/json',
    },
    body: JSON.stringify(datos),
    })
    .then((response) => response.json())
    .then((result) => {
    if (result) {
    //console.log(result);
    } else {
    //console.log('ERROR DE CONEXION');
    }
    })
    .catch((error) => {
    console.log(error);
    });
    //fin de envio
 

  }

  const chequearexistencia = async () => {

    const datos = {
        email: email,
    }

     //enviar datos
     fetch(`${process.env.REACT_APP_BASE}/chequearexistencia`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify(datos),
        })
        .then((response) => response.json())
        .then((result) => {
        if (result) {
            if(result.message == "Si existe"){
                //console.log(result.message)
                setSaldo(result.saldo)
                setCupos(result.cupos)
                setCuposR(result.cupos_regalo)
            }
            else{
                //console.log("no existe")
               registraruser()
            }
        } else {
        //console.log('ERROR DE CONEXION');
        }
        })
        .catch((error) => {
        console.log(error);
        });
        //fin de envio

  }

  const obtenerjuego = (sala, tipo) => {
    //console.log("LLEGANDO JUEGOS")
    //console.log(juegos)
    for (let i = 0; i < juegos.length; i++) {
      if (juegos[i].nombre === sala) {
        if (tipo === "premio") {
          return juegos[i].premio;
        }
        if (tipo === "estatus") {
          return juegos[i].estatus;
        }
        if (tipo === "fecha") {
          return juegos[i].fecha;
        }
        if (tipo === "precio") {
          return juegos[i].precio;
        }
      }
    }
  };


  const comprarentrada = (email) => {

    alert(email)

    

    alert("llego")

    const datos = {
      email: email,
  }

     //enviar datos
     fetch(`${process.env.REACT_APP_BASE}/comprarentrada`, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json',
      },
      body: JSON.stringify(datos),
      })
      .then((response) => response.json())
      .then((result) => {
      if (result) {
      //console.log(result.message);
      if(result.message == "Compra Exitosa"){
 
        msjsave("Compra Exitosa, Tienes Acceso al Torneo", "save")

          listadejuegos();
          chequearexistencia();
          setShowModal2(false)
      }else{
        if(result.message == "Saldo Insuficiente"){
          msjsave("No tienes Fondos Suficientes, Recarga tu Saldo", "error")
  
          chequearexistencia()
          setShowModal2(false)
        }
        if(result.message == "No disponible"){
          msjsave("Ya estas Inscrito, Puede Entrar", "error")
        
          setShowModal2(false)
        }
        if(result.message == "Sala Cerrada"){
          msjsave("ENTRADA AL TORNEO Cerrada", "error")
          
          listadejuegos();
          setShowModal2(false)
        }
      }
      } else {
      //console.log('ERROR DE CONEXION');
      }
      })
      .catch((error) => {
      //console.log(error);
      });
      //fin de envio

  }

  // const content = (
  //   <div>
  //     <p className=" font-semibold italic">
  //       {totales.length === 0 ? (
  //         <></>
  //       ) : (
  //         <>
  //           {totales[0].jugadores.map((val, key) => {
  //             return <div className="text-blue-700">* {val}</div>;
  //           })}
  //         </>
  //       )}
  //     </p>
  //   </div>
  // );

  const volver = () => {
    window.location.href = '/#/';
    window.location.reload();
  }

  const listadejuegos = async () => {
    
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE}/obtenerjuegos/`);
        if (!response.ok) {
            throw new Error('Error en la red');
        }
        const data = await response.json();
        if(data){
          setJuegos(data);
        //console.log(data);
        }else{
          console.log("ERROR");
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    //console.log("SE EJECUTO")
    if(activaintervalo){
      setActivaintervalo(false)
    }else{
      setActivaintervalo(true)
    }
};


const listadefiguras = async () => {
    
  try {
      const response = await fetch(`${process.env.REACT_APP_BASE}/obtenerfiguras/`);
      if (!response.ok) {
          throw new Error('Error en la red');
      }
      const data = await response.json();
      if(data.figuras){
       //console.log("FIGURAS")
      console.log(data.juegos);
      }else{
        //console.log("ERROR");
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
 
};

const abrirsala1 = () => {
  window.location.href = '/#/juego';
		window.location.reload();
}

const abrirsala2 = () => {
  window.location.href = '/#/marc';
		window.location.reload();
}

const abrirsala3 = () => {
  window.location.href = '/#/libre';
		window.location.reload();
}

const abrirsala4 = () => {
  window.location.href = '/#/merc';
		window.location.reload();
}


const abrirsala5 = () => {
  window.location.href = '/#/animalitos';
		window.location.reload();
}

const abrirsala6 = () => {
  msjsave("Esperar que se Anuncie por Whatsapp","warning")
  // window.location.href = '/#/merc';
	// 	window.location.reload();
  //setShowModal2(true)
}


 const comprarcupos =  () => {

  const radios = document.getElementsByName('radio-examples');

// Inicializar una variable para almacenar el valor seleccionado
let promocion;
let content;

// Iterar sobre los radio buttons para encontrar el seleccionado
for (let i = 0; i < radios.length; i++) {
  if (radios[i].checked) {
    promocion = radios[i].value; // Obtener el valor del radio button seleccionado
    break; // Salir del bucle una vez que encontramos el seleccionado
  }
}

if(promocion === "promo1"){
content = "1 Carton por (10 Bs)"
}

if(promocion === "promo2"){
  content = "5 Cartones + 1 Regalo por (50 Bs)"
  }
  if(promocion === "promo3"){
    content = "8 Cartones + 2 Regalos por (80 Bs)"
    }
    if(promocion === "promo4"){
      content = "10 Cartones + 5 Regalos por (100 Bs)"
      }


  Swal.fire({
       title: `Desea Comprar Cupos de?`
       ,
       text: content,
       icon: "warning",
       showCancelButton: true,
       confirmButtonColor: "#3085d6",
       cancelButtonColor: "#d33",
       confirmButtonText: "Comprar Cupos"
     }).then((result) => {
       if (result.isConfirmed) {
        //comprando()
        comprarcuposahora(promocion)
     
        
         
 
       }
     });
 
 }
 
 const comprarcuposahora = (promocion) => {


  const datos = {
    email: email,
    promocion: promocion
}

   //enviar datos
   fetch(`${process.env.REACT_APP_BASE}/compracupos`, {
    method: 'POST',
    headers: {
    'Content-Type': 'application/json',
    },
    body: JSON.stringify(datos),
    })
    .then((response) => response.json())
    .then((result) => {
    if (result) {

     
      if(result.message === "Compra Exitosa"){
        Swal.fire({
          title: "Cupos Asignados",
          text: "Hemos Agregado los Cartones, Ahora Elija los de su preferencia",
          icon: "success"
        });
        chequearexistencia()
      }
      if(result.message === "Saldo Insuficiente"){
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Su Saldo es Insuficiente",
          footer: '<a href="/#/recarga">Recarga Ahora</a>'
        });
        chequearexistencia()
      }

      if(result.message !== "Saldo Insuficiente" && result.message !== "Compra Exitosa"){
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error de Conexion, Intenta de Nuevo",
        });
      }



    }

    })
    .catch((error) => {
    //console.log(error);
    });
    //fin de envio

 }

const abrirhome = () => {
  window.location.href = '/#/';
		window.location.reload();
}


const abrirsala10 = () => {
    window.location.href = '/#/sala3';
          window.location.reload();
  }

  const abrirsala7 = () => {
    window.location.href = '/#/sala4';
          window.location.reload();
  }

  const abrirsala8 = () => {
    window.location.href = '/#/sala1';
          window.location.reload();
  }

  const abrirsala9 = () => {
    window.location.href = '/#/sala2';
          window.location.reload();
  }




  useEffect(() => {
    
    if (email != undefined) {
      chequearexistencia();
    }
    

  }, [email]);

  useEffect(() => {
   listadejuegos();
   listadefiguras();
  }, []);

  // useEffect(() => {
  //   // Obtener la URL actual
  //   const currentUrl = window.location.href;

  //   // Verificar si la URL contiene el parámetro fbclid
  //   if (currentUrl.includes('fbclid')) {
  //     // Crear una nueva URL sin el parámetro fbclid
  //     const baseUrl = currentUrl.split('?')[0];
  //     const queryString = currentUrl.split('?')[1];

  //     // Si hay otros parámetros, filtrar el fbclid
  //     const newQueryString = queryString
  //       ? queryString
  //           .split('&')
  //           .filter(param => !param.startsWith('fbclid'))
  //           .join('&')
  //       : '';

  //     // Construir la nueva URL
  //     const newUrl = newQueryString ? `${baseUrl}?${newQueryString}` : baseUrl;

  //     // Actualizar la URL sin recargar la página
  //     window.history.replaceState(null, '', newUrl);
  //   }
  // }, []);

  // useEffect(() => {
  //   // Verificar si la URL es HTTP
  //   if (window.location.protocol !== 'https:') {
  //     // Redirigir a la versión HTTPS
  //     window.location.href = window.location.href.replace('http://', 'https://');
  //   }
  // }, []);


  useEffect(() => {
    // setInterval(() => {
    //   listadejuegos()
    // }, 10000);
  }, [activaintervalo]);


  return (
    <>
      <div className="container2 w-screen">
      <div className=" p-2 h-28 w-full flex place-content-between items-center">
        <div className="m-0 flex flex-wrap flex-col place-content-around">
          
              <img
              onClick={() => {
              abrirhome();
              }}
                className="pt-2 mt-[-10px] cursor-pointer"
                src={logo}
                width="300px"
                height="100%"
                alt="logo"
              />
            
            <p className="p-1"></p>
          </div>

          {displayName == undefined ? (
            <button
              type="button"
              onClick={(e) => handleGoogle(e)}
              class="text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 me-2 mb-2"
            >
              <svg
                class="w-4 h-4 me-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 19"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z"
                  clip-rule="evenodd"
                />
              </svg>
              Entrar
            </button>
          ) : (
            <div className="flex">
              <div className="p-2">
                <div className="flex flex-col items-end gap-4 ">
                
                <div>
                <div className="text-white text-[14px] font-medium text-left ml-6 mt-[-5px]">
                      {displayName && <> {primerNombre} </>}
                    </div>
                    <div className="bg-blue-950 w-[175px] h-10 rounded-lg grid grid-cols-3 gap-3" >

                  <img
                    onClick={() => {
                      setSalir(true);
                    }}
                    className="w-10 h-10 ml-[-5px]  rounded-full border-2 border-blue-500"
                    src={`${photoURL}`}
                    alt=""
                  />
                  <div className="bg-blue-950 w-[60px] h-10 ml-[-15px] pt-2">
                    <span className="text-[12px] relative"><svg class=" absolute w-[24px] ml-[-4px] h-[24px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8H5m12 0a1 1 0 0 1 1 1v2.6M17 8l-4-4M5 8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.6M5 8l4-4 4 4m6 4h-4a2 2 0 1 0 0 4h4a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1Z"/>
</svg>

 <span className="absolute mt-[0px] w-16 ml-[22px] bg-gray-600 rounded-xl p-1 pl-2 pr-2 text-[12px] font-medium shadow-md">{parseInt(saldo)} Bs</span></span>
                    
                  </div>
                  <div className="bg-blue-950 rounded-r-lg flex flex-row place-content-center mt-[6px]">
                  <Popover placement="bottomRight" title="Configuración de Cuenta" content={content}>
            <svg class="w-[32px] h-[32px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M17 10v1.126c.367.095.714.24 1.032.428l.796-.797 1.415 1.415-.797.796c.188.318.333.665.428 1.032H21v2h-1.126c-.095.367-.24.714-.428 1.032l.797.796-1.415 1.415-.796-.797a3.979 3.979 0 0 1-1.032.428V20h-2v-1.126a3.977 3.977 0 0 1-1.032-.428l-.796.797-1.415-1.415.797-.796A3.975 3.975 0 0 1 12.126 16H11v-2h1.126c.095-.367.24-.714.428-1.032l-.797-.796 1.415-1.415.796.797A3.977 3.977 0 0 1 15 11.126V10h2Zm.406 3.578.016.016c.354.358.574.85.578 1.392v.028a2 2 0 0 1-3.409 1.406l-.01-.012a2 2 0 0 1 2.826-2.83ZM5 8a4 4 0 1 1 7.938.703 7.029 7.029 0 0 0-3.235 3.235A4 4 0 0 1 5 8Zm4.29 5H7a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h6.101A6.979 6.979 0 0 1 9 15c0-.695.101-1.366.29-2Z" clip-rule="evenodd"/>
</svg>
          </Popover>
                  
                  

                  </div>
                  </div>
                </div>
                  
                  

                  


                </div>
              </div>

              <div></div>
            </div>
          )}
        </div>
      </div>

    
          
            <br />
            <div>
      
        <div className="flex flex-row place-content-end text-[20px] mr-6">
          <div className="flex flex-row cursor-pointer" onClick={() => {
          volver();
          }}>
          <img src="https://royalinsurancellc.com/bingo/home.png" className="imagen3 z-10" width="40px" alt="" />
          <span className="text-white fuente-comics bg-slate-800 p-1  rounded-xl mt-2 ml-[-5px] pr-4">&nbsp;&nbsp;Principal</span></div>
          </div>
      <div class=" flex flex-wrap justify-center mt-8">
        


      {obtenerjuego("sala8", "estatus") === "En Espera" ? (
          <div
           onClick={() => {
           abrirsala8();
           }}
            className=" relative m-4 h-40 w-40 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl border-[5px] border-[#1aff00] hover:bg-gradient-to-r hover:from-blue-500 hover:to-black hover:border-white hover:cursor-pointer"
          >
            {/* puede ir una imagen */}
            
            <div className="flex flex-col place-content-center">
              {/* <img src={imgpremio1} className=" rounded-tl-xl rounded-tr-xl" /> */}
              <img
                src={imagensala2}
                className=" rounded-tl-xl rounded-tr-xl "
              />
              <span className="flex flex-row place-content-center absolute mt-[55px] w-[118px] text-center p-1 bg-slate-600 rounded-md ml-[16px] text-md font-semibold text-white">
              <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7.171 12.906-2.153 6.411 2.672-.89 1.568 2.34 1.825-5.183m5.73-2.678 2.154 6.411-2.673-.89-1.568 2.34-1.825-5.183M9.165 4.3c.58.068 1.153-.17 1.515-.628a1.681 1.681 0 0 1 2.64 0 1.68 1.68 0 0 0 1.515.628 1.681 1.681 0 0 1 1.866 1.866c-.068.58.17 1.154.628 1.516a1.681 1.681 0 0 1 0 2.639 1.682 1.682 0 0 0-.628 1.515 1.681 1.681 0 0 1-1.866 1.866 1.681 1.681 0 0 0-1.516.628 1.681 1.681 0 0 1-2.639 0 1.681 1.681 0 0 0-1.515-.628 1.681 1.681 0 0 1-1.867-1.866 1.681 1.681 0 0 0-.627-1.515 1.681 1.681 0 0 1 0-2.64c.458-.361.696-.935.627-1.515A1.681 1.681 0 0 1 9.165 4.3ZM14 9a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"/>
</svg>
 {obtenerjuego("sala8", "premio")} Bs
            </span>
              <span className=" text-white text-center pt-1 font-semibold font-mono">
                {obtenerjuego("sala8", "estatus") == "Empezando" ? (
                  <>{relojjuego}</>
                ) : (
                  <>{diasEntreFechas(obtenerjuego("sala8", "fecha")) === 1 ? (<Temporizador tiempo1={obtenerjuego("sala8", "fecha")} />) : (<div>Faltan {diasEntreFechas(obtenerjuego("sala8", "fecha"))} Dias</div>)}</>
                )}
              </span>
            </div>
          </div>
        ) : (
         
            <div onClick={() => {
              abrirsala8();
              }} className="  relative m-4 h-40 w-40 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl border-[5px] border-[#1aff00] hover:bg-gradient-to-r hover:from-blue-500 hover:to-black hover:border-white hover:cursor-pointer">
              
              <div className="flex flex-col place-content-center">
                {/* <img
                  src={imgpremio1}
                  className=" rounded-tl-xl rounded-tr-xl"
                /> */}
                <img
                  src={imagensala2}
                  className=" rounded-tl-xl rounded-tr-xl"
                />
                <span className="flex flex-row place-content-center absolute mt-[55px] w-[118px] text-center p-1 bg-slate-600 rounded-md ml-[16px] text-md font-semibold text-white">
              <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7.171 12.906-2.153 6.411 2.672-.89 1.568 2.34 1.825-5.183m5.73-2.678 2.154 6.411-2.673-.89-1.568 2.34-1.825-5.183M9.165 4.3c.58.068 1.153-.17 1.515-.628a1.681 1.681 0 0 1 2.64 0 1.68 1.68 0 0 0 1.515.628 1.681 1.681 0 0 1 1.866 1.866c-.068.58.17 1.154.628 1.516a1.681 1.681 0 0 1 0 2.639 1.682 1.682 0 0 0-.628 1.515 1.681 1.681 0 0 1-1.866 1.866 1.681 1.681 0 0 0-1.516.628 1.681 1.681 0 0 1-2.639 0 1.681 1.681 0 0 0-1.515-.628 1.681 1.681 0 0 1-1.867-1.866 1.681 1.681 0 0 0-.627-1.515 1.681 1.681 0 0 1 0-2.64c.458-.361.696-.935.627-1.515A1.681 1.681 0 0 1 9.165 4.3ZM14 9a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"/>
</svg>
 {obtenerjuego("sala8", "premio")} Bs
            </span>
                <span className=" text-white text-center pt-1 font-semibold font-mono">
                  {obtenerjuego("sala8", "estatus") == "Empezando" ? (
                    <>{relojjuego}</>
                  ) : (
                    <>{obtenerjuego("sala8", "estatus")}</>
                  )}
                </span>
              </div>
            </div>
        
        )}


{obtenerjuego("sala9", "estatus") === "En Espera" ? (
          <div
           onClick={() => {
           abrirsala9();
           }}
            className=" relative m-4 h-40 w-40 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl border-[5px] border-[#1aff00] hover:bg-gradient-to-r hover:from-blue-500 hover:to-black hover:border-white hover:cursor-pointer"
          >
       
            <div className="flex flex-col place-content-center">
              {/* <img src={imgpremio1} className=" rounded-tl-xl rounded-tr-xl" /> */}
              <img src="https://firebasestorage.googleapis.com/v0/b/apphost-57318.appspot.com/o/estrella.png?alt=media&token=f4ab7fae-a7b2-4395-af12-efaea083b28c" className=" rounded-tl-xl rounded-tr-xl" />
              
              <span className="flex flex-row place-content-center absolute mt-[55px] w-[118px] text-center p-1 bg-slate-600 rounded-md ml-[16px] text-md font-semibold text-white">
              <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7.171 12.906-2.153 6.411 2.672-.89 1.568 2.34 1.825-5.183m5.73-2.678 2.154 6.411-2.673-.89-1.568 2.34-1.825-5.183M9.165 4.3c.58.068 1.153-.17 1.515-.628a1.681 1.681 0 0 1 2.64 0 1.68 1.68 0 0 0 1.515.628 1.681 1.681 0 0 1 1.866 1.866c-.068.58.17 1.154.628 1.516a1.681 1.681 0 0 1 0 2.639 1.682 1.682 0 0 0-.628 1.515 1.681 1.681 0 0 1-1.866 1.866 1.681 1.681 0 0 0-1.516.628 1.681 1.681 0 0 1-2.639 0 1.681 1.681 0 0 0-1.515-.628 1.681 1.681 0 0 1-1.867-1.866 1.681 1.681 0 0 0-.627-1.515 1.681 1.681 0 0 1 0-2.64c.458-.361.696-.935.627-1.515A1.681 1.681 0 0 1 9.165 4.3ZM14 9a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"/>
</svg>
 {obtenerjuego("sala9", "premio")} Bs
            </span>
              <span className=" text-white text-center pt-1 font-semibold font-mono">
                {obtenerjuego("sala9", "estatus") == "Empezando" ? (
                  <>{relojjuego}</>
                ) : (
                  <>{diasEntreFechas(obtenerjuego("sala9", "fecha")) === 1 ? (<Temporizador tiempo1={obtenerjuego("sala9", "fecha")} />) : (<div>Faltan {diasEntreFechas(obtenerjuego("sala9", "fecha"))} Dias</div>)}</>
                )}
              </span>
            </div>
          </div>
        ) : (

            <div onClick={() => {
              abrirsala9();
              }} className="  relative m-4 h-40 w-40 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl border-[5px] border-[#1aff00] hover:bg-gradient-to-r hover:from-blue-500 hover:to-black hover:border-white hover:cursor-pointer">
              
              <div className="flex flex-col place-content-center">
                {/* <img
                  src={imgpremio1}
                  className=" rounded-tl-xl rounded-tr-xl"
                /> */}
                <img src="https://firebasestorage.googleapis.com/v0/b/apphost-57318.appspot.com/o/estrella.png?alt=media&token=f4ab7fae-a7b2-4395-af12-efaea083b28c" className=" rounded-tl-xl rounded-tr-xl" />
                <span className="flex flex-row place-content-center absolute mt-[55px] w-[118px] text-center p-1 bg-slate-600 rounded-md ml-[16px] text-md font-semibold text-white">
              <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7.171 12.906-2.153 6.411 2.672-.89 1.568 2.34 1.825-5.183m5.73-2.678 2.154 6.411-2.673-.89-1.568 2.34-1.825-5.183M9.165 4.3c.58.068 1.153-.17 1.515-.628a1.681 1.681 0 0 1 2.64 0 1.68 1.68 0 0 0 1.515.628 1.681 1.681 0 0 1 1.866 1.866c-.068.58.17 1.154.628 1.516a1.681 1.681 0 0 1 0 2.639 1.682 1.682 0 0 0-.628 1.515 1.681 1.681 0 0 1-1.866 1.866 1.681 1.681 0 0 0-1.516.628 1.681 1.681 0 0 1-2.639 0 1.681 1.681 0 0 0-1.515-.628 1.681 1.681 0 0 1-1.867-1.866 1.681 1.681 0 0 0-.627-1.515 1.681 1.681 0 0 1 0-2.64c.458-.361.696-.935.627-1.515A1.681 1.681 0 0 1 9.165 4.3ZM14 9a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"/>
</svg>
 {obtenerjuego("sala9", "premio")} Bs
            </span>
                <span className=" text-white text-center pt-1 font-semibold font-mono">
                  {obtenerjuego("sala9", "estatus") == "Empezando" ? (
                    <>{relojjuego}</>
                  ) : (
                    <>{obtenerjuego("sala9", "estatus")}</>
                  )}
                </span>
              </div>
            </div>
        
        )}




{obtenerjuego("sala10", "estatus") === "En Espera" ? (
          <div
           onClick={() => {
           abrirsala10();
           }}
            className="mt-4 relative m-4 h-40 w-40 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl border-[5px] border-[#1aff00] hover:bg-gradient-to-r hover:from-blue-500 hover:to-black hover:border-white hover:cursor-pointer"
          >
          
            <div className="flex flex-col place-content-center">
              {/* <img src={imgpremio1} className=" rounded-tl-xl rounded-tr-xl" /> */}
              <img
                src={imagensala6}
                className=" rounded-tl-xl rounded-tr-xl"
              />
              <span className="flex flex-row place-content-center absolute mt-[55px] w-[118px] text-center p-1 bg-slate-600 rounded-md ml-[16px] text-md font-semibold text-white">
              <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7.171 12.906-2.153 6.411 2.672-.89 1.568 2.34 1.825-5.183m5.73-2.678 2.154 6.411-2.673-.89-1.568 2.34-1.825-5.183M9.165 4.3c.58.068 1.153-.17 1.515-.628a1.681 1.681 0 0 1 2.64 0 1.68 1.68 0 0 0 1.515.628 1.681 1.681 0 0 1 1.866 1.866c-.068.58.17 1.154.628 1.516a1.681 1.681 0 0 1 0 2.639 1.682 1.682 0 0 0-.628 1.515 1.681 1.681 0 0 1-1.866 1.866 1.681 1.681 0 0 0-1.516.628 1.681 1.681 0 0 1-2.639 0 1.681 1.681 0 0 0-1.515-.628 1.681 1.681 0 0 1-1.867-1.866 1.681 1.681 0 0 0-.627-1.515 1.681 1.681 0 0 1 0-2.64c.458-.361.696-.935.627-1.515A1.681 1.681 0 0 1 9.165 4.3ZM14 9a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"/>
</svg>
 {obtenerjuego("sala10", "premio")} Bs
            </span>
              <span className=" text-white text-center pt-1 font-semibold font-mono">
                {obtenerjuego("sala10", "estatus") == "Empezando" ? (
                  <>{relojjuego}</>
                ) : (
                  <>{diasEntreFechas(obtenerjuego("sala10", "fecha")) === 1 ? (<Temporizador tiempo1={obtenerjuego("sala10", "fecha")} />) : (<div>Faltan {diasEntreFechas(obtenerjuego("sala10", "fecha"))} Dias</div>)}</>
                )}
              </span>
            </div>
          </div>
        ) : (
         
            <div onClick={() => {
           abrirsala10();
           }} className="mt-4 relative m-4 h-40 w-40 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl border-[5px] border-[#1aff00] hover:bg-gradient-to-r hover:from-blue-500 hover:to-black hover:border-white hover:cursor-pointer">
             
              <div className="flex flex-col place-content-center">
                {/* <img
                  src={imgpremio1}
                  className=" rounded-tl-xl rounded-tr-xl"
                /> */}
                <img
                  src={imagensala6}
                  className=" rounded-tl-xl rounded-tr-xl"
                />
                <span className="flex flex-row place-content-center absolute mt-[55px] w-[118px] text-center p-1 bg-slate-600 rounded-md ml-[16px] text-md font-semibold text-white">
              <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7.171 12.906-2.153 6.411 2.672-.89 1.568 2.34 1.825-5.183m5.73-2.678 2.154 6.411-2.673-.89-1.568 2.34-1.825-5.183M9.165 4.3c.58.068 1.153-.17 1.515-.628a1.681 1.681 0 0 1 2.64 0 1.68 1.68 0 0 0 1.515.628 1.681 1.681 0 0 1 1.866 1.866c-.068.58.17 1.154.628 1.516a1.681 1.681 0 0 1 0 2.639 1.682 1.682 0 0 0-.628 1.515 1.681 1.681 0 0 1-1.866 1.866 1.681 1.681 0 0 0-1.516.628 1.681 1.681 0 0 1-2.639 0 1.681 1.681 0 0 0-1.515-.628 1.681 1.681 0 0 1-1.867-1.866 1.681 1.681 0 0 0-.627-1.515 1.681 1.681 0 0 1 0-2.64c.458-.361.696-.935.627-1.515A1.681 1.681 0 0 1 9.165 4.3ZM14 9a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"/>
</svg>
 {obtenerjuego("sala10", "premio")} Bs
            </span>
                <span className=" text-white text-center pt-1 font-semibold font-mono">
                  {obtenerjuego("sala10", "estatus") == "Empezando" ? (
                    <>{relojjuego}</>
                  ) : (
                    <>{obtenerjuego("sala10", "estatus")}</>
                  )}
                </span>
              </div>
            </div>
         
        )}

      {obtenerjuego("sala7", "estatus") === "En Espera" ? (
          <div
           onClick={() => {
           abrirsala7();
           }}
            className="mt-4 relative m-4 h-40 w-40 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl border-[5px] border-[#1aff00] hover:bg-gradient-to-r hover:from-blue-500 hover:to-black hover:border-white hover:cursor-pointer"
          >
             <img className="imagent absolute mt-[-30px] ml-[100px]" width="60px" src={imgtrebol} alt="" />
            <div className="flex flex-col place-content-center">
              {/* <img src={imgpremio1} className=" rounded-tl-xl rounded-tr-xl" /> */}
              <img
                src={imagensala1}
                className=" rounded-tl-xl rounded-tr-xl"
              />
              <span className="flex flex-row place-content-center absolute mt-[55px] w-[118px] text-center p-1 bg-slate-600 rounded-md ml-[16px] text-md font-semibold text-white">
              <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7.171 12.906-2.153 6.411 2.672-.89 1.568 2.34 1.825-5.183m5.73-2.678 2.154 6.411-2.673-.89-1.568 2.34-1.825-5.183M9.165 4.3c.58.068 1.153-.17 1.515-.628a1.681 1.681 0 0 1 2.64 0 1.68 1.68 0 0 0 1.515.628 1.681 1.681 0 0 1 1.866 1.866c-.068.58.17 1.154.628 1.516a1.681 1.681 0 0 1 0 2.639 1.682 1.682 0 0 0-.628 1.515 1.681 1.681 0 0 1-1.866 1.866 1.681 1.681 0 0 0-1.516.628 1.681 1.681 0 0 1-2.639 0 1.681 1.681 0 0 0-1.515-.628 1.681 1.681 0 0 1-1.867-1.866 1.681 1.681 0 0 0-.627-1.515 1.681 1.681 0 0 1 0-2.64c.458-.361.696-.935.627-1.515A1.681 1.681 0 0 1 9.165 4.3ZM14 9a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"/>
</svg>
 {obtenerjuego("sala7", "premio")} Bs
            </span>
              <span className=" text-white text-center pt-1 font-semibold font-mono">
                {obtenerjuego("sala7", "estatus") == "Empezando" ? (
                  <>{relojjuego}</>
                ) : (
                  <>{diasEntreFechas(obtenerjuego("sala7", "fecha")) === 1 ? (<Temporizador tiempo1={obtenerjuego("sala7", "fecha")} />) : (<div>Faltan {diasEntreFechas(obtenerjuego("sala7", "fecha"))} Dias</div>)}</>
                )}
              </span>
            </div>
          </div>
        ) : (
          
          
            <div onClick={() => {
              abrirsala7();
              }} className="mt-4 relative m-4 h-40 w-40 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl border-[5px] border-[#1aff00] hover:bg-gradient-to-r hover:from-blue-500 hover:to-black hover:border-white hover:cursor-pointer">
            <img className="imagent absolute mt-[-30px] ml-[100px]" width="60px" src={imgtrebol} alt="" />
              <div className="flex flex-col place-content-center">
                {/* <img
                  src={imgpremio1}
                  className=" rounded-tl-xl rounded-tr-xl"
                /> */}
                <img
                  src={imagensala1}
                  className=" rounded-tl-xl rounded-tr-xl"
                />
                
                <span className="flex flex-row place-content-center absolute mt-[55px] w-[118px] text-center p-1 bg-slate-600 rounded-md ml-[16px] text-md font-semibold text-white">
              <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7.171 12.906-2.153 6.411 2.672-.89 1.568 2.34 1.825-5.183m5.73-2.678 2.154 6.411-2.673-.89-1.568 2.34-1.825-5.183M9.165 4.3c.58.068 1.153-.17 1.515-.628a1.681 1.681 0 0 1 2.64 0 1.68 1.68 0 0 0 1.515.628 1.681 1.681 0 0 1 1.866 1.866c-.068.58.17 1.154.628 1.516a1.681 1.681 0 0 1 0 2.639 1.682 1.682 0 0 0-.628 1.515 1.681 1.681 0 0 1-1.866 1.866 1.681 1.681 0 0 0-1.516.628 1.681 1.681 0 0 1-2.639 0 1.681 1.681 0 0 0-1.515-.628 1.681 1.681 0 0 1-1.867-1.866 1.681 1.681 0 0 0-.627-1.515 1.681 1.681 0 0 1 0-2.64c.458-.361.696-.935.627-1.515A1.681 1.681 0 0 1 9.165 4.3ZM14 9a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"/>
</svg>
 {obtenerjuego("sala7", "premio")} Bs
            </span>
                <span className=" text-white text-center pt-1 font-semibold font-mono">
                  {obtenerjuego("sala7", "estatus") == "Empezando" ? (
                    <>{relojjuego}</>
                  ) : (
                    <>{obtenerjuego("sala7", "estatus")}</>
                  )}
                </span>
              </div>
            </div>
          
          
        )}






      

        
      </div>

      <div class=" flex flex-wrap justify-center mt-8">
      
      </div>

      <br />
      <br />
      
      <br />

      <div class="h-[20px] relative bg-blue-600 flex flex-row place-content-center">
        <div className="ml-[-100px] absolute flex flex-row mt-[-30px]">
          <img
            src={letrero}
            alt=""
            height="140px"
            width="140px"
            className="mt-[-80px] relative"

          />
          <span className="absolute ml-10 mt-[-47px] text-border text-[18px] text-white text-center" style={{lineHeight: '1.1'}}>Trebol de la <br />SUERTE</span>
          
          

          
        
       
         
        </div>
        <div className="absolute mt-[-10px] ml-[100px] flex flex-row">
            <svg
              class="w-[32px] h-[32px] text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
                clip-rule="evenodd"
              />
            </svg>

            <span class=" inline-flex items-center bg-slate-800 justify-center w-6 h-6 ml-[-10px] mt-[-10px] text-xs font-semibold text-white  rounded-full">
              {cupos}
            </span>
          </div>
          <div className="absolute  mt-[-10px] ml-[200px] flex flex-row">
            <svg
              class="w-[32px] h-[32px] text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M20 7h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C10.4 2.842 8.949 2 7.5 2A3.5 3.5 0 0 0 4 5.5c.003.52.123 1.033.351 1.5H4a2 2 0 0 0-2 2v2a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V9a2 2 0 0 0-2-2Zm-9.942 0H7.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM13 14h-2v8h2v-8Zm-4 0H4v6a2 2 0 0 0 2 2h3v-8Zm6 0v8h3a2 2 0 0 0 2-2v-6h-5Z" />
            </svg>

            <span class=" inline-flex items-center bg-slate-800 justify-center w-6 h-6 ml-[-10px] mt-[-10px] text-xs font-semibold text-white  rounded-full">
             {cuposr}
            </span>
          </div>
      </div>
      



        <br />
        <br />
     
        <div className="relative rounded-xl p-6 m-4 bg-gradient-to-r from-[#0c20f4] via-[#087dff] to-[#0079ff] p-4 shadow-md">
              <img className="absolute mt-[-70px] ml-[-40px] rotar" src={imgestrella} width="100px" alt="" />

              <div class="radio-wrapper-21 mt-6">
  <input id="example-21" type="radio" value="promo1" name="radio-examples" />
  <label for="example-21">
    <span class="title">Comprar</span>
    <small><span class="description">1 Carton <span className="font-bold">(10 Bs)</span> </span></small>
  </label>
</div>

<div class="radio-wrapper-21 mt-2 w-full">
  <input id="example-22" type="radio" value="promo2" name="radio-examples" />
  <label for="example-22">
    <span class="title">Promoción 1</span>
    <small><span class="description">5 Cartones + 1 Regalo <span className="font-bold">(50 Bs)</span> </span></small>
  </label>
</div>


<div class="radio-wrapper-21 mt-2">
  <input id="example-23" type="radio" value="promo3" name="radio-examples" />
  <label for="example-23">
    <span class="title">Promoción 2</span>
    <small><span class="description">8 Cartones + 2 Regalos <span className="font-bold">(80 Bs)</span> </span></small>
  </label>
</div>

<div class="radio-wrapper-21 mt-2">
  <input id="example-24" type="radio" value="promo4" name="radio-examples" />
  <label for="example-24">
    <span class="title">Promoción 3</span>
    <small><span class="description">10 Cartones + 5 Regalos <span className="font-bold">(100 Bs)</span> </span></small>
  </label>
</div>

<div className="flex flex-row place-content-center mt-4">
<button
                                  onClick={() => {
                                    comprarcupos();
                                  }}
                                  id="botoncomprar"
                                  type="button"
                                  class="text-white flex flex-row bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                                >
                                  <svg class="w-[24px] h-[24px] mt-[-4px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
</svg>

 <span>Comprar</span>
                                </button>
                                </div>
              </div>

      {/* <div className="flex flex-col m-4">

          <section class="accordion accordion--radio">
      <div class="tab">
        <input type="radio" name="accordion-2" id="rd1" />
        <label for="rd1" class="tab__label font-bold">¿ QUE ES BINGO ARCOIRIS ?</label>
        <div class="tab__content bg-yellow-200">
          <p className="font-bold text-gray-600">
            Es un sitio o aplicación web que te permite Jugar, Divertirte y Ganar premios con este juego tradicional de BINGO, con la oportunidad de participar con personas en tiempo real, tenemos diferentes salas con formas diferentes de ganar. <span className="text-[#ad0054]">CARTON LLENO, FIGURAS y LIBRE</span>.
          </p>
        </div>
      </div>
      <div class="tab">
        <input type="radio" name="accordion-2" id="rd2" />
        <label for="rd2" class="tab__label font-bold">¿ COMO JUGAR ?</label>
        <div class="tab__content bg-yellow-200">
          <div className="font-bold text-gray-600 flex flex-col">
          <p><div className="flex flex-row"><svg class="w-6 h-6 text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z" clip-rule="evenodd"/>
</svg>  <span className="text-[#ad0054]">ENTRA Ó INICIA SESIÓN</span> </div><span className="ml-4">En la parte superior derecha de la pantalla encontraras un boton que dice ENTRAR este boton te buscara los correos que tienes en tu dispositivos elige el que usaras para tu cuenta y al continuar se reflejan tus datos en pantalla.</span></p>

<p><div className="flex flex-row"><svg class="w-6 h-6 text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z" clip-rule="evenodd"/>
</svg>  <span className="text-[#ad0054]">RECARGA SALDO</span> </div><span className="ml-4">Para Recargar Saldo dirigete al Menu  donde encontraras esta opcion, alli encontraras datos del PAGO MOVIL, al hacer el pago coloca el numero de referencia que son los ultimos 6 digitos, y el monto a recargar, al hacer la solicitud espera que un administrador confirme el pago y tendras tu saldo reflejado en la parte superior. tambien puedes hacerlo desde nuestro grupo de whatsapp con simplemente enviar capture y indicar tu nombre el administrador te confirmara el pago. </span></p>

<p><div className="flex flex-row"><svg class="w-6 h-6 text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z" clip-rule="evenodd"/>
</svg>  <span className="text-[#ad0054]">COMPRAR CARTÓN</span> </div><span className="ml-4">Entra en la sala de tu preferencia, los cuales son CARTÓN LLENO, FIGURAS y LIBRE antes de entrar veras el costo del carton, una ves dentro te salen todos los cartones disponibles en un color azul, al tocarlo veras el carton en pantalla al comprarlo se te debitara del saldo. </span></p>

<p><div className="flex flex-row"><svg class="w-6 h-6 text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z" clip-rule="evenodd"/>
</svg>  <span className="text-[#ad0054]">A JUGAR</span> </div><span className="ml-4">Cada sala tiene un temporizador de inicio del juego, si hay 2 jugadores o mas el juego inicia, si solo hay un jugador el juego devuelve el dinero, el juego es automatizado, se reflejan tus cartones y se marca automatico, al tener la figura debes cantar BINGO con un botón que aparece debajo de cada cartón. </span></p>


<p><div className="flex flex-row"><svg class="w-6 h-6 text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z" clip-rule="evenodd"/>
</svg>  <span className="text-[#ad0054]">RECIBE TU PREMIO</span> </div><span className="ml-4">Si eres el ganador del juego, se refleja el premio sumado a tu saldo, si hay un empate se divide el dinero, el premio dependera de la cantidad de cartones comprados en cada sala el 80% del dinero. </span></p>


<p><div className="flex flex-row"><svg class="w-6 h-6 text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z" clip-rule="evenodd"/>
</svg>  <span className="text-[#ad0054]">RETIRAR DINERO</span> </div><span className="ml-4">De la misma forma que recargar saldo para retirar dinero, entra al Menu de opciones y dentro colocas tu pago movil y la cantidad a retirar de tu saldo, esta solicitud sera procesada en la mayor brevedad posible. </span></p>

          </div>
        </div>
      </div>
      <div class="tab">
        <input type="radio" name="accordion-2" id="rd3" />
        <label for="rd3" class="tab__label font-bold">CONOCE NUESTRAS SALAS</label>
        <div class="tab__content bg-yellow-200">
         <div className="flex flex-row">

         <img
              src="https://firebasestorage.googleapis.com/v0/b/apphost-57318.appspot.com/o/bingo%2Fcartonlleno.jpg?alt=media&token=af31af01-3e7d-4b22-9299-1b4952c0c2d8"
              className="m-2 rounded-lg border-2  h-[50px] bg-slate-300"
            />
          <div className="flex flex-col mt-2 font-bold text-gray-600">
                <div className="text-[#ad0054] flex flex-row"><svg class="w-6 h-6 text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z" clip-rule="evenodd"/>
</svg> CARTÓN LLENO</div>
                <div>En esta sala ya conocemos que para ganar debes tener todas las casillas marcadas en rojo, un total de 24 numeros acertados para poder ganar.</div>
                </div>
          
         </div>

         <div className="flex flex-row mt-2">

         <img
              src="https://royalinsurancellc.com/bingo/interrogante.png"
              className="m-2 rounded-lg border-2  h-[50px] bg-slate-300"
            />
          <div className="flex flex-col mt-2 font-bold text-gray-600">
                <div className="text-[#ad0054] flex flex-row"><svg class="w-6 h-6 text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z" clip-rule="evenodd"/>
</svg> FIGURAS ?</div>
                <div>Al iniciar la partida, se mostrara una figura aleatoria con la que se jugara, al tener la figura completada puedes cantar BINGO, tenemos una gran variedad de figuras, ejemplo: Letras (X, Z, T, D y mas), Otros: Escalera, Ancla, Tortuga, y muchos mas </div>
                </div>
          
         </div>

         <div className="flex flex-row mt-2">

         <Popover content={content2} placement="topRight" title="Formas de Ganar" trigger="click"><img
              src="https://royalinsurancellc.com/bingo/estrellalibre.png"
              className="m-2 rounded-lg border-2  h-[50px] bg-slate-300"
            /></Popover>
          <div className="flex flex-col mt-2 font-bold text-gray-600">
                <div className="text-[#ad0054] flex flex-row"><svg class="w-6 h-6 text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z" clip-rule="evenodd"/>
</svg> LIBRE</div>
                <div>Esta Sala tiene la particularidad de Ganar de Distintas Maneras es un clasico cantar BINGO al tener, Linea Vertical y Horizontal (todas), Diagonal (Todas), Cuatro Esquinas, Cruz Pequeña y Cruz Grande.</div>
                </div>
          
         </div>
         <div className="h-2"></div>
        </div>
      </div>
      <div class="tab">
        <input type="radio" name="accordion-2" id="rd4" />
        <label for="rd4" class="tab__label font-bold">REGLAMENTO AL CANTAR BINGO</label>
        <div class="tab__content bg-yellow-200">
          <div className="font-bold text-[#ad0054] p-4 flex flex-row">
          <svg class="w-6 h-6 text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z" clip-rule="evenodd"/>
</svg>  El Comportamiento de Nuestro Sistema es:
          </div>
         <div className="flex flex-row pl-4 font-bold text-gray-600">  <span> (1) El Lanzamiento de Cada Numero o Bolita es cada 7 Segundos.</span> </div>
<div className="h-2"></div>
<div className="flex flex-row pl-4 font-bold text-gray-600"> (2)  El Sistema detecta inmediatamente si un jugador tiene BINGO, por lo tanto asignara 7 segundos adicionales para que tenga tiempo para cantar bingo, de lo contrario si no lo hace en 14 segundos dejara pasar el BINGO. </div>
<div className="h-2"></div>
<div className="flex flex-row pl-4 font-bold text-gray-600"> (3)  El BINGO será siempre con la bolita actual, si el usuario deja pasar el bingo a pesar que el sistema le dara mas tiempo, el sistema le notificará que DEJÓ PASAR EL BINGO. </div>
<div className="h-2"></div>
<div className="flex flex-row pl-4 font-bold text-gray-600"> (4)  El Cartón tendra validez si deja pasar el bingo, solo para casos como SALA LIBRE que tiene mas formas de ganar con otras casillas ya que el ganador sera siempre con la ultima bolita cantada. </div>
<div className="h-2"></div>
<div className="flex flex-row pl-4 font-bold text-gray-600"> (5)  Luego que la persona cante bingo el sistema dará otros 10 segundos para esperar si hay otro ganador que tiene BINGO, en total serian 14+10 segundos, lo que esto hace que tengan mas chance de cantar, de lo contrario, ya no nos hacemos responsable si les falla el internet o no llegaron a tiempo. </div>
<div className="h-2"></div>

        </div>
      </div>
      <div class="tab">
        <input type="radio" name="accordion-2" id="rd5" />
        <label for="rd5" class="tab__label font-bold">TORNEO Y EVENTOS</label>

        <div class="tab__content bg-yellow-200">
         <div className="flex flex-row">

         <img
              src="https://royalinsurancellc.com/bingo/torneo.png"
              className="m-2 rounded-lg border-2  h-[50px] bg-slate-300"
            />
          <div className="flex flex-col mt-2 font-bold text-gray-600">
                <div className="text-[#ad0054] flex flex-row"><svg class="w-6 h-6 text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z" clip-rule="evenodd"/>
</svg> TORNEO</div>
                <div>Este Torneo consta de una maratón de BINGO, en las diferentes salas, cada sala con duracion de 90 minutos, cada jugador iniciara con el mismo puntaje 1000 pts al finalizar el tiempo el ganador con mayor puntaje recibira el premio del pote acumulado.</div>
                </div>
          
         </div>

         <div className="h-2"></div>
        </div>
    
      </div>
    </section>
          </div> */}

      <br />
      {/* <div className="flex flex-row place-content-center">
      <img src={flecha} width="80px" height="80px" alt="" />
      <a target="_blank" href="https://chat.whatsapp.com/ETDjFuHSbxZCuWzbaF9h6c"><button type="button" class="ml-4 text-white bg-[#2557D6] hover:bg-[#2557D6]/90 focus:ring-4 focus:ring-[#2557D6]/50 focus:outline-none font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center dark:focus:ring-[#2557D6]/50 me-2 mb-2">
     <img src={whatsapp} width="50px" height="50px" alt="" />
<span class="ml-2">Unete a Nuestro Grupo <br />WHATSAPP</span>
</button></a>

</div> */}
      <br />
      <br />
 
      </div>
   
      
      <ToastContainer theme="dark" />
      {salir ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="h-20 bg-blue-800 rounded-t-lg place-content-center flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <span className="mt-6 pl-2">Salir</span>
                </div>
                <div className="relative p-1 flex-auto">
                  <div className="p-1 md:p-1 text-center">
                    <div>
                      <div className="p-2 md:p-2 font-bold text-center">
                        Deseas Cerrar Sesi&oacute;n?
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-18 bg-blue-800 rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <button
                    type="button"
                    onClick={() => {
                      setSalir(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-gray-800 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    &nbsp;Cancelar
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      handleLogout();
                      setSalir(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-white bg-[#a51b1b] hover:bg-[#d12121]/90 focus:ring-4 focus:outline-none focus:ring-[#d12121]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                    </svg>
                    &nbsp;Salir
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

{showModal2 ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-2 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="flex flex-row place-content-between">
                <h3 className=" text-lg ml-4 mt-4 text-gray-500 font-bold">
                          Participar en el Torneo
                        </h3>
                
                      <svg onClick={() => {
                        setShowModal2(false);
                      }} class="w-[32px] h-[32px] text-gray-800 mt-2 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z" clip-rule="evenodd"/>
</svg>


                   </div>
                <div className="relative p-2 flex-auto">
                  <div className=" text-center">
                    <div>
                      <div className=" text-center">
                       
                        
                      </div>
                      
            
                      <div className="flex">
                       
                          <>
                            CONTENIDO
                            <div className="flex flex-col">
                              <div className="p-4 text-blue-900 font-bold text-xl">
                                PRECIO: Gratis
                              </div>
                              <div className="pl-4 flex flex-col">
                                <button
                                  onClick={() => {
                                    comprarentrada(email);
                                  }}
                                  id="botoncomprar6"
                                  type="button"
                                  class="text-white flex flex-row bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                                >
                                  <svg class="w-[24px] h-[24px] mt-[-4px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
</svg>

 <span>Comprar</span>
                                </button>
                               
                              </div>
                            </div>
                          </>
                        
                      </div>
                    </div>

                  
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
</>
  );
};

export default Home2;
