import React, { useState, useEffect } from "react";

import { db } from "../firebaseConfig/db";

import { collection } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { doc, setDoc, addDoc } from "@firebase/firestore";
import trofeow from "../img/trofeow.png";
import { validatePassword } from "firebase/auth";

import Swal from 'sweetalert2'

const Cartonlineal = ({ numeros,
    email,
    mensaje,
    sacada,
    bolitas,
    lineas,
    iniciando,
    iniciando2,
    usuarios,
    bolita,
    cartonesganadores,
    misala }) => {

        
  const marcarresultados = () => {
    for (let i = 0; i < sacada.length; i++) {
      revisarcarton(sacada[i]);
    }
  };

  const revisarcarton = (bolitanueva) => {
    if (bolitanueva > 0 && bolitanueva < 16) {
      chequearb(bolitanueva);
    }
    if (bolitanueva > 15 && bolitanueva < 31) {
      chequeari(bolitanueva);
    }
    if (bolitanueva > 30 && bolitanueva < 46) {
      chequearn(bolitanueva);
    }
    if (bolitanueva > 45 && bolitanueva < 61) {
      chequearg(bolitanueva);
    }
    if (bolitanueva > 60 && bolitanueva < 76) {
      chequearo(bolitanueva);
    }
  };

  const chequearb = (bolita) => {
    for (let i = 1; i <= 15; i++) {
      if (bolita == i) {
        document.getElementById(`b${i}`).className = "marcador3";
      }
    }
  };

  const chequeari = (bolita) => {
    for (let i = 16; i <= 30; i++) {
      if (bolita == i) {
        document.getElementById(`i${i}`).className = "marcador3";
      }
    }
  };

  const chequearn = (bolita) => {
    for (let i = 31; i <= 45; i++) {
      if (bolita == i) {
        document.getElementById(`n${i}`).className = "marcador3";
      }
    }
  };

  const chequearg = (bolita) => {
    for (let i = 46; i <= 60; i++) {
      if (bolita == i) {
        document.getElementById(`g${i}`).className = "marcador3";
      }
    }
  };

  const chequearo = (bolita) => {
    for (let i = 61; i <= 75; i++) {
      if (bolita == i) {
        document.getElementById(`o${i}`).className = "marcador3";
      }
    }
  };


  const obtenerinfolinea = (id, tipo) => {

   
    for (let i = 0; i < lineas.length; i++) {
    if (lineas[i].id === id) {
      if (tipo === "estatus"){
        //console.log(id)
        //console.log(lineas[i].estatus)
        return lineas[i].estatus;
      }
      if (tipo === "propietario"){
        return lineas[i].propietario;
      }
    }
    }

    return ""


  }


  const obtenerinfousers = (email, tipo) => {

    for (let i = 0; i < usuarios.length; i++) {
    if (usuarios[i].email === email) {
      if (tipo === "nombre"){
        return usuarios[i].nombre;
      }
      if (tipo === "photo"){
        return usuarios[i].photo;
        }
    }
    }
    return ""
  }

  const obtenernombre = (nombre) => {
   //console.log(nombre)
    var PrimerN = "";
    if(nombre.length !== 0){
      for (let a = 0; a < nombre.length; a++) {
        if (nombre[a] === " ") {
          a = nombre.length;
        } else {
          PrimerN += nombre[a];
        }
      }
    }
    

    return PrimerN.substring(0, 12);

  }

 const comprarlinea = (id) => {

  Swal.fire({
       title: `Desea Comprar esta Linea?`
       ,
       html: `<strong><font size='6' color=red>Linea #${id}</font></strong>`,
       icon: "warning",
       showCancelButton: true,
       confirmButtonColor: "#3085d6",
       cancelButtonColor: "#d33",
       confirmButtonText: "Comprar Linea"
     }).then((result) => {
       if (result.isConfirmed) {
        //comprando()
        comprarestalinea(id)
 
       }
     });
 }



 const comprarestalinea = (idcarton) => {

   const datos = {
    email: email,
    id: idcarton,
    sala: "sala11"
}

   //enviar datos
   fetch(`${process.env.REACT_APP_BASE}/comprarcarton`, {
    method: 'POST',
    headers: {
    'Content-Type': 'application/json',
    },
    body: JSON.stringify(datos),
    })
    .then((response) => response.json())
    .then((result) => {
    if (result) {
    //console.log(result.message);
    if(result.message === "Compra Exitosa"){
      //comprar()
      Swal.fire({
                title: "Linea Comprada",
                text: "Su Compra ha sido Exitosa, Suerte y a Ligar",
                icon: "success"
              });
      
     mensaje("compra", "actualizar", idcarton);
      
    }else{
      if(result.message === "Saldo Insuficiente"){
        //SALDO INSUFICIENTE
        Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Su Saldo es Insuficiente",
                  footer: '<a href="/#/recarga">Recarga Ahora</a>'
                });
      }
      if(result.message === "No disponible"){
        //NO DISPONIBLE
        Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Ya alguien compro tu linea, Elige otro",
                });
                mensaje("compra", "actualizar", idcarton);
        
      }
      if(result.message === "Sala Cerrada"){
        //sala cerrada
        Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "La Sala Esta Cerrada, El Juego Comenzo, pendiente para la proxima",
                });

                mensaje("compra", "actualizar", idcarton);
      }
    }
    } else {
    //console.log('ERROR DE CONEXION');
    Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Error de Conexion, Intenta de Nuevo",
            });

            mensaje("compra", "actualizar", idcarton);
    }
    })
    .catch((error) => {
    //console.log(error);
    });
    //fin de envio

}




  useEffect(() => {
    marcarresultados();
    //console.log(sacada);
  }, [sacada]);

  useEffect(() => {
    //console.log(lineas)

  }, [lineas]);

  useEffect(() => {
    //console.log(usuarios)
    
  }, [usuarios]);


  useEffect(() => {

    if(cartonesganadores.length > 0){
      document.getElementById(`nombre${cartonesganadores[0]}`).className = "animacion";
    }else{
      document.getElementById(`nombre1`).className = "";
      document.getElementById(`nombre2`).className = "";
      document.getElementById(`nombre3`).className = "";
      document.getElementById(`nombre4`).className = "";
      document.getElementById(`nombre5`).className = "";
      document.getElementById(`nombre6`).className = "";
      document.getElementById(`nombre7`).className = "";
      document.getElementById(`nombre8`).className = "";
      document.getElementById(`nombre9`).className = "";
      document.getElementById(`nombre10`).className = "";
      document.getElementById(`nombre11`).className = "";
      document.getElementById(`nombre12`).className = "";
      document.getElementById(`nombre13`).className = "";
      document.getElementById(`nombre14`).className = "";
      document.getElementById(`nombre15`).className = "";
    }

    //console.log(cartonesganadores)

  }, [cartonesganadores]);

  return (
    <>
      <table id="bingo-table" style={{fontSize: "14px"}}>
        <tr>
        <th><span class="encabezado1"></span></th>
            
          <th>
            <span class="encabezado1">B</span>
          </th>
          <th>
            <span class="encabezado1">I</span>
          </th>
          <th>
            <span class="encabezado1">N</span>
          </th>
          <th>
            <span class="encabezado1">G</span>
          </th>
          <th>
            <span class="encabezado1">O</span>
          </th>
        </tr>
        <tr >
        <td id="nombre1"  style={{backgroundColor: '#e5e7eb', padding: '0px'}}>

        {obtenerinfolinea(1, "estatus") === 'disponible' ? (
          <> {iniciando === true ? (<button onClick={() => {
          comprarlinea(1);
          }} type="button" class="text-white bg-gradient-to-r w-[150px] from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium text-sm px-3 py-1.5 text-center">
            <div className="flex flex-row place-content-center" ><svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
      <path fill-rule="evenodd" d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
    </svg>
     <span>Linea 1</span>
     </div>
     </button>) : (<button type="button" class="text-white bg-gradient-to-r w-[150px] from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-red-300 font-medium text-sm px-3 py-1.5 text-center">
        <div className="flex flex-row place-content-center" >

 <span>No Participa</span>
 </div>
 </button>)}
          
 </>

) : (<div className="flex flex-row flex-grow">
                      <img
                     
                      className="w-8 h-8 cursor-pointer border-2 border-blue-500"
                      src={obtenerinfousers(obtenerinfolinea(1,"propietario"), "photo")}
                      alt=""
                    />
                    <div className=" text-sm mt-[5px] ml-2 mr-1 text-blue-600 font-bold">
                        {obtenernombre(obtenerinfousers(obtenerinfolinea(1,"propietario"), "nombre"))}
                      </div>
                  </div>)}
            
        
 

        </td>
            
        
        <td id="b1" class="">
            1
          </td>
          <td id="i16" class="">
            16
          </td>
          <td id="n31" class="">
            31
          </td>
          <td id="g46" class="">
            46
          </td>
          <td id="o61" class="">
            61
          </td>
        </tr>
        <tr>
        <td id="nombre2"  style={{backgroundColor: '#e5e7eb', padding: '0px'}}>

            
        {obtenerinfolinea(2, "estatus") === 'disponible' ? (
          <> {iniciando === true ? (<button onClick={() => {
          comprarlinea(2);
          }} type="button" class="text-white bg-gradient-to-r w-[150px] from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium text-sm px-3 py-1.5 text-center">
            <div className="flex flex-row place-content-center" ><svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
      <path fill-rule="evenodd" d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
    </svg>
     <span>Linea 2</span>
     </div>
     </button>) : (<button type="button" class="text-white bg-gradient-to-r w-[150px] from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-red-300 font-medium text-sm px-3 py-1.5 text-center">
        <div className="flex flex-row place-content-center" >

 <span>No Participa</span>
 </div>
 </button>)}
          
 </>

) : (<div className="flex flex-row flex-grow">
                      <img
                     
                      className="w-8 h-8 cursor-pointer border-2 border-blue-500"
                      src={obtenerinfousers(obtenerinfolinea(2,"propietario"), "photo")}
                      alt=""
                    />
                    <div className=" text-sm mt-[5px] ml-2 text-blue-600 font-bold">
                        {obtenernombre(obtenerinfousers(obtenerinfolinea(2,"propietario"), "nombre"))}
                      </div>
                  </div>)}
 

        </td>
          <td id="b2" class="">
            2
          </td>
          <td id="i17" class="">
            17
          </td>
          <td id="n32" class="">
            32
          </td>
          <td id="g47" class="">
            47
          </td>
          <td id="o62" class="">
            62
          </td>
        </tr>
        <tr>
        <td id="nombre3"   style={{backgroundColor: '#e5e7eb', padding: '0px'}}>
        {obtenerinfolinea(3, "estatus") === 'disponible' ? (
          <> {iniciando === true ? (<button onClick={() => {
          comprarlinea(3);
          }} type="button" class="text-white bg-gradient-to-r w-[150px] from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium text-sm px-3 py-1.5 text-center">
            <div className="flex flex-row place-content-center" ><svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
      <path fill-rule="evenodd" d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
    </svg>
     <span>Linea 3</span>
     </div>
     </button>) : (<button type="button" class="text-white bg-gradient-to-r w-[150px] from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-red-300 font-medium text-sm px-3 py-1.5 text-center">
        <div className="flex flex-row place-content-center" >

 <span>No Participa</span>
 </div>
 </button>)}
          
 </>

) : (<div className="flex flex-row flex-grow">
                      <img
                     
                      className="w-8 h-8 cursor-pointer border-2 border-blue-500"
                      src={obtenerinfousers(obtenerinfolinea(3,"propietario"), "photo")}
                      alt=""
                    />
                    <div className=" text-sm mt-[5px] ml-2 text-blue-600 font-bold">
                        {obtenernombre(obtenerinfousers(obtenerinfolinea(3,"propietario"), "nombre"))}
                      </div>
                  </div>)}
          </td>
          <td id="b3" class="">
            3
          </td>
          <td id="i18" class="">
            18
          </td>
          <td id="n33" class="">
            33
          </td>
          <td id="g48" class="">
            48
          </td>
          <td id="o63" class="">
            63
          </td>
        </tr>
        <tr>
        <td id="nombre4"  style={{backgroundColor: '#e5e7eb', padding: '0px'}}>
        {obtenerinfolinea(4, "estatus") === 'disponible' ? (
          <> {iniciando === true ? (<button onClick={() => {
          comprarlinea(4);
          }} type="button" class="text-white bg-gradient-to-r w-[150px] from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium text-sm px-3 py-1.5 text-center">
            <div className="flex flex-row place-content-center" ><svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
      <path fill-rule="evenodd" d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
    </svg>
     <span>Linea 4</span>
     </div>
     </button>) : (<button type="button" class="text-white bg-gradient-to-r w-[150px] from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-red-300 font-medium text-sm px-3 py-1.5 text-center">
        <div className="flex flex-row place-content-center" >

 <span>No Participa</span>
 </div>
 </button>)}
          
 </>

) : (<div className="flex flex-row flex-grow">
                      <img
                     
                      className="w-8 h-8 cursor-pointer border-2 border-blue-500"
                      src={obtenerinfousers(obtenerinfolinea(4,"propietario"), "photo")}
                      alt=""
                    />
                    <div className=" text-sm mt-[5px] ml-2 text-blue-600 font-bold">
                        {obtenernombre(obtenerinfousers(obtenerinfolinea(4,"propietario"), "nombre"))}
                      </div>
                  </div>)}
          </td>
          <td id="b4" class="">
            4
          </td>
          <td id="i19" class="">
            19
          </td>
          <td id="n34" class="">
            34
          </td>
          <td id="g49" class="">
            49
          </td>
          <td id="o64" class="">
            64
          </td>
        </tr>
        <tr>
        <td id="nombre5"  style={{backgroundColor: '#e5e7eb', padding: '0px'}}>
               
        {obtenerinfolinea(5, "estatus") === 'disponible' ? (
          <> {iniciando === true ? (<button onClick={() => {
          comprarlinea(5);
          }} type="button" class="text-white bg-gradient-to-r w-[150px] from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium text-sm px-3 py-1.5 text-center">
            <div className="flex flex-row place-content-center" ><svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
      <path fill-rule="evenodd" d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
    </svg>
     <span>Linea 5</span>
     </div>
     </button>) : (<button type="button" class="text-white bg-gradient-to-r w-[150px] from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-red-300 font-medium text-sm px-3 py-1.5 text-center">
        <div className="flex flex-row place-content-center" >

 <span>No Participa</span>
 </div>
 </button>)}
          
 </>

) : (<div className="flex flex-row flex-grow">
                      <img
                     
                      className="w-8 h-8 cursor-pointer border-2 border-blue-500"
                      src={obtenerinfousers(obtenerinfolinea(5,"propietario"), "photo")}
                      alt=""
                    />
                    <div className=" text-sm mt-[5px] ml-2 text-blue-600 font-bold">
                        {obtenernombre(obtenerinfousers(obtenerinfolinea(5,"propietario"), "nombre"))}
                      </div>
                  </div>)}
          </td>
          <td id="b5" class="">
            5
          </td>
          <td id="i20" class="">
            20
          </td>
          <td id="n35" class="">
            35
          </td>
          <td id="g50" class="">
            50
          </td>
          <td id="o65" class="">
            65
          </td>
        </tr>
        <tr>
        <td id="nombre6"   style={{backgroundColor: '#e5e7eb', padding: '0px'}}>

            
        {obtenerinfolinea(6, "estatus") === 'disponible' ? (
          <> {iniciando === true ? (<button onClick={() => {
          comprarlinea(6);
          }} type="button" class="text-white bg-gradient-to-r w-[150px] from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium text-sm px-3 py-1.5 text-center">
            <div className="flex flex-row place-content-center" ><svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
      <path fill-rule="evenodd" d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
    </svg>
     <span>Linea 6</span>
     </div>
     </button>) : (<button type="button" class="text-white bg-gradient-to-r w-[150px] from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-red-300 font-medium text-sm px-3 py-1.5 text-center">
        <div className="flex flex-row place-content-center" >

 <span>No Participa</span>
 </div>
 </button>)}
          
 </>

) : (<div className="flex flex-row flex-grow">
                      <img
                     
                      className="w-8 h-8 cursor-pointer border-2 border-blue-500"
                      src={obtenerinfousers(obtenerinfolinea(6,"propietario"), "photo")}
                      alt=""
                    />
                    <div className=" text-sm mt-[5px] ml-2 text-blue-600 font-bold">
                        {obtenernombre(obtenerinfousers(obtenerinfolinea(6,"propietario"), "nombre"))}
                      </div>
                  </div>)}
 

        </td>
          <td id="b6" class="">
            6
          </td>
          <td id="i21" class="">
            21
          </td>
          <td id="n36" class="">
            36
          </td>
          <td id="g51" class="">
            51
          </td>
          <td id="o66" class="">
            66
          </td>
        </tr>
        <tr>
        <td id="nombre7"  style={{backgroundColor: '#e5e7eb', padding: '0px'}}>
        {obtenerinfolinea(7, "estatus") === 'disponible' ? (
          <> {iniciando === true ? (<button onClick={() => {
          comprarlinea(7);
          }} type="button" class="text-white bg-gradient-to-r w-[150px] from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium text-sm px-3 py-1.5 text-center">
            <div className="flex flex-row place-content-center" ><svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
      <path fill-rule="evenodd" d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
    </svg>
     <span>Linea 7</span>
     </div>
     </button>) : (<button type="button" class="text-white bg-gradient-to-r w-[150px] from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-red-300 font-medium text-sm px-3 py-1.5 text-center">
        <div className="flex flex-row place-content-center" >

 <span>No Participa</span>
 </div>
 </button>)}
          
 </>

) : (<div className="flex flex-row flex-grow">
                      <img
                     
                      className="w-8 h-8 cursor-pointer border-2 border-blue-500"
                      src={obtenerinfousers(obtenerinfolinea(7,"propietario"), "photo")}
                      alt=""
                    />
                    <div className=" text-sm mt-[5px] ml-2 text-blue-600 font-bold">
                        {obtenernombre(obtenerinfousers(obtenerinfolinea(7,"propietario"), "nombre"))}
                      </div>
                  </div>)}
          </td>
          <td id="b7" class="">
            7
          </td>
          <td id="i22" class="">
            22
          </td>
          <td id="n37" class="">
            37
          </td>
          <td id="g52" class="">
            52
          </td>
          <td id="o67" class="">
            67
          </td>
        </tr>
        <tr>
        <td id="nombre8"   style={{backgroundColor: '#e5e7eb', padding: '0px'}}>
        {obtenerinfolinea(8, "estatus") === 'disponible' ? (
          <> {iniciando === true ? (<button onClick={() => {
          comprarlinea(8);
          }} type="button" class="text-white bg-gradient-to-r w-[150px] from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium text-sm px-3 py-1.5 text-center">
            <div className="flex flex-row place-content-center" ><svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
      <path fill-rule="evenodd" d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
    </svg>
     <span>Linea 8</span>
     </div>
     </button>) : (<button type="button" class="text-white bg-gradient-to-r w-[150px] from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-red-300 font-medium text-sm px-3 py-1.5 text-center">
        <div className="flex flex-row place-content-center" >

 <span>No Participa</span>
 </div>
 </button>)}
          
 </>

) : (<div className="flex flex-row flex-grow">
                      <img
                     
                      className="w-8 h-8 cursor-pointer border-2 border-blue-500"
                      src={obtenerinfousers(obtenerinfolinea(8,"propietario"), "photo")}
                      alt=""
                    />
                    <div className=" text-sm mt-[5px] ml-2 text-blue-600 font-bold">
                        {obtenernombre(obtenerinfousers(obtenerinfolinea(8,"propietario"), "nombre"))}
                      </div>
                  </div>)}
          </td>
          <td id="b8" class="">
            8
          </td>
          <td id="i23" class="">
            23
          </td>
          <td id="n38" class="">
            38
          </td>
          <td id="g53" class="">
            53
          </td>
          <td id="o68" class="">
            68
          </td>
        </tr>
        <tr>
        <td id="nombre9"   style={{backgroundColor: '#e5e7eb', padding: '0px'}}>
        {obtenerinfolinea(9, "estatus") === 'disponible' ? (
          <> {iniciando === true ? (<button onClick={() => {
          comprarlinea(9);
          }} type="button" class="text-white bg-gradient-to-r w-[150px] from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium text-sm px-3 py-1.5 text-center">
            <div className="flex flex-row place-content-center" ><svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
      <path fill-rule="evenodd" d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
    </svg>
     <span>Linea 9</span>
     </div>
     </button>) : (<button type="button" class="text-white bg-gradient-to-r w-[150px] from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-red-300 font-medium text-sm px-3 py-1.5 text-center">
        <div className="flex flex-row place-content-center" >

 <span>No Participa</span>
 </div>
 </button>)}
          
 </>

) : (<div className="flex flex-row flex-grow">
                      <img
                     
                      className="w-8 h-8 cursor-pointer border-2 border-blue-500"
                      src={obtenerinfousers(obtenerinfolinea(9,"propietario"), "photo")}
                      alt=""
                    />
                    <div className=" text-sm mt-[5px] ml-2 text-blue-600 font-bold">
                        {obtenernombre(obtenerinfousers(obtenerinfolinea(9,"propietario"), "nombre"))}
                      </div>
                  </div>)}
          </td>
          <td id="b9" class="">
            9
          </td>
          <td id="i24" class="">
            24
          </td>
          <td id="n39" class="">
            39
          </td>
          <td id="g54" class="">
            54
          </td>
          <td id="o69" class="">
            69
          </td>
        </tr>
        <tr>
        <td id="nombre10"   style={{backgroundColor: '#e5e7eb', padding: '0px'}}>
        {obtenerinfolinea(10, "estatus") === 'disponible' ? (
          <> {iniciando === true ? (<button onClick={() => {
          comprarlinea(10);
          }} type="button" class="text-white bg-gradient-to-r w-[150px] from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium text-sm px-3 py-1.5 text-center">
            <div className="flex flex-row place-content-center" ><svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
      <path fill-rule="evenodd" d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
    </svg>
     <span>Linea 10</span>
     </div>
     </button>) : (<button type="button" class="text-white bg-gradient-to-r w-[150px] from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-red-300 font-medium text-sm px-3 py-1.5 text-center">
        <div className="flex flex-row place-content-center" >

 <span>No Participa</span>
 </div>
 </button>)}
          
 </>

) : (<div className="flex flex-row flex-grow">
                      <img
                     
                      className="w-8 h-8 cursor-pointer border-2 border-blue-500"
                      src={obtenerinfousers(obtenerinfolinea(10,"propietario"), "photo")}
                      alt=""
                    />
                    <div className=" text-sm mt-[5px] ml-2 text-blue-600 font-bold">
                        {obtenernombre(obtenerinfousers(obtenerinfolinea(10,"propietario"), "nombre"))}
                      </div>
                  </div>)}
          </td>
          <td id="b10" class="">
            10
          </td>
          <td id="i25" class="">
            25
          </td>
          <td id="n40" class="">
            40
          </td>
          <td id="g55" class="">
            55
          </td>
          <td id="o70" class="">
            70
          </td>
        </tr>
        <tr>
        <td id="nombre11"   style={{backgroundColor: '#e5e7eb', padding: '0px'}}>
        {obtenerinfolinea(11, "estatus") === 'disponible' ? (
          <> {iniciando === true ? (<button onClick={() => {
          comprarlinea(11);
          }} type="button" class="text-white bg-gradient-to-r w-[150px] from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium text-sm px-3 py-1.5 text-center">
            <div className="flex flex-row place-content-center" ><svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
      <path fill-rule="evenodd" d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
    </svg>
     <span>Linea 11</span>
     </div>
     </button>) : (<button type="button" class="text-white bg-gradient-to-r w-[150px] from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-red-300 font-medium text-sm px-3 py-1.5 text-center">
        <div className="flex flex-row place-content-center" >

 <span>No Participa</span>
 </div>
 </button>)}
          
 </>

) : (<div className="flex flex-row flex-grow">
                      <img
                     
                      className="w-8 h-8 cursor-pointer border-2 border-blue-500"
                      src={obtenerinfousers(obtenerinfolinea(11,"propietario"), "photo")}
                      alt=""
                    />
                    <div className=" text-sm mt-[5px] ml-2 text-blue-600 font-bold">
                        {obtenernombre(obtenerinfousers(obtenerinfolinea(11,"propietario"), "nombre"))}
                      </div>
                  </div>)}
          </td>
          <td id="b11" class="">
            11
          </td>
          <td id="i26" class="">
            26
          </td>
          <td id="n41" class="">
            41
          </td>
          <td id="g56" class="">
            56
          </td>
          <td id="o71" class="">
            71
          </td>
        </tr>
        <tr>
        <td id="nombre12"   style={{backgroundColor: '#e5e7eb', padding: '0px'}}>
        {obtenerinfolinea(12, "estatus") === 'disponible' ? (
          <> {iniciando === true ? (<button onClick={() => {
          comprarlinea(12);
          }} type="button" class="text-white bg-gradient-to-r w-[150px] from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium text-sm px-3 py-1.5 text-center">
            <div className="flex flex-row place-content-center" ><svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
      <path fill-rule="evenodd" d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
    </svg>
     <span>Linea 12</span>
     </div>
     </button>) : (<button type="button" class="text-white bg-gradient-to-r w-[150px] from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-red-300 font-medium text-sm px-3 py-1.5 text-center">
        <div className="flex flex-row place-content-center" >

 <span>No Participa</span>
 </div>
 </button>)}
          
 </>

) : (<div className="flex flex-row flex-grow">
                      <img
                     
                      className="w-8 h-8 cursor-pointer border-2 border-blue-500"
                      src={obtenerinfousers(obtenerinfolinea(12,"propietario"), "photo")}
                      alt=""
                    />
                    <div className=" text-sm mt-[5px] ml-2 text-blue-600 font-bold">
                        {obtenernombre(obtenerinfousers(obtenerinfolinea(12,"propietario"), "nombre"))}
                      </div>
                  </div>)}
          </td>
          <td id="b12" class="">
            12
          </td>
          <td id="i27" class="">
            27
          </td>
          <td id="n42" class="">
            42
          </td>
          <td id="g57" class="">
            57
          </td>
          <td id="o72" class="">
            72
          </td>
        </tr>
        <tr>
        <td id="nombre13"   style={{backgroundColor: '#e5e7eb', padding: '0px'}}>
        {obtenerinfolinea(13, "estatus") === 'disponible' ? (
          <> {iniciando === true ? (<button onClick={() => {
          comprarlinea(13);
          }} type="button" class="text-white bg-gradient-to-r w-[150px] from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium text-sm px-3 py-1.5 text-center">
            <div className="flex flex-row place-content-center" ><svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
      <path fill-rule="evenodd" d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
    </svg>
     <span>Linea 13</span>
     </div>
     </button>) : (<button type="button" class="text-white bg-gradient-to-r w-[150px] from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-red-300 font-medium text-sm px-3 py-1.5 text-center">
        <div className="flex flex-row place-content-center" >

 <span>No Participa</span>
 </div>
 </button>)}
          
 </>

) : (<div className="flex flex-row flex-grow">
                      <img
                     
                      className="w-8 h-8 cursor-pointer border-2 border-blue-500"
                      src={obtenerinfousers(obtenerinfolinea(13,"propietario"), "photo")}
                      alt=""
                    />
                    <div className=" text-sm mt-[5px] ml-2 text-blue-600 font-bold">
                        {obtenernombre(obtenerinfousers(obtenerinfolinea(13,"propietario"), "nombre"))}
                      </div>
                  </div>)}
          </td>
          <td id="b13" class="">
            13
          </td>
          <td id="i28" class="">
            28
          </td>
          <td id="n43" class="">
            43
          </td>
          <td id="g58" class="">
            58
          </td>
          <td id="o73" class="">
            73
          </td>
        </tr>
        <tr>
        <td id="nombre14"   style={{backgroundColor: '#e5e7eb', padding: '0px'}}>
        {obtenerinfolinea(14, "estatus") === 'disponible' ? (
          <> {iniciando === true ? (<button onClick={() => {
          comprarlinea(14);
          }} type="button" class="text-white bg-gradient-to-r w-[150px] from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium text-sm px-3 py-1.5 text-center">
            <div className="flex flex-row place-content-center" ><svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
      <path fill-rule="evenodd" d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
    </svg>
     <span>Linea 14</span>
     </div>
     </button>) : (<button type="button" class="text-white bg-gradient-to-r w-[150px] from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-red-300 font-medium text-sm px-3 py-1.5 text-center">
        <div className="flex flex-row place-content-center" >

 <span>No Participa</span>
 </div>
 </button>)}
          
 </>

) : (<div className="flex flex-row flex-grow">
                      <img
                     
                      className="w-8 h-8 cursor-pointer border-2 border-blue-500"
                      src={obtenerinfousers(obtenerinfolinea(14,"propietario"), "photo")}
                      alt=""
                    />
                    <div className=" text-sm mt-[5px] ml-2 text-blue-600 font-bold">
                        {obtenernombre(obtenerinfousers(obtenerinfolinea(14,"propietario"), "nombre"))}
                      </div>
                  </div>)}
          </td>
          <td id="b14" class="">
            14
          </td>
          <td id="i29" class="">
            29
          </td>
          <td id="n44" class="">
            44
          </td>
          <td id="g59" class="">
            59
          </td>
          <td id="o74" class="">
            74
          </td>
        </tr>
        <tr>
        <td id="nombre15"   style={{backgroundColor: '#e5e7eb', padding: '0px'}}>
        {obtenerinfolinea(15, "estatus") === 'disponible' ? (
          <> {iniciando === true ? (<button onClick={() => {
          comprarlinea(15);
          }} type="button" class="text-white bg-gradient-to-r w-[150px] from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium text-sm px-3 py-1.5 text-center">
            <div className="flex flex-row place-content-center" ><svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
      <path fill-rule="evenodd" d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
    </svg>
     <span>Linea 15</span>
     </div>
     </button>) : (<button type="button" class="text-white bg-gradient-to-r w-[150px] from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-red-300 font-medium text-sm px-3 py-1.5 text-center">
        <div className="flex flex-row place-content-center" >

 <span>No Participa</span>
 </div>
 </button>)}
          
 </>

) : (<div className="flex flex-row flex-grow">
                      <img
                     
                      className="w-8 h-8 cursor-pointer border-2 border-blue-500"
                      src={obtenerinfousers(obtenerinfolinea(15,"propietario"), "photo")}
                      alt=""
                    />
                    <div className=" text-sm mt-[5px] ml-2 text-blue-600 font-bold">
                        {obtenernombre(obtenerinfousers(obtenerinfolinea(15,"propietario"), "nombre"))}
                      </div>
                  </div>)}
          </td>
          <td id="b15" class="">
            15
          </td>
          <td id="i30" class="">
            30
          </td>
          <td id="n45" class="">
            45
          </td>
          <td id="g60" class="">
            60
          </td>
          <td id="o75" class="">
            75
          </td>
        </tr>
      </table>
    </>
  );
};

export default Cartonlineal;
