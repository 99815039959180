import React, { useState, useEffect } from "react";
import "../App.css";
import { useAuth } from "../context/AuthContext";
import logo from "../img/logo.png";
import imgcarton from "../img/tabla.png";
import useSound from "use-sound";
import { Button, Drawer, Radio, Space } from "antd";
import { Select } from "antd";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Swal from 'sweetalert2'
import io from "socket.io-client";

//IMPORTAR SONIDOS
import popoff from "../sound/pop-up-off.mp3";
import popon from "../sound/pop-up-on.mp3";
import like from "../sound/rising-pops.mp3";
import bite from "../sound/bite.mp3";
import menuopen from "../sound/menu-open.mp3";
import bienvenido from "../sound/clases/bienvenido.mp3";

import { db } from "../firebaseConfig/db";
import { collection } from "firebase/firestore";
import {
  useCollectionData,
  useCollection,
  useFirestore,
  useUpdateDoc,
} from "react-firebase-hooks/firestore";
import {
  doc,
  setDoc,
  addDoc,
  updateDoc,
  getDocs,
  deleteDoc,
} from "@firebase/firestore";

import Confetti from "react-confetti";
import Carton from "../componentes/carton";
import Mostrarcarton from "../componentes/mostrarcarton";
var todoslosc = [];


const socket = io(process.env.REACT_APP_SOCKET6);  //C LLENO
const socket2 = io(process.env.REACT_APP_SOCKET2); //FIGURA
const socket3 = io(process.env.REACT_APP_SOCKET3); //LIBRE
const socket4 = io(process.env.REACT_APP_SOCKET5); //ARCA
const socket5 = io(process.env.REACT_APP_SOCKET7); //POTE MILLONARIO

const options = [];
for (let i = 1; i < 501; i++) {
  options.push({
    label: i,
    value: i,
  });
}

const Adminuser = () => {
  const { width, height } = "100%";
  const [showModal, setShowModal] = useState(false)
  const [showModal2, setShowModal2] = useState(false)
  const [showModal3, setShowModal3] = useState(false)

  const [usuarios, setUsuarios] = useState([]);
  const [opcionuser, setOptionuser] = useState([]);

  const [cartonesmanual, setCartonesmanual] = useState([]);
  const [sala, setSala] = useState("");

  const [cartones, setCartones] = useState(0);


  const [email2, setEmail2] = useState("");

  const [emailuser, setEmailuser] = useState("");

  //WEBSOCKET
   const [messages, setMessages] = useState([]);
   const [messages2, setMessages2] = useState([]);
   const [messages3, setMessages3] = useState([]);
   const [messages4, setMessages4] = useState([]);
   const [messages5, setMessages5] = useState([]);


  const onChange = (value) => {
    console.log(`selected ${value}`);
    setEmailuser(value)
  };
  const onSearch = (value) => {
    console.log('search:', value);
  };

  const onChange2 = (value) => {
    console.log(`selected ${value}`);
    setCartones(value)
  };
  const onSearch2 = (value) => {
    console.log('search:', value);
  };


  const onChange3 = (value) => {
    console.log(`selected ${value}`);
    setSala(value)
  };
  const onSearch3 = (value) => {
    console.log('search:', value);
  };

  const handleChange = (value) => {
    let valor = value;

    setCartonesmanual(valor)

  };


  useEffect(() => {
      socket.on("message", receiveMessage);
      socket2.on("message", receiveMessage2);
      socket3.on("message", receiveMessage3);
      socket4.on("message", receiveMessage4);
      socket5.on("message", receiveMessage5);
  
      return () => {
        socket.off("message", receiveMessage);
        socket2.off("message", receiveMessage2);
        socket3.off("message", receiveMessage3);
        socket4.off("message", receiveMessage4);
        socket5.off("message", receiveMessage5);
      };
    }, []);

    const receiveMessage = (message) => setMessages((state) => [message]);
    const receiveMessage2 = (message) => setMessages2((state) => [message]);
    const receiveMessage3 = (message) => setMessages3((state) => [message]);
    const receiveMessage4 = (message) => setMessages4((state) => [message]);
    const receiveMessage5 = (message) => setMessages4((state) => [message]);

const obtenerusuarios = async () => {

  
  const emailu = email2

  try {

    const response = await fetch(`${process.env.REACT_APP_BASE}/obtenerusuarios/`, {
      method: 'POST', // Especifica el método POST
      headers: {
          'Content-Type': 'application/json', // Indica que el cuerpo de la solicitud es JSON
      },
      body: JSON.stringify({ emailu }) // Convierte el objeto a una cadena JSON
  });

      if (!response.ok) {
          throw new Error('Error en la red');
      }
      const data = await response.json();
      if(data){
        if(data.message == "no hay"){
          setUsuarios([])
        }else
        {
          if(data.usuarios){
            setUsuarios(data.usuarios)
          }
       
        }
      //console.log(data);
      }else{
        //console.log("ERROR");
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
  //console.log("SE EJECUTO")
  // if(activaintervalo){
  //   setActivaintervalo(false)
  // }else{
  //   setActivaintervalo(true)
  // }
};


const obtenerdatos = (id, tipo) => {

  for (let i = 0; i < usuarios.length; i++) {
    if (usuarios[i].email == id) {
      if(tipo == "nombre"){
        return usuarios[i].nombre
      }
      if(tipo == "foto"){
        return usuarios[i].photo
      }
      if(tipo == "cupos"){
        return usuarios[i].cupos
      }
      if(tipo == "robot"){
        return usuarios[i].robot
      }
      if(tipo == "saldo"){
        return usuarios[i].saldo
      }
      if(tipo == "cupos_regalo"){
        return usuarios[i].cupos_regalo
      }
    }
  }
}

const confirmarcupos = () => {


 Swal.fire({
      title: `Asignar estos (${cartones}) Cupos 
      a ${emailuser}`
      ,
      text: "Desea Asignar los Cupos?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Asignar Cupos"
    }).then((result) => {
      if (result.isConfirmed) {
        asignarcupos()
        Swal.fire({
          title: "Cupos Asignados",
          text: "Hemos Agregado los Cupos",
          icon: "success"
        });

      }
    });

}

const confirmarcuposregalo = () => {


  Swal.fire({
       title: `Asignar estos (${cartones}) Cupos de Regalo 
       a ${emailuser}`
       ,
       text: "Desea Asignar los Cupos de Regalo?",
       icon: "warning",
       showCancelButton: true,
       confirmButtonColor: "#3085d6",
       cancelButtonColor: "#d33",
       confirmButtonText: "Asignar Cupos"
     }).then((result) => {
       if (result.isConfirmed) {
         asignarcuposregalo()
         Swal.fire({
           title: "Cupos Asignados",
           text: "Hemos Agregado los Cupos",
           icon: "success"
         });
 
       }
     });
 
 }

const confirmarcartones = () => {

  let cartoneslabel = JSON.stringify(cartonesmanual)

  Swal.fire({
       title: `Asignar estos (${cartoneslabel}) Cartones`
       ,
       text: "Desea Asignar los Cupos?",
       icon: "warning",
       showCancelButton: true,
       confirmButtonColor: "#3085d6",
       cancelButtonColor: "#d33",
       confirmButtonText: "Asignar Cupos"
     }).then((result) => {
       if (result.isConfirmed) {
        compradecartones()
         Swal.fire({
           title: "Cupos Asignados",
           text: "Hemos Agregado los Cupos",
           icon: "success"
         });
 
       }
     });
 
 }


 const confirmarcartonesregalo = () => {

  let cartoneslabel = JSON.stringify(cartonesmanual)

  Swal.fire({
       title: `Asignar estos (${cartoneslabel}) Cartones de Regalo`
       ,
       text: "Desea Asignar estos cartones de regalo?",
       icon: "warning",
       showCancelButton: true,
       confirmButtonColor: "#3085d6",
       cancelButtonColor: "#d33",
       confirmButtonText: "Asignar Cartones"
     }).then((result) => {
       if (result.isConfirmed) {
        regalodecartones()
         Swal.fire({
           title: "Cupos Asignados",
           text: "Hemos Agregado los Cartones",
           icon: "success"
         });
 
       }
     });
 
 }

const confirmaractivar = (valor) => {

    
    let activa = "Desactivar"

    if(valor === 1){
        activa = "Activar"
    }

 
    Swal.fire({
         title: `Desea (${activa}) El Bot`
         ,
         text: "Activacion de Bot?",
         icon: "warning",
         showCancelButton: true,
         confirmButtonColor: "#3085d6",
         cancelButtonColor: "#d33",
         confirmButtonText: "Confirmar"
       }).then((result) => {
         if (result.isConfirmed) {
           activacion(valor)
           Swal.fire({
             title: "Activacion de Bot",
             text: "Hemos Confirmado su Solicitud",
             icon: "success"
           });
   
         }
       });
   
   }


   const compradecartones = async () => {

    for (let i = 0; i < cartonesmanual.length; i++) {
      
     let result = await comprarcartones(cartonesmanual[i])
    
    }

   }


   const regalodecartones = async () => {

    for (let i = 0; i < cartonesmanual.length; i++) {
      
     let result = await regalarcartones(cartonesmanual[i])
    
    }

   }


   const comprarcartones = async (idcarton) => {


    const id = idcarton;
    const email = emailuser;

  let api = ""
    if(sala === "sala6"){
    api = "regalarcartongratis"
    }
    if(sala === "sala1"){
      api = "comprarcarton"
    }
    if(sala === "sala2"){
      api = "comprarcarton"
    }
    if(sala === "sala3"){
      api = "comprarcarton"
    }
    if(sala === "sala5"){
      api = "comprarcarton"
    }
    if(sala === "sala7"){
      api = "compracarton"
    }
    
   

    console.log(email, id, sala)

    try {

      const response = await fetch(`${process.env.REACT_APP_BASE}/${api}/`, {
        method: 'POST', // Especifica el método POST
        headers: {
            'Content-Type': 'application/json', // Indica que el cuerpo de la solicitud es JSON
        },
        body: JSON.stringify({ email, id, sala }) // Convierte el objeto a una cadena JSON
    });

        if (!response.ok) {
            throw new Error('Error en la red');
        }
        const data = await response.json();
        if(data){
          if(data.message){
            alert(data.message + " Carton: "+id)

            const newMessage = {
              body: [
                "comprarcarton"
              ],
              from: "Administrador",
            };
            
            if(sala === "sala1"){
              socket.emit("message", newMessage.body);
            }
            if(sala === "sala2"){
              socket2.emit("message", newMessage.body);
            }
            if(sala === "sala3"){
              socket3.emit("message", newMessage.body);
            }
            if(sala === "sala5"){
              socket4.emit("message", newMessage.body);
            }
            if(sala === "sala6"){
              socket5.emit("message", newMessage.body);
            }
            

          }
        //console.log(data);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }


}


const regalarcartones = async (idcarton) => {


  const id = idcarton;
  const email = emailuser;

let api = ""
  if(sala === "sala6"){
  api = "regalarcartongratis"
  }
  if(sala === "sala1"){
    api = "regalarcarton"
  }
  if(sala === "sala2"){
    api = "regalarcarton"
  }
  if(sala === "sala3"){
    api = "regalarcarton"
  }
  if(sala === "sala5"){
    api = "regalarcarton"
  }
  if(sala === "sala7"){
    api = "regalacarton"
  }
  
 

  console.log(email, id, sala)

  try {

    const response = await fetch(`${process.env.REACT_APP_BASE}/${api}/`, {
      method: 'POST', // Especifica el método POST
      headers: {
          'Content-Type': 'application/json', // Indica que el cuerpo de la solicitud es JSON
      },
      body: JSON.stringify({ email, id, sala }) // Convierte el objeto a una cadena JSON
  });

      if (!response.ok) {
          throw new Error('Error en la red');
      }
      const data = await response.json();
      if(data){
        if(data.message){
          alert(data.message + " Carton: "+id)

          const newMessage = {
            body: [
              "regalarcarton"
            ],
            from: "Administrador",
          };
          
          if(sala === "sala1"){
            socket.emit("message", newMessage.body);
          }
          if(sala === "sala2"){
            socket2.emit("message", newMessage.body);
          }
          if(sala === "sala3"){
            socket3.emit("message", newMessage.body);
          }
          if(sala === "sala5"){
            socket4.emit("message", newMessage.body);
          }
          if(sala === "sala6"){
            socket5.emit("message", newMessage.body);
          }
          

        }
      //console.log(data);
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }


}




const asignarcupos = async () => {

    const emailu = email2
    const cupos = cartones;
    const email = emailuser;

    console.log(emailu, cupos, email)

    try {

      const response = await fetch(`${process.env.REACT_APP_BASE}/asignarcupos/`, {
        method: 'POST', // Especifica el método POST
        headers: {
            'Content-Type': 'application/json', // Indica que el cuerpo de la solicitud es JSON
        },
        body: JSON.stringify({ emailu, cupos, email }) // Convierte el objeto a una cadena JSON
    });

        if (!response.ok) {
            throw new Error('Error en la red');
        }
        const data = await response.json();
        if(data){
          if(data.message){
            console.log(data.message)
          }
        //console.log(data);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }


}

const asignarcuposregalo = async () => {

  const emailu = email2
  const cupos = cartones;
  const email = emailuser;

  console.log(emailu, cupos, email)

  try {

    const response = await fetch(`${process.env.REACT_APP_BASE}/asignarcuposregalo/`, {
      method: 'POST', // Especifica el método POST
      headers: {
          'Content-Type': 'application/json', // Indica que el cuerpo de la solicitud es JSON
      },
      body: JSON.stringify({ emailu, cupos, email }) // Convierte el objeto a una cadena JSON
  });

      if (!response.ok) {
          throw new Error('Error en la red');
      }
      const data = await response.json();
      if(data){
        if(data.message){
          console.log(data.message)
        }
      //console.log(data);
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }


}

const activacion = async (valor) => {

    const emailu = email2
    const activacion = valor
    const email = emailuser

   console.log(valor)


    try {

      const response = await fetch(`${process.env.REACT_APP_BASE}/activarbot/`, {
        method: 'POST', // Especifica el método POST
        headers: {
            'Content-Type': 'application/json', // Indica que el cuerpo de la solicitud es JSON
        },
        body: JSON.stringify({ emailu, activacion, email }) // Convierte el objeto a una cadena JSON
    });

        if (!response.ok) {
            throw new Error('Error en la red');
        }
        const data = await response.json();
        if(data){
          if(data.message){
            console.log(data.message)
          }
        //console.log(data);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }


}




  const msjsave = (mensajesave, tipodemensaje) => {
    if (tipodemensaje == "save") {
      toast.success(mensajesave, {});
    }
    if (tipodemensaje == "error") {
      toast.error(mensajesave, {});
    }
    if (tipodemensaje == "warning") {
      toast.warning(mensajesave, {});
    }
    if (tipodemensaje == "info") {
      toast.info(mensajesave, {});
    }
  };




  // GENERAR CARTONES

  //

  const auth = useAuth();
  const { displayName } = auth.user;
  const { email } = auth.user;
  
  var primerNombre = "";
  if (displayName == undefined) {
  } else {
    let nombre = displayName;

    for (let i = 0; i < nombre.length; i++) {
      if (nombre[i] === " ") {
        i = nombre.length;
      } else {
        primerNombre += nombre[i];
      }
    }
  }
  const photoURL = auth.user.photoURL;
  //console.log(auth.user.photoURL);

  const handleGoogle = (e) => {
    e.preventDefault();
    auth.loginWithGoogle();
  };
  const handleLogout = () => {
    auth.logout();
  };


  useEffect(() => {
    console.log(email2)
    if(email2 !== undefined){

      obtenerusuarios()

    }
    
    
  }, [email2]);

  useEffect(() => {
    setEmail2(email)
  }, [email]);

  


  useEffect(() => {

    setOptionuser([])
    console.log(usuarios)
    for (let i = 0; i < usuarios.length; i++) {
        const nuevoUsuario = {
            value: usuarios[i].email,
            label: usuarios[i].nombre + " " + usuarios[i].email,
          };
        setOptionuser((usuarios) => [...usuarios, nuevoUsuario]);
    }

  }, [usuarios]);


  return (
    <>
      <div className="container w-screen">
        <div className=" p-2 h-28 w-full flex place-content-between items-center">
          <div className="m-0 flex flex-wrap flex-col place-content-around">
            <Link to="/">
              <img
                className="pt-2 mt-[-10px]"
                src={logo}
                width="300px"
                height="100%"
                alt="logo"
              />
            </Link>
            <p className="p-1"></p>
          </div>

          {displayName == undefined ? (
            <button
              type="button"
              onClick={(e) => handleGoogle(e)}
              class="text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 me-2 mb-2"
            >
              <svg
                class="w-4 h-4 me-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 19"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z"
                  clip-rule="evenodd"
                />
              </svg>
              Entrar
            </button>
          ) : (
            <div className="flex">
              <div className="p-2">
                <div className="flex flex-col items-end gap-4 ">
                  <img
                    onClick={handleLogout}
                    className="w-10 h-10  rounded-full border-2 border-blue-500"
                    src={`${photoURL}`}
                    alt=""
                  />
                  <div className="font-medium text-white mt-[-10px]">
                    <div className="text-white text-right text-sm">
                      {displayName && <> {primerNombre} </>}
                    </div>
                  </div>
                </div>
              </div>

              <div></div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer theme="dark" />
      <br />

      {email == "enmarycarrasco@gmail.com" ? (
        <>

       
        
     
        <div className="p-4">
          <div className="mb-2 font-bold text-gray-700 text-xl">Lista de Usuarios</div>
          
          <Select
    showSearch
    placeholder="Select a person"
    optionFilterProp="label"
    onChange={onChange}
    onSearch={onSearch}
    options={opcionuser}
  />
        </div>
   

   
   
     

          <div className="p-4 flex flex-col">

            <span className="text-blue-900 font-bold">Correo: {emailuser}</span>
            <span className="text-blue-900 font-bold">Nombres: {obtenerdatos(emailuser, "nombre")}</span>
            <span className="text-blue-900 font-bold">Saldo: {obtenerdatos(emailuser, "saldo")}</span>
            <span className="text-blue-900 font-bold">Cupos: {obtenerdatos(emailuser, "cupos")}</span>
            <span className="text-blue-900 font-bold">Cupos de Regalo: {obtenerdatos(emailuser, "cupos_regalo")}</span>
            <span className="text-blue-900 font-bold">Robot: {obtenerdatos(emailuser, "robot")}</span>

        
            <div className="flex flex-col">
            <br />
            
                <span className="font-bold">Asignar Cupos de Cartones: </span>
            <Select
    showSearch
    placeholder="Asignar Cartones"
    optionFilterProp="label"
    onChange={onChange2}
    onSearch={onSearch2}
    options={[
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
    ]}
  />
            </div>
        
            <button  onClick={() => {
       confirmarcupos()
        }} type="button" class="mt-2 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Asignar Cupos</button>
        

        <button  onClick={() => {
       confirmarcuposregalo()
        }} type="button" class="mt-2 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Asignar Cupos de Regalo</button>
        
 
        <div>
        <div className="mb-2 font-bold text-gray-700 text-xl">Activacion de BOT</div>
        <button  onClick={() => {
       confirmaractivar(1)
        }} type="button" class="mt-2 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Activar Bot</button>
        
        <button  onClick={() => {
       confirmaractivar(0)
        }} type="button" class="mt-2 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Desactivar Bot</button>
        
        </div>


        <div className="font-bold">Asignar Cartones Manual</div>
        <div>
        <Space
    style={{
      width: '100%',
    }}
    direction="vertical"
  >
    <Select
      mode="multiple"
      allowClear
      style={{
        width: '100%',
      }}
      placeholder="Please select"
      defaultValue={[]}
      onChange={handleChange}
      options={options}
    />
   
  </Space>
        </div>


        <div className="flex flex-col">
            <br />
            
                <span className="font-bold">Sala a Comprar: </span>
            <Select
    showSearch
    placeholder="Seleccione la Sala"
    optionFilterProp="label"
    onChange={onChange3}
    onSearch={onSearch3}
    defaultValue={'sala1'}
    options={[
        { value: 'sala1', label: 'Sala 1 - Carton Lleno' },
        { value: 'sala2', label: 'Sala 2 - Figuras' },
        { value: 'sala3', label: 'Sala 3 - Libre' },
        { value: 'sala5', label: 'Sala 5 - Arca' },
        { value: 'sala6', label: 'Sala 6 - Olla de Oro' },
        { value: 'sala7', label: 'Modalidad - Casual' },
    ]}
  />
            </div>


            <button  onClick={() => {
       confirmarcartones()
        }} type="button" class="mt-2 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Comprar Cartones</button>
        

        <button  onClick={() => {
       confirmarcartonesregalo()
        }} type="button" class="mt-2 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Cartones de Regalo</button>
        

          </div>

    
        </>
      ) : (
        <div></div>
      )}


    </>
  );
};

export default Adminuser;
