import React, { useState, useEffect } from "react";

import { db } from "../firebaseConfig/db";

import { collection } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { doc, setDoc, addDoc } from "@firebase/firestore";
import trofeow from "../img/trofeow.png";

import img1 from "../img/animales/b1.png";
import img2 from "../img/animales/b2.png";
import img3 from "../img/animales/b3.png";
import img4 from "../img/animales/b4.png";
import img5 from "../img/animales/b5.png";
import img6 from "../img/animales/b6.png";
import img7 from "../img/animales/b7.png";
import img8 from "../img/animales/b8.png";
import img9 from "../img/animales/b9.png";
import img10 from "../img/animales/b10.png";
import img11 from "../img/animales/b11.png";
import img12 from "../img/animales/b12.png";
import img13 from "../img/animales/b13.png";
import img14 from "../img/animales/b14.png";
import img15 from "../img/animales/b15.png";
import img16 from "../img/animales/b16.png";
import img17 from "../img/animales/b17.png";
import img18 from "../img/animales/b18.png";
import img19 from "../img/animales/b19.png";
import img20 from "../img/animales/b20.png";
import img21 from "../img/animales/b21.png";
import img22 from "../img/animales/b22.png";
import img23 from "../img/animales/b23.png";
import img24 from "../img/animales/b24.png";
import img25 from "../img/animales/b25.png";
import img26 from "../img/animales/b26.png";
import img27 from "../img/animales/b27.png";
import img28 from "../img/animales/b28.png";
import img29 from "../img/animales/b29.png";
import img30 from "../img/animales/b30.png";
import img31 from "../img/animales/b31.png";
import img32 from "../img/animales/b32.png";
import img33 from "../img/animales/b33.png";
import img34 from "../img/animales/b34.png";
import img35 from "../img/animales/b35.png";
import img36 from "../img/animales/b36.png";
import img37 from "../img/animales/b37.png";
import img38 from "../img/animales/b38.png";
import img39 from "../img/animales/b39.png";
import img40 from "../img/animales/b40.png";
import img41 from "../img/animales/b41.png";
import img42 from "../img/animales/b42.png";
import img43 from "../img/animales/b43.png";
import img44 from "../img/animales/b44.png";
import img45 from "../img/animales/b45.png";
import img46 from "../img/animales/b46.png";
import img47 from "../img/animales/b47.png";
import img48 from "../img/animales/b48.png";
import img49 from "../img/animales/b49.png";
import img50 from "../img/animales/b50.png";
import img51 from "../img/animales/b51.png";
import img52 from "../img/animales/b52.png";
import img53 from "../img/animales/b53.png";
import img54 from "../img/animales/b54.png";
import img55 from "../img/animales/b55.png";
import img56 from "../img/animales/b56.png";
import img57 from "../img/animales/b57.png";
import img58 from "../img/animales/b58.png";
import img59 from "../img/animales/b59.png";
import img60 from "../img/animales/b60.png";
import img61 from "../img/animales/b61.png";
import img62 from "../img/animales/b62.png";
import img63 from "../img/animales/b63.png";
import img64 from "../img/animales/b64.png";
import img65 from "../img/animales/b65.png";
import img66 from "../img/animales/b66.png";
import img67 from "../img/animales/b67.png";
import img68 from "../img/animales/b68.png";
import img69 from "../img/animales/b69.png";
import img70 from "../img/animales/b70.png";
import img71 from "../img/animales/b71.png";
import img72 from "../img/animales/b72.png";
import img73 from "../img/animales/b73.png";
import img74 from "../img/animales/b74.png";
import img75 from "../img/animales/b75.png";

const Arcafinal = ({
  numeros,
  nombre,
  photo,
  id,
  idcarton,
  mensaje,
  sacada,
  figuras,
  bolitas,
}) => {
  let nomb = nombre;
  var primerNombre = "";

  for (let i = 0; i < nomb.length; i++) {
    if (nomb[i] === " ") {
      i = nomb.length;
    } else {
      primerNombre += nomb[i];
    }
  }

  const marcarresultados = () => {
    for (let i = 0; i < sacada.length; i++) {
      revisarcarton(sacada[i]);
    }
  };

  const marcarblanco = () => {
    for (let i = 0; i < bolitas.length; i++) {
      revisarcarton1(bolitas[i]);
    }
  };

  const revisarcarton = (bolitanueva) => {
    if (bolitanueva > 0 && bolitanueva < 76) {
      chequearb(bolitanueva);
    }
    
  };

  const revisarcarton1 = (bolitanueva) => {
    if (bolitanueva > 0 && bolitanueva < 76) {
      chequearb1(bolitanueva);
    }
    
  };

  const obtenerimagen = (id) => {
    if (id == 1) {
      return img1;
    }
    if (id == 2) {
      return img2;
    }
    if (id == 3) {
      return img3;
    }
    if (id == 4) {
      return img4;
    }
    if (id == 5) {
      return img5;
    }
    if (id == 6) {
      return img6;
    }
    if (id == 7) {
      return img7;
    }
    if (id == 8) {
      return img8;
    }
    if (id == 9) {
      return img9;
    }
    if (id == 10) {
      return img10;
    }
    if (id == 11) {
      return img11;
    }
    if (id == 12) {
      return img12;
    }
    if (id == 13) {
      return img13;
    }
    if (id == 14) {
      return img14;
    }
    if (id == 15) {
      return img15;
    }
    if (id == 16) {
      return img16;
    }
    if (id == 17) {
      return img17;
    }
    if (id == 18) {
      return img18;
    }
    if (id == 19) {
      return img19;
    }
    if (id == 20) {
      return img20;
    }
    if (id == 21) {
      return img21;
    }
    if (id == 22) {
      return img22;
    }
    if (id == 23) {
      return img23;
    }
    if (id == 24) {
      return img24;
    }
    if (id == 25) {
      return img25;
    }
    if (id == 26) {
      return img26;
    }
    if (id == 27) {
      return img27;
    }
    if (id == 28) {
      return img28;
    }
    if (id == 29) {
      return img29;
    }
    if (id == 30) {
      return img30;
    }
    if (id == 31) {
      return img31;
    }
    if (id == 32) {
      return img32;
    }
    if (id == 33) {
      return img33;
    }
    if (id == 34) {
      return img34;
    }
    if (id == 35) {
      return img35;
    }
    if (id == 36) {
      return img36;
    }
    if (id == 37) {
      return img37;
    }
    if (id == 38) {
      return img38;
    }
    if (id == 39) {
      return img39;
    }
    if (id == 40) {
      return img40;
    }
    if (id == 41) {
      return img41;
    }
    if (id == 42) {
      return img42;
    }
    if (id == 43) {
      return img43;
    }
    if (id == 44) {
      return img44;
    }
    if (id == 45) {
      return img45;
    }
    if (id == 46) {
      return img46;
    }
    if (id == 47) {
      return img47;
    }
    if (id == 48) {
      return img48;
    }
    if (id == 49) {
      return img49;
    }
    if (id == 50) {
      return img50;
    }
    if (id == 51) {
      return img51;
    }
    if (id == 52) {
      return img52;
    }
    if (id == 53) {
      return img53;
    }
    if (id == 54) {
      return img54;
    }
    if (id == 55) {
      return img55;
    }
    if (id == 56) {
      return img56;
    }
    if (id == 57) {
      return img57;
    }
    if (id == 58) {
      return img58;
    }
    if (id == 59) {
      return img59;
    }
    if (id == 60) {
      return img60;
    }
    if (id == 61) {
      return img61;
    }
    if (id == 62) {
      return img62;
    }
    if (id == 63) {
      return img63;
    }
    if (id == 64) {
      return img64;
    }
    if (id == 65) {
      return img65;
    }
    if (id == 66) {
      return img66;
    }
    if (id == 67) {
      return img67;
    }
    if (id == 68) {
      return img68;
    }
    if (id == 69) {
      return img69;
    }
    if (id == 70) {
      return img70;
    }
    if (id == 71) {
      return img71;
    }
    if (id == 72) {
      return img72;
    }
    if (id == 73) {
      return img73;
    }
    if (id == 74) {
      return img74;
    }
    if (id == 75) {
      return img75;
    }
    
  };

  const chequearb = (bolita) => {
    if (numeros.b1 == bolita) {
      document.getElementById(`carto${id}b1`).className = cambiacolor("b1");
    }
    if (numeros.b2 == bolita) {
      document.getElementById(`carto${id}b2`).className = cambiacolor("b2");
    }
    if (numeros.b3 == bolita) {
      document.getElementById(`carto${id}b3`).className = cambiacolor("b3");
    }
    if (numeros.b4 == bolita) {
      document.getElementById(`carto${id}b4`).className = cambiacolor("b4");
    }
    if (numeros.b5 == bolita) {
      document.getElementById(`carto${id}b5`).className = cambiacolor("b5");
    }
    if (numeros.b6 == bolita) {
        document.getElementById(`carto${id}b6`).className = cambiacolor("b6");
      }
  };

  const chequearb1 = (bolita) => {
    if (numeros.b1 == bolita) {
      document.getElementById(`carto${id}b1`).className = "border-[1px] border-blue-900";
    }
    if (numeros.b2 == bolita) {
      document.getElementById(`carto${id}b2`).className = "border-[1px] border-blue-900";
    }
    if (numeros.b3 == bolita) {
      document.getElementById(`carto${id}b3`).className = "border-[1px] border-blue-900";
    }
    if (numeros.b4 == bolita) {
      document.getElementById(`carto${id}b4`).className = "border-[1px] border-blue-900";
    }
    if (numeros.b5 == bolita) {
      document.getElementById(`carto${id}b5`).className = "border-[1px] border-blue-900";
    }
    if (numeros.b6 == bolita) {
        document.getElementById(`carto${id}b6`).className = "border-[1px] border-blue-900";
      }
  };



  const cambiacolor = (casilla) => {
    for (let i = 0; i < figuras.length; i++) {
      if (figuras[i] == casilla) {
        return "border-[1px] border-blue-900 bg-red-500";
      }
    }
    return "border-[1px] border-blue-900";
  };

  const chequearresultados = () => {
    var total = figuras.length;
    var totalacertado = 0;
    for (let i = 0; i < figuras.length; i++) {
      if (figuras[i] == "b1") {
        totalacertado = totalacertado + salio(numeros.b1);
      }
      if (figuras[i] == "b2") {
        totalacertado = totalacertado + salio(numeros.b2);
      }
      if (figuras[i] == "b3") {
        totalacertado = totalacertado + salio(numeros.b3);
      }
      if (figuras[i] == "b4") {
        totalacertado = totalacertado + salio(numeros.b4);
      }
      if (figuras[i] == "b5") {
        totalacertado = totalacertado + salio(numeros.b5);
      }
      if (figuras[i] == "b6") {
        totalacertado = totalacertado + salio(numeros.b6);
      }
      
    }
    if (totalacertado == total) {
      document.getElementById(`boton${id}`).innerHTML = "";
      mensaje("Felicidades", "save");
    } else {
      mensaje("No tienes BINGO", "error");
    }
  };

  const salio = (numero) => {
    for (let i = 0; i < sacada.length; i++) {
      if (numero == sacada[i]) {
        return 1;
      }
    }
    return 0;
  };

  useEffect(() => {
    marcarresultados();
    //console.log(sacada);
  }, [sacada]);

  useEffect(() => {
    marcarblanco();
    //console.log(bolitas);
  }, [bolitas]);
  //console.log(numeros.estatus);

  return (
    <>
      <div className="flex flex-col m-4">
        <span className="h-8 flex flex-row bg-gradient-to-tr from-blue-400 to-blue-900 rounded-t-xl text-white">
          
        <span className="w-8 h-8 mt-[-6px] text-[12px] flex flex-row place-content-center bg-slate-700 items-center  rounded-full border-2 border-blue-500">{numeros.numerocarton}</span>
          <span className="ml-2 mt-1 text-white text-sm font-semibold">
            {primerNombre}
          </span>
          {idcarton.includes(id) == true ? (
            <>
              <img
                className="mt-[-20px] ml-4"
                src={trofeow}
                width="40px"
                height="60px"
              />
            </>
          ) : (
            <></>
          )}
        </span>

        <div className="flex flex-col border-0 rounded-xl bg-[#00d4ff]">
        
        <div className="flex flex-row">
          <img
          id={`carto${id}b1`}
            src={obtenerimagen(numeros.b1)}
            width="80px"
            className="border-[1px] border-blue-900"
            alt=""
          />
          <img
          id={`carto${id}b2`}
            src={obtenerimagen(numeros.b2)}
            width="80px"
            className="border-[1px] border-blue-900"
            alt=""
          />
          <img
          id={`carto${id}b3`}
            src={obtenerimagen(numeros.b3)}
            className="border-[1px] border-blue-900"
            width="80px"
            alt=""
          />
        </div>
        <div className="flex flex-row">
          <img
          id={`carto${id}b4`}
            src={obtenerimagen(numeros.b4)}
            width="80px"
            className="border-[1px] border-blue-900"
            alt=""
          />
          <img
          id={`carto${id}b5`}
            src={obtenerimagen(numeros.b5)}
            width="80px"
            alt=""
            className="border-[1px] border-blue-900"
          />
          <img
          id={`carto${id}b6`}
            src={obtenerimagen(numeros.b6)}
            width="80px"
            className="border-[1px] border-blue-900"
            alt=""
          />
        </div>
      </div>
        {/* <table id="bingo-table">
          <tr>
            <th>
              <span class="encabezado1">B</span>
            </th>
            <th>
              <span class="encabezado1">I</span>
            </th>
            <th>
              <span class="encabezado1">N</span>
            </th>
            <th>
              <span class="encabezado1">G</span>
            </th>
            <th>
              <span class="encabezado1">O</span>
            </th>
          </tr>
          <tr>
            <td id={`carto${id}b1`} class="">
              {numeros.b1}
            </td>
            <td id={`carto${id}i1`} class="">
              00
            </td>
            <td id={`carto${id}n1`} class="">
              00
            </td>
            <td id={`carto${id}g1`} class="">
              00
            </td>
            <td id={`carto${id}o1`} class="">
              00
            </td>
          </tr>
          <tr>
            <td id={`carto${id}b2`} class="">
              {numeros.b2}
            </td>
            <td id={`carto${id}i2`} class="">
             00
            </td>
            <td id={`carto${id}n2`} class="">
             00
            </td>
            <td id={`carto${id}g2`} class="">
             00
            </td>
            <td id={`carto${id}o2`} class="">
              00
            </td>
          </tr>
          <tr>
            <td id={`carto${id}b3`} class="">
              {numeros.b3}
            </td>
            <td id={`carto${id}i3`} class="">
              00
            </td>
            <td class="marcador3 ">
              <svg
                class="w-6 h-6 text-yellow-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
              </svg>
            </td>
            <td id={`carto${id}g3`} class="">
              00
            </td>
            <td id={`carto${id}o3`} class="">
             00
            </td>
          </tr>
          <tr>
            <td id={`carto${id}b4`} class="">
              {numeros.b4}
            </td>
            <td id={`carto${id}i4`} class="">
              00
            </td>
            <td id={`carto${id}n4`} class="">
             00
            </td>
            <td id={`carto${id}g4`} class="">
              00
            </td>
            <td id={`carto${id}o4`} class="">
              00
            </td>
          </tr>
          <tr>
            <td id={`carto${id}b5`} class="">
              {numeros.b5}
            </td>
            <td id={`carto${id}i5`} class="">
              00
            </td>
            <td id={`carto${id}n5`} class="">
              00
            </td>
            <td id={`carto${id}g5`} class="">
              00
            </td>
            <td id={`carto${id}o5`} class="">
              00
            </td>
          </tr>
          <tr>
            <td id={`carto${id}b6`} class="">
              {numeros.b6}
            </td>
            <td id={`carto${id}i6`} class="">
              00
            </td>
            <td id={`carto${id}n6`} class="">
              00
            </td>
            <td id={`carto${id}g6`} class="">
              00
            </td>
            <td id={`carto${id}o6`} class="">
              00
            </td>
          </tr>
        </table> */}
      </div>
    </>
  );
};

export default Arcafinal;
