import React, { useState, useEffect } from "react";
import "../App.css";
import { useAuth } from "../context/AuthContext";
import logo from "../img/logo.png";
import maracaibo from "../img/maracaibo.png";
import figura from "../img/figuras/cuadrado.png";
import trofeow from "../img/trofeow.png";
import fotoreloj from "../img/reloj.gif";
import useSound from "use-sound";





import { Link } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import menuopen from "../sound/menu-open.mp3";
import celebration from "../sound/celebration.mp3";
import bb1 from "../sound/bolitas/b1.mp3";
import bb2 from "../sound/bolitas/b2.mp3";
import bb3 from "../sound/bolitas/b3.mp3";
import bb4 from "../sound/bolitas/b4.mp3";
import bb5 from "../sound/bolitas/b5.mp3";
import bb6 from "../sound/bolitas/b6.mp3";
import bb7 from "../sound/bolitas/b7.mp3";
import bb8 from "../sound/bolitas/b8.mp3";
import bb9 from "../sound/bolitas/b9.mp3";
import bb10 from "../sound/bolitas/b10.mp3";
import bb11 from "../sound/bolitas/b11.mp3";
import bb12 from "../sound/bolitas/b12.mp3";
import bb13 from "../sound/bolitas/b13.mp3";
import bb14 from "../sound/bolitas/b14.mp3";
import bb15 from "../sound/bolitas/b15.mp3";

import ii16 from "../sound/bolitas/i16.mp3";
import ii17 from "../sound/bolitas/i17.mp3";
import ii18 from "../sound/bolitas/i18.mp3";
import ii19 from "../sound/bolitas/i19.mp3";
import ii20 from "../sound/bolitas/i20.mp3";
import ii21 from "../sound/bolitas/i21.mp3";
import ii22 from "../sound/bolitas/i22.mp3";
import ii23 from "../sound/bolitas/i23.mp3";
import ii24 from "../sound/bolitas/i24.mp3";
import ii25 from "../sound/bolitas/i25.mp3";
import ii26 from "../sound/bolitas/i26.mp3";
import ii27 from "../sound/bolitas/i27.mp3";
import ii28 from "../sound/bolitas/i28.mp3";
import ii29 from "../sound/bolitas/i29.mp3";
import ii30 from "../sound/bolitas/i30.mp3";

import nn31 from "../sound/bolitas/n31.mp3";
import nn32 from "../sound/bolitas/n32.mp3";
import nn33 from "../sound/bolitas/n33.mp3";
import nn34 from "../sound/bolitas/n34.mp3";
import nn35 from "../sound/bolitas/n35.mp3";
import nn36 from "../sound/bolitas/n36.mp3";
import nn37 from "../sound/bolitas/n37.mp3";
import nn38 from "../sound/bolitas/n38.mp3";
import nn39 from "../sound/bolitas/n39.mp3";
import nn40 from "../sound/bolitas/n40.mp3";
import nn41 from "../sound/bolitas/n41.mp3";
import nn42 from "../sound/bolitas/n42.mp3";
import nn43 from "../sound/bolitas/n43.mp3";
import nn44 from "../sound/bolitas/n44.mp3";
import nn45 from "../sound/bolitas/n45.mp3";

import gg46 from "../sound/bolitas/g46.mp3";
import gg47 from "../sound/bolitas/g47.mp3";
import gg48 from "../sound/bolitas/g48.mp3";
import gg49 from "../sound/bolitas/g49.mp3";
import gg50 from "../sound/bolitas/g50.mp3";
import gg51 from "../sound/bolitas/g51.mp3";
import gg52 from "../sound/bolitas/g52.mp3";
import gg53 from "../sound/bolitas/g53.mp3";
import gg54 from "../sound/bolitas/g54.mp3";
import gg55 from "../sound/bolitas/g55.mp3";
import gg56 from "../sound/bolitas/g56.mp3";
import gg57 from "../sound/bolitas/g57.mp3";
import gg58 from "../sound/bolitas/g58.mp3";
import gg59 from "../sound/bolitas/g59.mp3";
import gg60 from "../sound/bolitas/g60.mp3";

import oo61 from "../sound/bolitas/o61.mp3";
import oo62 from "../sound/bolitas/o62.mp3";
import oo63 from "../sound/bolitas/o63.mp3";
import oo64 from "../sound/bolitas/o64.mp3";
import oo65 from "../sound/bolitas/o65.mp3";
import oo66 from "../sound/bolitas/o66.mp3";
import oo67 from "../sound/bolitas/o67.mp3";
import oo68 from "../sound/bolitas/o68.mp3";
import oo69 from "../sound/bolitas/o69.mp3";
import oo70 from "../sound/bolitas/o70.mp3";
import oo71 from "../sound/bolitas/o71.mp3";
import oo72 from "../sound/bolitas/o72.mp3";
import oo73 from "../sound/bolitas/o73.mp3";
import oo74 from "../sound/bolitas/o74.mp3";
import oo75 from "../sound/bolitas/o75.mp3";

import bingoo from "../sound/bolitas/bingo.mp3";

import tambores from "../sound/tambores.mp3"

import { db } from "../firebaseConfig/db";
import { collection } from "firebase/firestore";
import {
  useCollectionData,
  useCollection,
  useFirestore,
} from "react-firebase-hooks/firestore";
import { doc, setDoc, addDoc, getDocs, deleteDoc } from "@firebase/firestore";

import Confetti from "react-confetti";
import Micarton from "../componentes/micarton";
import Cartonfinal from "../componentes/cartonfinal";
import { Button, Drawer, Radio, Space, Popover } from "antd";

import io from "socket.io-client";
import Temporizador from "../componentes/temporizador";
import Mostrarcarton from "../componentes/mostrarcarton";
import Micartonlibre from "../componentes/micartonlibre";
import Cartonfinallibre from "../componentes/cartonfinallibre";
import Menudeopciones from "./menudeopciones";
import Cartonfull from "../componentes/cartonfull";
import Usuariosuno from "../componentes/usuariosuno";
const socket = io(process.env.REACT_APP_SOCKET3);

var todoslosc = [];
var cartonestodos = [];


const content = (
  <Menudeopciones />
);



const content4 = (
  <div className="flex flex-row">
    <img src="https://royalinsurancellc.com/bingo/libre1.jpg" className=" border-2 border-gray-500 rounded-lg" width="40px" height="40px" alt="" />
    <img src="https://royalinsurancellc.com/bingo/libre2.jpg" className="ml-2 border-2 border-gray-500 rounded-lg" width="40px" height="35px" alt="" />
    <img src="https://royalinsurancellc.com/bingo/libre3.jpg" className="ml-2 border-2 border-gray-500 rounded-lg" width="40px" height="35px" alt="" />
    <img src="https://royalinsurancellc.com/bingo/libre4.jpg" className="ml-2 border-2 border-gray-500 rounded-lg" width="40px" height="35px" alt="" />
    <img src="https://royalinsurancellc.com/bingo/libre5.jpg" className="ml-2 border-2 border-gray-500 rounded-lg" width="40px" height="35px" alt="" />
    <img src="https://royalinsurancellc.com/bingo/libre6.jpg" className="ml-2 border-2 border-gray-500 rounded-lg" width="40px" height="35px" alt="" />
  </div>
);

const Librenew = () => {
  const { width, height } = "100%";

  const [activar, SetActivar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);


  const [juegos, setJuegos] = useState([]);

  const [principal] = useSound(menuopen);
  const [sonidofinal] = useSound(celebration);
  const [bingo] = useSound(bingoo);


  const [carton, setCarton] = useState([]);
  const [numcarton, setNumcarton] = useState([]);
  const [cartones, setCartones] = useState([]);

  const [buttons, setButtons] = useState([]);

  if(document.getElementById("bodycolor").className){
    document.getElementById("bodycolor").className = "body1";
  }

  const buscarcartones = async () => {
    //console.log("BUSCANDO CARTONES")
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE}/cartonessala3/`);
        if (!response.ok) {
            throw new Error('Error en la red');
        }
        const data = await response.json();
        if(data){
          setCartones(data);
        //console.log(data);
        }else{
          //console.log("ERROR");
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    
      
    
    }

  const [b1] = useSound(bb1);
  const [b2] = useSound(bb2);
  const [b3] = useSound(bb3);
  const [b4] = useSound(bb4);
  const [b5] = useSound(bb5);
  const [b6] = useSound(bb6);
  const [b7] = useSound(bb7);
  const [b8] = useSound(bb8);
  const [b9] = useSound(bb9);
  const [b10] = useSound(bb10);
  const [b11] = useSound(bb11);
  const [b12] = useSound(bb12);
  const [b13] = useSound(bb13);
  const [b14] = useSound(bb14);
  const [b15] = useSound(bb15);

  const [i16] = useSound(ii16);
  const [i17] = useSound(ii17);
  const [i18] = useSound(ii18);
  const [i19] = useSound(ii19);
  const [i20] = useSound(ii20);
  const [i21] = useSound(ii21);
  const [i22] = useSound(ii22);
  const [i23] = useSound(ii23);
  const [i24] = useSound(ii24);
  const [i25] = useSound(ii25);
  const [i26] = useSound(ii26);
  const [i27] = useSound(ii27);
  const [i28] = useSound(ii28);
  const [i29] = useSound(ii29);
  const [i30] = useSound(ii30);

  const [n31] = useSound(nn31);
  const [n32] = useSound(nn32);
  const [n33] = useSound(nn33);
  const [n34] = useSound(nn34);
  const [n35] = useSound(nn35);
  const [n36] = useSound(nn36);
  const [n37] = useSound(nn37);
  const [n38] = useSound(nn38);
  const [n39] = useSound(nn39);
  const [n40] = useSound(nn40);
  const [n41] = useSound(nn41);
  const [n42] = useSound(nn42);
  const [n43] = useSound(nn43);
  const [n44] = useSound(nn44);
  const [n45] = useSound(nn45);

  const [g46] = useSound(gg46);
  const [g47] = useSound(gg47);
  const [g48] = useSound(gg48);
  const [g49] = useSound(gg49);
  const [g50] = useSound(gg50);
  const [g51] = useSound(gg51);
  const [g52] = useSound(gg52);
  const [g53] = useSound(gg53);
  const [g54] = useSound(gg54);
  const [g55] = useSound(gg55);
  const [g56] = useSound(gg56);
  const [g57] = useSound(gg57);
  const [g58] = useSound(gg58);
  const [g59] = useSound(gg59);
  const [g60] = useSound(gg60);

  const [o61] = useSound(oo61);
  const [o62] = useSound(oo62);
  const [o63] = useSound(oo63);
  const [o64] = useSound(oo64);
  const [o65] = useSound(oo65);
  const [o66] = useSound(oo66);
  const [o67] = useSound(oo67);
  const [o68] = useSound(oo68);
  const [o69] = useSound(oo69);
  const [o70] = useSound(oo70);
  const [o71] = useSound(oo71);
  const [o72] = useSound(oo72);
  const [o73] = useSound(oo73);
  const [o74] = useSound(oo74);
  const [o75] = useSound(oo75);

  const [tambor] = useSound(tambores);

  //SELECCION DE NUMEROS
  const options = [];
  for (let i = 1; i <= 75; i++) {
    options.push({
      label: i,
      value: i,
    });
  }

  const msjsave = (mensajesave, tipodemensaje) => {
    if (tipodemensaje == "save") {
      toast.success(mensajesave, {});
    }
    if (tipodemensaje == "error") {
      toast.error(mensajesave, {});
    }
    if (tipodemensaje == "warning") {
      toast.warning(mensajesave, {});
    }
    if (tipodemensaje == "info") {
      toast.info(mensajesave, {});
    }
  };


  const abrirhome = () => {
    window.location.href = '/#/';
      window.location.reload();
  }

  const mensaje = (value, tipo, idcarton) => {
    if (value == "Felicidades") {
      msjsave(value, tipo);
      SetActivar(true);
      ganar(idcarton);
    }
    if (tipo == "error") {
      msjsave(value, tipo);
    }
  };

  const auth = useAuth();
  const { displayName } = auth.user;
  const { email } = auth.user;
  var primerNombre = "";
  if (displayName == undefined) {
  } else {
    let nombre = displayName;

    for (let i = 0; i < nombre.length; i++) {
      if (nombre[i] === " ") {
        i = nombre.length;
      } else {
        primerNombre += nombre[i];
      }
    }
  }
  const photoURL = auth.user.photoURL;

  const handleGoogle = (e) => {
    e.preventDefault();
    auth.loginWithGoogle();
  };
  const handleLogout = () => {
    auth.logout();
  };

  const query = collection(db, `cartones/${email}/miscartones`);
  const [docs, loading, error] = useCollectionData(query);

  const [documents, setDocuments] = useState([]);

  const [bolita, setBolita] = useState(0);
  const [sacada, setSacada] = useState([]);
  const [bolitas, setBolitas] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75,
  ]);

  const content2 = (
    <Cartonfull sacada={sacada} />
   );

  const [figuras, setFiguras] = useState({
    verticalb: ["b1","b2","b3","b4","b5"],
    verticali: ["i1","i2","i3","i4","i5"],
    verticaln: ["n1","n2","n4","n5"],
    verticalg: ["g1","g2","g3","g4","g5"],
    verticalo: ["o1","o2","o3","o4","o5"],
    horizontal1: ["b1","i1","n1","g1","o1"],
    horizontal2: ["b2","i2","n2","g2","o2"],
    horizontal3: ["b3","i3","g3","o3"],
    horizontal4: ["b4","i4","n4","g4","o4"],
    horizontal5: ["b5","i5","n5","g5","o5"],
    cruzchica: ["n2","i3","g3","n4"],
    cruzgrande: ["n1","b3","n5","o3"],
    diagonal1: ["b1","i2","g4","o5"],
    diagonal2: ["b5","i4","g2","o1"],
    cuatroesquinas: ["b1","b5","o1","o5"]
}); //ESTA PARTE ES OBLIGATORIO



  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");

  const [ganadores, setGanadores] = useState([]);
  const [cartonesganadores, setCartonesganadores] = useState([]);
  const [finalizo, setFinalizo] = useState(false);
  const [cierre, setCierre] = useState(false);
  const [premio, setPremio] = useState(0);
  const [premioinicial, setPremioinicial] = useState(0);
  const [namefigura, SetNamefigura] = useState("");
  const [figuraurl, setFiguraurl] = useState("https://royalinsurancellc.com/bingo/estrellalibre.png");
  const [tiempo, setTiempo] = useState("");
  const [iniciando, setIniciando] = useState(true);
  const [iniciando2, setIniciando2] = useState(false);
  const [salir, setSalir] = useState(false);
  const [emaillocal, setEmaillocal] = useState("user@example.com");

  const [solouno, setSolouno] = useState([]);
  const [misala, setMisala] = useState("sala3");

  const content3 = (
    <Usuariosuno solouno={solouno} sala="sala3" />
   );

  const [emailf, setEmailf] = useState("");

  const [min, setMin] = useState(0);
  const [seg, setSeg] = useState(0);

  const [users, setUsers] = useState([]);
  const [users2, setUsers2] = useState([]); 
  const [juego, setJuego] = useState([]);
  const [cartonesusers, setCartonesusers] = useState([]);
  const [cartonesusers2, setCartonesusers2] = useState([]);
  const [participantes, setParticipantes] = useState([]);
  const [cards, setCards] = useState("cartones")
  const [saldo, setSaldo] = useState(0)
  const [regalos, setRegalos] = useState(0)

  const gettodosloscartones = () => {
   
    const datos = {
      sala: "sala3",
  }

   //enviar datos
   fetch(`${process.env.REACT_APP_BASE}/obtenercartones`, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json',
      },
      body: JSON.stringify(datos),
      })
      .then((response) => response.json())
      .then((result) => {
      if (result) {
          setCartonesusers2(result);
      } else {
      //console.log('ERROR DE CONEXION');
      }
      })
      .catch((error) => {
      //console.log(error);
      });
      //fin de envio
  };

  const obtenermiscartones = async () => {

    const datos = {
        email: email,
        sala:  "sala3",
    }

     //enviar datos
     fetch(`${process.env.REACT_APP_BASE}/obtenermiscartones`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify(datos),
        })
        .then((response) => response.json())
        .then((result) => {
        if (result) {
            setDocuments(result);
        } else {
        //console.log('ERROR DE CONEXION');
        }
        })
        .catch((error) => {
        //console.log(error);
        });
        //fin de envio

  }

  
  const volver = () => {
    window.location.href = '/';
     
  }

  const obtenermiscartones3 = () => {
    const collectionRef = collection(db, `cartones/${emailf}/miscartones`);
    getDocs(collectionRef).then((querySnapshot) => {
      const documentsArray = [];
      querySnapshot.forEach((doc) => {
        documentsArray.push({
          id: doc.id,
          data: doc.data(),
        });
        //console.log(documentsArray);
      });
      setDocuments(documentsArray);
    });
  };

  const getusers = async () => {
    const protemploCollection = collection(db, "cartones");
    const data = await getDocs(protemploCollection);
    setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

    const getusers2 = async () => {
  
      const sala = "sala3"
  
      try {
  
        const response = await fetch(`${process.env.REACT_APP_BASE}/obtenerusers/`, {
          method: 'POST', // Especifica el método POST
          headers: {
              'Content-Type': 'application/json', // Indica que el cuerpo de la solicitud es JSON
          },
          body: JSON.stringify({ sala }) // Convierte el objeto a una cadena JSON
      });
  
          if (!response.ok) {
              throw new Error('Error en la red');
          }
          const data = await response.json();
          if(data){
            if(data.message == "no hay"){
              setUsers2([])
            }else
            {
              setUsers2(data.usuarios)
            }
          //console.log(data);
          }else{
            //console.log("ERROR");
          }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
    };

  const registraruser = async () => {
    
    if (email != undefined) {
      const datos = {
        email: email,
        nombre: displayName,
        photo: photoURL,
        referido: "enmarycarrasco@gmail.com",
        usuario: ""
      };

      registrarusuario(datos)
    }
  };


  const registrarusuario = async (datos) => {

   
    //enviar datos
    fetch(`${process.env.REACT_APP_BASE}/nuevouser`, {
    method: 'POST',
    headers: {
    'Content-Type': 'application/json',
    },
    body: JSON.stringify(datos),
    })
    .then((response) => response.json())
    .then((result) => {
    if (result) {
    //console.log(result);
    } else {
    //console.log('ERROR DE CONEXION');
    }
    })
    .catch((error) => {
    //console.log(error);
    });
    //fin de envio
 

  }


  const chequearexistencia = async () => {

    const datos = {
        email: email,
    }

     //enviar datos
     fetch(`${process.env.REACT_APP_BASE}/chequearexistencia`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify(datos),
        })
        .then((response) => response.json())
        .then((result) => {
        if (result) {
            if(result.message == "Si existe"){
                //console.log(result.message)
                setSaldo(result.saldo)
                setRegalos(result.regalos)
            }
            else{
                //console.log("no existe")
               registraruser()
            }
        } else {
        //console.log('ERROR DE CONEXION');
        }
        })
        .catch((error) => {
        //console.log(error);
        });
        //fin de envio

  }

  const chequearexistencia2 = () => {
    var existe = false;

    for (let i = 0; i < users.length; i++) {
      if (users[i].email == emailf) {
        existe = true;
      }
    }
    //console.log(existe);
    if (existe == true) {
      obtenermiscartones3();
    }
  };


   //compras y regalo de cartones
   const comprar =(idcarton)  => {

    

    const newMessage = {
      body: [
        "comprarcarton"
      ],
      from: "Administrador",
    };

    socket.emit("message", newMessage.body);

    

  }

  const comprarcarton = (idcarton, email) => {

    var boton = document.getElementById("botoncomprar");
    boton.disabled = true;


    const datos = {
      email: email,
      id: idcarton,
      sala: "sala3"
  }

     //enviar datos
     fetch(`${process.env.REACT_APP_BASE}/comprarcarton`, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json',
      },
      body: JSON.stringify(datos),
      })
      .then((response) => response.json())
      .then((result) => {
      if (result) {
      //console.log(result.message);
      if(result.message == "Compra Exitosa"){
        comprar()
        msjsave("Compra Exitosa", "save")
          buscarcartones();
          listadejuegos();
          chequearexistencia();
          setShowModal2(false)
      }else{
        if(result.message == "Saldo Insuficiente"){
          msjsave("No tienes Fondos Suficientes, Recarga tu Saldo", "error")
          buscarcartones();
          chequearexistencia()
          setShowModal2(false)
        }
        if(result.message == "No disponible"){
          msjsave("El Carton ya no esta Disponible", "error")
          buscarcartones();
          setShowModal2(false)
        }
        if(result.message == "Sala Cerrada"){
          msjsave("La SALA esta Cerrada", "error")
          buscarcartones();
          listadejuegos();
          setShowModal2(false)
        }
      }
      } else {
      //console.log('ERROR DE CONEXION');
      }
      })
      .catch((error) => {
      //console.log(error);
      });
      //fin de envio

  }


  const regalo =(idcarton)  => {

    

    const newMessage = {
      body: [
        "regalarcarton"
      ],
      from: "Administrador",
    };

    socket.emit("message", newMessage.body);
  }


  const regalarcarton = (idcarton, email) => {

    var boton = document.getElementById("botonregalar");
    boton.disabled = true;


    const datos = {
      email: email,
      id: idcarton,
      sala: "sala3"
  }

     //enviar datos
     fetch(`${process.env.REACT_APP_BASE}/regalarcarton`, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json',
      },
      body: JSON.stringify(datos),
      })
      .then((response) => response.json())
      .then((result) => {
      if (result) {
      //console.log(result.message);
      if(result.message == "Regalo Exitoso"){
        comprar()
        msjsave("Carton de Regalo Exitoso", "save")
          buscarcartones();
          listadejuegos();
          chequearexistencia();
          setShowModal2(false)
      }else{
        if(result.message == "Saldo Insuficiente"){
          msjsave("No tienes Cupos de Regalo Disponible", "error")
          buscarcartones();
          chequearexistencia()
          setShowModal2(false)
        }
        if(result.message == "No disponible"){
          msjsave("El Carton ya no esta Disponible", "error")
          buscarcartones();
          setShowModal2(false)
        }
        if(result.message == "Sala Cerrada"){
          msjsave("La SALA esta Cerrada", "error")
          buscarcartones();
          listadejuegos();
          setShowModal2(false)
        }
      }
      } else {
      //console.log('ERROR DE CONEXION');
      }
      })
      .catch((error) => {
      //console.log(error);
      });
      //fin de envio

  }

 

  const obtenernombre = (correo, tipo) => {
    for (let i = 0; i < users2.length; i++) {
      if (users2[i].email == correo) {
        let nombre = users2[i].nombre;
        var PrimerN = "";
        for (let a = 0; a < nombre.length; a++) {
          if (nombre[a] === " ") {
            a = nombre.length;
          } else {
            PrimerN += nombre[a];
          }
        }
        if (tipo == "nombre") {
          return PrimerN;
        }
        if (tipo == "foto") {
          return users2[i].photo;
        }
      }
    }
  };

  const revisarbolita = (bola) => {
    if (bola === 1) {
      b1();
    }
    if (bola === 2) {
      b2();
    }
    if (bola === 3) {
      b3();
    }
    if (bola === 4) {
      b4();
    }
    if (bola === 5) {
      b5();
    }
    if (bola === 6) {
      b6();
    }
    if (bola === 7) {
      b7();
    }
    if (bola === 8) {
      b8();
    }
    if (bola === 9) {
      b9();
    }
    if (bola === 10) {
      b10();
    }
    if (bola === 11) {
      b11();
    }
    if (bola === 12) {
      b12();
    }
    if (bola === 13) {
      b13();
    }
    if (bola === 14) {
      b14();
    }
    if (bola === 15) {
      b15();
    }
    if (bola === 16) {
      i16();
    }
    if (bola === 17) {
      i17();
    }
    if (bola === 18) {
      i18();
    }
    if (bola === 19) {
      i19();
    }
    if (bola === 20) {
      i20();
    }
    if (bola === 21) {
      i21();
    }
    if (bola === 22) {
      i22();
    }
    if (bola === 23) {
      i23();
    }
    if (bola === 24) {
      i24();
    }
    if (bola === 25) {
      i25();
    }
    if (bola === 26) {
      i26();
    }
    if (bola === 27) {
      i27();
    }
    if (bola === 28) {
      i28();
    }
    if (bola === 29) {
      i29();
    }
    if (bola === 30) {
      i30();
    }
    if (bola === 31) {
      n31();
    }
    if (bola === 32) {
      n32();
    }
    if (bola === 33) {
      n33();
    }
    if (bola === 34) {
      n34();
    }
    if (bola === 35) {
      n35();
    }
    if (bola === 36) {
      n36();
    }
    if (bola === 37) {
      n37();
    }
    if (bola === 38) {
      n38();
    }
    if (bola === 39) {
      n39();
    }
    if (bola === 40) {
      n40();
    }
    if (bola === 41) {
      n41();
    }
    if (bola === 42) {
      n42();
    }
    if (bola === 43) {
      n43();
    }
    if (bola === 44) {
      n44();
    }
    if (bola === 45) {
      n45();
    }
    if (bola === 46) {
      g46();
    }
    if (bola === 47) {
      g47();
    }
    if (bola === 48) {
      g48();
    }
    if (bola === 49) {
      g49();
    }
    if (bola === 50) {
      g50();
    }
    if (bola === 51) {
      g51();
    }
    if (bola === 52) {
      g52();
    }
    if (bola === 53) {
      g53();
    }
    if (bola === 54) {
      g54();
    }
    if (bola === 55) {
      g55();
    }
    if (bola === 56) {
      g56();
    }
    if (bola === 57) {
      g57();
    }
    if (bola === 58) {
      g58();
    }
    if (bola === 59) {
      g59();
    }
    if (bola === 60) {
      g60();
    }
    if (bola === 61) {
      o61();
    }
    if (bola === 62) {
      o62();
    }
    if (bola === 63) {
      o63();
    }
    if (bola === 64) {
      o64();
    }
    if (bola === 65) {
      o65();
    }
    if (bola === 66) {
      o66();
    }
    if (bola === 67) {
      o67();
    }
    if (bola === 68) {
      o68();
    }
    if (bola === 69) {
      o69();
    }
    if (bola === 70) {
      o70();
    }
    if (bola === 71) {
      o71();
    }
    if (bola === 72) {
      o72();
    }
    if (bola === 73) {
      o73();
    }
    if (bola === 74) {
      o74();
    }
    if (bola === 75) {
      o75();
    }

    //console.log(bola);
  };

  const obtenerjuego2 = (sala, tipo) => {
    for (let i = 0; i < juegos.length; i++) {
      if (juegos[i].nombre === sala) {
        if (tipo === "premio") {
          return juegos[i].premio;
        }
        if (tipo === "estatus") {
          return juegos[i].estatus;
        }
        if (tipo === "fecha") {
          return juegos[i].fecha;
        }
      }
    }
  };

  const obtenerjuegoinfo = (sala, tipo) => {
    for (let i = 0; i < juego.length; i++) {
      if (juego[i].nombre === sala) {
        if (tipo === "namefigura") {
          return juego[i].namefigura;
        }
        // if (tipo === "figuraurl") {
        //   return juego[i].figuraurl;
        // }
        if (tipo === "bolita") {
          return juego[i].bolita;
        }
        if (tipo === "casillas") {
          return JSON.parse(juego[i].casillas);
        }
        if (tipo === "ganadores") {
          return JSON.parse(juego[i].ganadores);
        }
        if (tipo === "cartonesganadores") {
          return JSON.parse(juego[i].cartonesganadores);
        }
      }
    }
  };


  const obtenerparticipantes = async () => {

    setCards("participantes")
    
    const sala = "sala3"

    try {

      const response = await fetch(`${process.env.REACT_APP_BASE}/obtenerparticipantes/`, {
        method: 'POST', // Especifica el método POST
        headers: {
            'Content-Type': 'application/json', // Indica que el cuerpo de la solicitud es JSON
        },
        body: JSON.stringify({ sala }) // Convierte el objeto a una cadena JSON
    });

        if (!response.ok) {
            throw new Error('Error en la red');
        }
        const data = await response.json();
        if(data){
          if(data.message == "no hay"){
            setParticipantes([])
          }else
          {
            setParticipantes(data.usuarios)
          }
        //console.log(data);
        }else{
          //console.log("ERROR");
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    //console.log("SE EJECUTO")
    // if(activaintervalo){
    //   setActivaintervalo(false)
    // }else{
    //   setActivaintervalo(true)
    // }
};


const menucartones = () => {
  gettodosloscartones()
    setCards("cartones")
  }
  
    const listadejuegos = async () => {
      
      try {
          const response = await fetch(`${process.env.REACT_APP_BASE}/obtenerjuegos/`);
          if (!response.ok) {
              throw new Error('Error en la red');
          }
          const data = await response.json();
          if(data){
            setJuegos(data);
            //console.log("ACTUALIZANDO JUEGOS")
          //console.log(data);
          }else{
            //console.log("ERROR");
          }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
      //console.log("SE EJECUTO")
      // if(activaintervalo){
      //   setActivaintervalo(false)
      // }else{
      //   setActivaintervalo(true)
      // }
  };


  const listainfojuegos = async () => {
    
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE}/obtenerinfojuegos/`);
        if (!response.ok) {
            throw new Error('Error en la red');
        }
        const data = await response.json();
        if(data){
          setJuego(data);
          //console.log("ACTUALIZANDO INFOJUEGOS")
        //console.log(data);
        }else{
          //console.log("ERROR");
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    //console.log("SE EJECUTO")
    // if(activaintervalo){
    //   setActivaintervalo(false)
    // }else{
    //   setActivaintervalo(true)
    // }
  };


  const obtenerprimernombre = (usuario, tipo) => {

    for (let i = 0; i < participantes.length; i++) {
      if (participantes[i].email == usuario) {
        if(tipo == "nombre"){
          var nombre = participantes[i].nombre;
          var primerNombre = ""
    
      for (let i = 0; i < nombre.length; i++) {
        if (nombre[i] === " ") {
          i = nombre.length;
        } else {
          primerNombre += nombre[i];
        }
      }
          return primerNombre;
        }
  
      }
    }
  
  }

  const actualizarcartones = () => {
    //console.log("HOLA")
  }

  const obtenertotal = (emailc) => {
    var total  = 0;
    for (let i = 0; i < cartones.length; i++) {
      if(cartones[i].propietario == emailc){
        total += 1
      }
    }
    return total
  }

  useEffect(() => {
    listadejuegos()
    listainfojuegos()
    buscarcartones()
    obtenermiscartones()
 getusers2()
 
 }, []);
 
 useEffect(() => {
 console.log(users2)
 }, [users2]);

  useEffect(() => {
    //console.log(cartones)
    const newButtons = [];
    for (let i = 0; i < cartones.length; i++) {
      if(cartones[i].estatus === "disponible"){
        newButtons.push(
          <button
            key={i}
            type="button"
            onClick={() => {
            
            setNumcarton(cartones[i].id)
            setCarton(cartones[i])
            setShowModal2(true);
            }}
            className="text-white w-14 bg-[#3b5998] focus:ring-4 focus:outline-none focus:ring-[#000]/50 font-medium rounded-lg text-sm px-2 py-2 text-center"
          >
            #{cartones[i].id}
          </button>
        );
      }
  
      if(cartones[i].estatus === "comprado"){
        newButtons.push(
          <button
            key={i}
            type="button"
            className="text-white w-14 bg-green-500 focus:ring-4 focus:outline-none focus:ring-[#000]/50 font-medium rounded-lg text-sm px-2 py-2 text-center"
          >
            #{cartones[i].id}
          </button>
        );
      }
  
      if(cartones[i].estatus === "regalo"){
        newButtons.push(
          <button
            key={i}
            type="button"
            className="text-white w-14 bg-red-500 focus:ring-4 focus:outline-none focus:ring-[#000]/50 font-medium rounded-lg text-sm px-2 py-2 text-center"
          >
            #{cartones[i].id}
          </button>
        );
      }
      
    }
    setButtons(newButtons)
  }, [cartones]);

  useEffect(() => {
    if (email === undefined) {
      obtenermiscartones();
      getusers();
     
      chequearexistencia();
      //setShowModal2(true);
    } else {
      obtenermiscartones();
      getusers();
     
      chequearexistencia();
      setShowModal2(false);
    }

  }, [email]);

  useEffect(() => {
    //console.log(emailf);
    chequearexistencia2(emailf);
  }, [emailf]);

  useEffect(() => {
    socket.on("message", receiveMessage);

    return () => {
      socket.off("message", receiveMessage);
    };
  }, []);



  useEffect(() => {
    //console.log(participantes)
  }, [participantes]);

  useEffect(() => {

    if(showModal2){
      document.getElementById("botoncomprar").disabled = false;
      document.getElementById("botonregalar").disabled = false;
  }

  }, [showModal2]);

  useEffect(() => {
    SetNamefigura(obtenerjuegoinfo("sala3", "namefigura"))
    setBolita(obtenerjuegoinfo("sala3", "bolita"))
    //setFiguraurl(obtenerjuegoinfo("sala3", "figuraurl"))
    //setFiguras(obtenerjuegoinfo("sala3", "casillas"))


    //console.log(juego)

  }, [juego]);

  useEffect(() => {
    
    

    if(obtenerjuego2("sala3", "estatus") == "En Espera"){
      setPremio(obtenerjuego2("sala3", "premio"))
      setPremioinicial(obtenerjuego2("sala3", "premio"))
    }

    if(obtenerjuego2("sala3", "estatus") == "Jugando"){
      setIniciando(false);
      setIniciando2(false);
      setPremio(obtenerjuego2("sala3", "premio"))
      setPremioinicial(obtenerjuego2("sala3", "premio"))
    }

    if(obtenerjuego2("sala3", "estatus") == "Finalizado"){
      setIniciando(false);
      setIniciando2(false);

      
    }


    //console.log(juegos)

  }, [juegos]);


  const receiveMessage = (message) => setMessages((state) => [message]);

  useEffect(() => {
    // if (finalizo == false) {
    //   if (messages.length > 0) {
    //     if (messages[0].body[0] != "Empezando") {
    //       //console.log(messages);
    //       if (messages[0].body[6] === "juego") {
    //         setBolita(messages[0].body[0]);
    //         setSacada(messages[0].body[1]);
    //         setBolitas(messages[0].body[2]);
    //         setFiguras(messages[0].body[3]);
    //         setIniciando(false);
    //         //principal();
    //         revisarbolita(messages[0].body[0]);
    //       }
    //     }
    //   }
    // }
    // if (messages.length > 0) {
    //   if (messages[0].body[4] == "Ganador") {
    //     setGanadores(messages[0].body[5]);
    //     setCartonesganadores(messages[0].body[6]);

    //     setFinalizo(true);
    //     bingo();
    //   }
    // }
    // if (messages.length > 0) {
    //   if (messages[0].body[4] == "Finalizado") {
    //     setCartonesusers2(JSON.parse(messages[0].body[7]));
    //     //console.log(JSON.parse(messages[0].body[7]));
    //     setShowModal(true);
    //     setCierre(true);
    //     setIniciando(false);
    //   }
    // }

    // if (messages.length > 0) {
    //   if (messages[0].body[0] == "Empezando") {
    //     //console.log("ESTAMOS EMPEZANDO");
    //     if (messages[0].body[2] === "juego") {
    //       setFinalizo(false);
    //       setCierre(false);
    //       SetActivar(false);
    //       setGanadores([]);
    //       setCartonesganadores([]);
    //       setShowModal(false);
    //       setBolita(0);
    //       setSacada([]);
    //       setTiempo(messages[0].body[1]);
    //       SetNamefigura(messages[0].body[3]);
    //       setFiguraurl(messages[0].body[4]);

    //       setIniciando(true);
    //     }
    //   }
    // }
    
    // if (premioinicial == 0) {
    //   obtenerjuego("premio");
    // }
    // if (namefigura == "") {
    //   obtenerjuego("figura");
    // }
    // if (figuraurl == "") {
    //   obtenerjuego("figuraurl");
    // }

    //console.log(messages)
    if (messages.length > 0 && messages[0].body) {


      if(messages[0].body[0]=="DEVOLUCION"){
        if(email == messages[0].body[1]){
          msjsave("HEMOS DEVUELTO SU SALDO, YA QUE NO HAY MAS JUGADORES PARA JUGAR", "warning")
          buscarcartones();
          chequearexistencia()
          listadejuegos()
        }
        
      }

      if(messages[0].body[0]=="REINICIO"){


          listadejuegos();
          buscarcartones();
          chequearexistencia()
          setShowModal(false)
          setShowModal2(false)
          setIniciando2(false)
          setIniciando(true)
          SetActivar(false)
          gettodosloscartones()
          setFinalizo(false);
          setBolita(0)
          setSacada([])
          setBolitas([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75,])
          setFiguraurl("https://royalinsurancellc.com/bingo/estrellalibre.png")
          SetNamefigura("Formas de Ganar")
          listainfojuegos()


        
      }

      if(messages[0].body[0]=="EMPEZANDO"){

        setFinalizo(false);
           setCierre(false);
           SetActivar(false);
           setGanadores([]);
           setCartonesganadores([]);
           setShowModal(false);
           setBolita(0);
           setSacada([]);
           setTiempo(messages[0].body[1]);
           SetNamefigura(messages[0].body[3]);
           //setFiguraurl(messages[0].body[4]);
           setIniciando2(true);
           setIniciando(false)
           obtenermiscartones();
           //console.log("ESTAMOS EMPEZANDO "+messages[0].body[1]);
           getusers2();
           

      }

      if(messages[0].body[0]=="JUGANDO"){

        setBolita(messages[0].body[1]);
             setSacada(messages[0].body[2]);
             setBolitas(messages[0].body[3]);
             //setFiguras(messages[0].body[4]);
             setIniciando(false);
             setIniciando2(false)
             //principal();
             revisarbolita(messages[0].body[1]);

      }

      if(messages[0].body[0] == "GANADOR"){
        
        setGanadores(messages[0].body[1]);
        setCartonesganadores(messages[0].body[2]);

         setFinalizo(true);
         bingo();
         obtenerparticipantes();
         menucartones()
         getusers2();
      }

      if(messages[0].body[0] == "FINALIZADO"){

        setGanadores(messages[0].body[1]);
        setCartonesganadores(messages[0].body[2]);
              gettodosloscartones();
             setShowModal(true);
             setCierre(true);
             setIniciando(false);
             listadejuegos();
             listainfojuegos();
             getusers2();
             setSolouno([])
      }


      if(messages[0].body[0] == "actualizarcartones"){
        buscarcartones();
        listadejuegos();
        listainfojuegos();
      }

      if(messages[0].body[0] == "FALTAUNO"){
        console.log(messages[0].body[1]);
        setSolouno(messages[0].body[1])
        tambor()
      }
      //console.log(messages[0].body)
    }

    //console.log(messages[0].body)

  }, [messages]);

  const ganar = (idcarton) => {
    var ganador = [];
    var cartonganador = [];
    for (let i = 0; i < ganadores.length; i++) {
      ganador.push(ganadores[i]);
    }
    for (let i = 0; i < cartonesganadores.length; i++) {
      cartonganador.push(cartonesganadores[i]);
    }

    if (!cartonganador.includes(idcarton)) {
      if (email == undefined) {
        ganador.push(emailf);
      } else {
        ganador.push(email);
      }

      cartonganador.push(idcarton);
      setGanadores(ganador);
      setCartonesganadores(cartonganador);
      const newMessage = {
        body: ["GANADOR",
          ganador,
          cartonganador,
        ],
        from: "Administrador",
      };
      setFinalizo(true);
      //setShowModal(true);
      sonidofinal();
      socket.emit("message", newMessage.body);
      obtenerparticipantes();
         menucartones()
    } else {
      msjsave("El Carton ya Gano, No intentes hacer trampa", "error");
    }
  };

  useEffect(() => {
    if (ganadores.length == 0) {
    } else {
      let premionuevo = parseFloat(premioinicial) / parseInt(ganadores.length);
      setPremio(premionuevo.toFixed(2));
      //console.log(premionuevo)
    }
    //console.log(premioinicial)
    
    //console.log(ganadores)
  }, [ganadores]);

  useEffect(() => {
    //console.log(users);
    //gettodosloscartones();
    //console.log(juegos)
  }, [listadejuegos]);

  useEffect(() => {
    //console.log(cartonesusers);
  }, [cartonesusers]);

  useEffect(() => {
    //console.log(cartonesusers2);
  }, [cartonesusers2]);

  useEffect(() => {
    setMin(Math.floor(parseInt(tiempo) / 60));
    setSeg(parseInt(tiempo) % 60);
  }, [tiempo]);

  useEffect(() => {
    //console.log(figuraurl);
  }, [figuraurl]);



  return (
    <>
      <div className="container2 w-screen">
        <div className=" p-2 h-28 w-full flex place-content-between items-center">
          <div className="m-0 flex flex-wrap flex-col place-content-around">
   
              <img
                onClick={() => {
                  abrirhome();
                  }}
                    className="pt-2 mt-[-10px] cursor-pointer"
                src={logo}
                width="300px"
                height="100%"
                alt="logo"
              />
            
            <p className="p-1"></p>
          </div>

          {displayName == undefined ? (
            <button
              type="button"
              onClick={(e) => handleGoogle(e)}
              class="text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 me-2 mb-2"
            >
              <svg
                class="w-4 h-4 me-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 19"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z"
                  clip-rule="evenodd"
                />
              </svg>
              Entrar
            </button>
          ) : (
            <div className="flex">
              <div className="p-2">
                <div className="flex flex-col items-end gap-4 ">
                
                <div>
                <div className="text-white text-[14px] font-medium text-left ml-6 mt-[-5px]">
                      {displayName && <> {primerNombre} </>}
                    </div>
                    <div className="bg-blue-950 w-[175px] h-10 rounded-lg grid grid-cols-3 gap-3" >

                  <img
                    onClick={() => {
                      setSalir(true);
                    }}
                    className="w-10 h-10 ml-[-5px]  rounded-full border-2 border-blue-500"
                    src={`${photoURL}`}
                    alt=""
                  />
                  <div className="bg-blue-950 w-[60px] h-10 ml-[-15px] pt-2">
                    <span className="text-[12px] relative"><svg class=" absolute w-[24px] ml-[-4px] h-[24px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8H5m12 0a1 1 0 0 1 1 1v2.6M17 8l-4-4M5 8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.6M5 8l4-4 4 4m6 4h-4a2 2 0 1 0 0 4h4a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1Z"/>
</svg>

 <span className="absolute mt-[0px] w-16 ml-[22px] bg-gray-600 rounded-xl p-1 pl-2 pr-2 text-[12px] font-medium shadow-md">{parseInt(saldo)} Bs</span></span>
                    
                  </div>
                  <div className="bg-blue-950 rounded-r-lg flex flex-row place-content-center mt-[6px]">
                  <Popover placement="bottomRight" title="Configuración de Cuenta" content={content}>
            <svg class="w-[32px] h-[32px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M17 10v1.126c.367.095.714.24 1.032.428l.796-.797 1.415 1.415-.797.796c.188.318.333.665.428 1.032H21v2h-1.126c-.095.367-.24.714-.428 1.032l.797.796-1.415 1.415-.796-.797a3.979 3.979 0 0 1-1.032.428V20h-2v-1.126a3.977 3.977 0 0 1-1.032-.428l-.796.797-1.415-1.415.797-.796A3.975 3.975 0 0 1 12.126 16H11v-2h1.126c.095-.367.24-.714.428-1.032l-.797-.796 1.415-1.415.796.797A3.977 3.977 0 0 1 15 11.126V10h2Zm.406 3.578.016.016c.354.358.574.85.578 1.392v.028a2 2 0 0 1-3.409 1.406l-.01-.012a2 2 0 0 1 2.826-2.83ZM5 8a4 4 0 1 1 7.938.703 7.029 7.029 0 0 0-3.235 3.235A4 4 0 0 1 5 8Zm4.29 5H7a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h6.101A6.979 6.979 0 0 1 9 15c0-.695.101-1.366.29-2Z" clip-rule="evenodd"/>
</svg>
          </Popover>
                  
                  

                  </div>
                  </div>
                </div>
                  
                  

                  


                </div>
              </div>

              <div></div>
            </div>
          )}
        </div>
      </div>
      {email != undefined || emailf != "" ? (
        <>
          <div class=" flex">
            <div className="relative m-2 mt-10 bg-yellow-500 text-lg text-blue-800 font-mono pl-[70px] font-bold h-[50px] w-[96%] rounded-lg border-2">
              <span className=" absolute m-0 mt-6 text-sm text-cyan-700">
                Premio
              </span>
              <span className="pl-2 pr-2 absolute m-0 mt-6 ml-[55px] text-cyan-300 text-sm font-bold bg-slate-800 border-slate-500 rounded-lg">
                {obtenerjuego2("sala3","premio")} Bs
              </span>
              SALA LIBRE
            </div>
            <div className="absolute">
             <div onClick={() => {
             volver();
             }} className="contenedoricono cursor-pointer z-10 ml-1 mt-8">
                <div className="moneda">

                </div>
                </div>
            </div>
            <div className="m-2 items-end mt-10 rounded-l-lg border-2 absolute h-[50px]"></div>
     
    <Popover content={content4} placement="bottomRight" title="Formas de Ganar" trigger="click">
    <img
              src={figuraurl}
              className="m-2 items-end ml-[300px] mt-5 rounded-lg border-2 absolute h-[50px] bg-slate-300"
            />
    </Popover>

            
            <span className="pl-2 pr-2 w-[120px] absolute m-0 mt-20 ml-[265px] text-cyan-300 text-sm font-bold bg-slate-800 border-slate-500 rounded-lg text-center">
            {namefigura}
            </span>
          </div>
          {iniciando == true ? (
            <>
            <table width="100%" style={{border: "0"}}>
              <tr width="60%">
                <td>
                <div className="w-50% ml-4">
                  <div className="flex flex-col place-content-end">
                  <div className="text-[14px] text-red-700 font-sans font-bold">Aprovecha <span className="text-white p-1 font-bold bg-red-500 rounded-xl">80% del Pote</span> </div>
                <div class="inline-flex mt-2 rounded-md shadow-sm" role="group">
  <button onClick={() => {
  menucartones();
  }} type="button" class="inline-flex bg-[#09096c] items-center px-4 py-2 text-[12px] font-medium text-gray-200 border border-gray-900 rounded-s-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white">
  <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M4.857 3A1.857 1.857 0 0 0 3 4.857v4.286C3 10.169 3.831 11 4.857 11h4.286A1.857 1.857 0 0 0 11 9.143V4.857A1.857 1.857 0 0 0 9.143 3H4.857Zm10 0A1.857 1.857 0 0 0 13 4.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 21 9.143V4.857A1.857 1.857 0 0 0 19.143 3h-4.286Zm-10 10A1.857 1.857 0 0 0 3 14.857v4.286C3 20.169 3.831 21 4.857 21h4.286A1.857 1.857 0 0 0 11 19.143v-4.286A1.857 1.857 0 0 0 9.143 13H4.857ZM18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z" clip-rule="evenodd"/>
</svg>

    Cartones
  </button>
 
  <button onClick={() => {
  obtenerparticipantes();
  }} type="button" class="inline-flex bg-[#09096c] items-center px-4 py-2 text-[12px] font-medium text-gray-200 border border-gray-900 rounded-e-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white">
  <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M12 20a7.966 7.966 0 0 1-5.002-1.756l.002.001v-.683c0-1.794 1.492-3.25 3.333-3.25h3.334c1.84 0 3.333 1.456 3.333 3.25v.683A7.966 7.966 0 0 1 12 20ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10c0 5.5-4.44 9.963-9.932 10h-.138C6.438 21.962 2 17.5 2 12Zm10-5c-1.84 0-3.333 1.455-3.333 3.25S10.159 13.5 12 13.5c1.84 0 3.333-1.455 3.333-3.25S13.841 7 12 7Z" clip-rule="evenodd"/>
</svg>

 &nbsp;Participantes
  </button>
</div></div>
                </div>
                </td>
                <td width="40%">
                <div className="h-[85px] rounded-xl border-2 border-blue-900 m-4 flex flex-col place-content-center justify-center items-center bg-[#e36c4c]">
                <img src={fotoreloj} width="70px" height="50px"/>
               
                  <span className=" text-md text-white  font-semibold">
                  <Temporizador tiempo1={obtenerjuego2("sala3", "fecha")} />
                  </span>
               
              </div>
                </td>
              </tr>
            </table>
              
              {cards == 'cartones' ? (<div className=" flex flex-col ml-4 mr-4 place-content-center  bg-gray-200 border-2 rounded-lg p-0 text-[14px]">
                <div className="bg-gray-500 rounded-t-lg m-0 h-[60px] flex flex-row place-content-center items-center justify-center"><span className="bg-blue-900 h-4 w-4 rounded-md"></span> <span className="text-white">&nbsp;Disponibles</span> &nbsp;&nbsp; <span className="bg-green-600 h-4 w-4 rounded-md"></span> <span className="text-white"> &nbsp;Comprados</span>&nbsp;&nbsp; <span className="bg-red-600 h-4 w-4 rounded-md"></span> <span className="text-white"> &nbsp;Regalos</span></div>
                <div className="grid grid-cols-5 gap-5 place-content-center items-center justify-center m-4">{buttons}</div>
                
              </div>) : (<div className=" flex flex-col ml-4 mr-4 place-content-center  bg-gray-200 border-2 rounded-lg p-0 text-[14px]">
                <div className="bg-gray-500 rounded-t-lg m-0 h-[60px] flex flex-row place-content-center items-center justify-center"><svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M12 20a7.966 7.966 0 0 1-5.002-1.756l.002.001v-.683c0-1.794 1.492-3.25 3.333-3.25h3.334c1.84 0 3.333 1.456 3.333 3.25v.683A7.966 7.966 0 0 1 12 20ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10c0 5.5-4.44 9.963-9.932 10h-.138C6.438 21.962 2 17.5 2 12Zm10-5c-1.84 0-3.333 1.455-3.333 3.25S10.159 13.5 12 13.5c1.84 0 3.333-1.455 3.333-3.25S13.841 7 12 7Z" clip-rule="evenodd"/>
</svg>
 <span className="text-white">&nbsp;Participantes del Juego</span> &nbsp;</div>
                <div className="flex flex-row place-content-start m-4">

                <div className="">
                  {participantes.map((val, key) => {
                  return (
                    
                    <div key={key} className="flex flex-row flex-grow m-1">
                      <img
                     
                      className="w-10 h-10 cursor-pointer  rounded-full border-2 border-blue-500"
                      src={`${val.photo}`}
                      alt=""
                    />
                    <div className=" text-sm ml-2 mt-3 text-blue-600 font-bold">
                        {val.nombre}  <span className="text-red-600 font-bold">({obtenertotal(val.email)}) Cartones</span>
                      </div>
                  </div>
                  );
                  })}
                </div>
                </div>
                
              </div>)}
              
      
            </>
          ) : (
            <></>
          )}
          {iniciando2 == true ? (
            <>
              <div className="h-[100px] rounded-xl border-2 border-blue-900 m-4 flex place-content-center bg-[#e36c4c]">
                <img src={fotoreloj} />
                {min == 0 ? (
                  <span className="mt-[32px] text-2xl text-white  font-semibold">
                    {seg} Segundos
                  </span>
                ) : (
                  <span className="mt-[32px] text-2xl text-white  font-semibold">
                    {min}:{seg} Segundos
                  </span>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
          {cierre == true ? (
            <div className="flex flex-wrap place-content-center m-4">
              {Object.keys(cartonesusers2).map((key, index) => {
                  return (
                    <>
                {cartonesusers2[key].estatus !== "disponible" ? (
                  <div key={index} className="p-4">
                    <Cartonfinallibre
                      key={`${key}${index}`}
                      id={cartonesusers2[key].id}
                      idcarton={cartonesganadores}
                      nombre={obtenerprimernombre(cartonesusers2[key].propietario, "nombre")}
                      photo=""
                      numeros={cartonesusers2[key]}
                      sacada={sacada}
                      figuras={figuras}
                      bolitas={bolitas}
                    />


</div>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
            </div>
          ) : (
            <>
            {iniciando == true ? (<></>) : (<>
              <div className=" flex flex-grow flex-row">
                <SwitchTransition mode="out-in">
                  <CSSTransition
                    key={bolita}
                    addEndListener={(node, done) =>
                      node.addEventListener("transitionend", done, false)
                    }
                    classNames="fade"
                  >
                    {bolita > 0 && bolita < 16 ? (
                      <div>
                        <div className="p-4 pt-2">
                          <span className="ml-[20px] mt-[-6px] absolute rounded-lg border-1 pl-2 pr-2 bg-blue-600 text-sm font-mono font-semibold">
                            B
                          </span>
                          <div className=" text-center pt-[10px] text-3xl p-2 text-teal-200 font-bold rounded-full border-4 border-blue-600 bg-gradient-to-tr from-red-500 to-red-700 w-16 h-16">
                            {bolita}
                          </div>
                        </div>
                      </div>
                    ) : bolita > 15 && bolita < 31 ? (
                      <div>
                        <div className="p-4 pt-2">
                          <span className="ml-[20px] mt-[-6px] absolute rounded-lg border-1 pl-2 pr-2 bg-amber-300 text-sm font-mono font-semibold">
                            I
                          </span>
                          <div className=" text-center pt-[10px] text-3xl p-2 text-teal-200 font-bold rounded-full border-4 border-yellow-400 bg-gradient-to-tr from-red-500 to-red-700 w-16 h-16">
                            {bolita}
                          </div>
                        </div>
                      </div>
                    ) : bolita > 30 && bolita < 46 ? (
                      <div>
                        <div className="p-4 pt-2">
                          <span className="ml-[20px] mt-[-6px] absolute rounded-lg border-1 pl-2 pr-2 bg-orange-500 text-sm font-mono font-semibold">
                            N
                          </span>
                          <div className=" text-center pt-[10px] text-3xl p-2 text-teal-200 font-bold rounded-full border-4 border-orange-500 bg-gradient-to-tr from-red-500 to-red-700 w-16 h-16">
                            {bolita}
                          </div>
                        </div>
                      </div>
                    ) : bolita > 45 && bolita < 61 ? (
                      <div>
                        <div className="p-4 pt-2">
                          <span className="ml-[20px] mt-[-6px] absolute rounded-lg border-1 pl-2 pr-2 bg-green-600 text-sm font-mono font-semibold">
                            G
                          </span>
                          <div className=" text-center pt-[10px] text-3xl p-2 text-teal-200 font-bold rounded-full border-4 border-green-600 bg-gradient-to-tr from-red-500 to-red-700 w-16 h-16">
                            {bolita}
                          </div>
                        </div>
                      </div>
                    ) : bolita > 60 && bolita < 76 ? (
                      <div>
                        <div className="p-4 pt-2">
                          <span className="ml-[20px] mt-[-6px] absolute text-white rounded-lg border-1 pl-2 pr-2 bg-black text-sm font-mono font-semibold">
                            O
                          </span>
                          <div className=" text-center pt-[10px] text-3xl p-2 text-teal-200 font-bold rounded-full border-4 border-black bg-gradient-to-tr from-red-500 to-red-700 w-16 h-16">
                            {bolita}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </CSSTransition>
                </SwitchTransition>

                {sacada.slice(0, 6).map((val, key) => {
                  return (
                    <>
                      {key == 0 ? (
                        <></>
                      ) : sacada[key] > 0 && sacada[key] < 16 ? (
                        <div>
                          <div className="p-1 pt-4">
                            <span className="ml-[12px] mt-[-5px] absolute rounded-lg border-1 pl-2 pr-2 bg-blue-600 text-xs text-white font-mono font-semibold">
                              B
                            </span>
                            <div className=" text-center text-xl p-2 text-teal-200 font-bold rounded-full border-2 border-blue-600  bg-gradient-to-tr from-blue-600 to-blue-950 w-12 h-12">
                              {sacada[key]}
                            </div>
                          </div>
                        </div>
                      ) : sacada[key] > 15 && sacada[key] < 31 ? (
                        <div>
                          <div className="p-1 pt-4">
                            <span className="ml-[12px] mt-[-5px] absolute rounded-lg border-1 pl-2 pr-2 bg-amber-300 text-xs font-mono font-semibold">
                              I
                            </span>
                            <div className=" text-center text-xl p-2 text-teal-200 font-bold rounded-full border-2 border-yellow-400 bg-gradient-to-tr from-blue-600 to-blue-950 w-12 h-12">
                              {sacada[key]}
                            </div>
                          </div>
                        </div>
                      ) : sacada[key] > 30 && sacada[key] < 46 ? (
                        <div>
                          <div className="p-1 pt-4">
                            <span className="ml-[12px] mt-[-5px] absolute rounded-lg border-1 pl-2 pr-2 bg-orange-500 text-xs font-mono font-semibold">
                              N
                            </span>
                            <div className=" text-center text-xl p-2 text-teal-200 font-bold rounded-full border-2 border-orange-500 bg-gradient-to-tr from-blue-600 to-blue-950 w-12 h-12">
                              {sacada[key]}
                            </div>
                          </div>
                        </div>
                      ) : sacada[key] > 45 && sacada[key] < 61 ? (
                        <div>
                          <div className="p-1 pt-4">
                            <span className="ml-[12px] mt-[-5px] absolute rounded-lg border-1 pl-2 pr-2 bg-green-600 text-xs font-mono font-semibold">
                              G
                            </span>
                            <div className=" text-center text-xl p-2 text-teal-200 font-bold rounded-full border-2 border-green-600 bg-gradient-to-tr from-blue-600 to-blue-950 w-12 h-12">
                              {sacada[key]}
                            </div>
                          </div>
                        </div>
                      ) : sacada[key] > 60 && sacada[key] < 76 ? (
                        <div>
                          <div className="p-1 pt-4">
                            <span className="ml-[12px] mt-[-5px] text-white absolute rounded-lg border-1 pl-2 pr-2 bg-black text-xs font-mono font-semibold">
                              O
                            </span>
                            <div className=" text-center text-xl p-2 text-teal-200 font-bold rounded-full border-2 border-black bg-gradient-to-tr from-blue-600 to-blue-950 w-12 h-12">
                              {sacada[key]}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </div>
              <div className="orden">
                {documents.length > 0 ? (<>
                  {Object.keys(documents).map((key, index) => {
                  return (
                    <>
                     {documents[key].estatus !== "disponible" ? (
                        <div key={index} className="p-4">
                      
                            <Micartonlibre
                              mensaje={mensaje}
                              id={index}
                              idcarton={documents[index].id}
                              email={email}
                              numeros={documents[index]}
                              sacada={sacada}
                              figuras={figuras}
                              bolitas={bolitas}
                              iniciando={iniciando}
                              iniciando2={iniciando2}
                              misala={misala}
                            />
                    
                            
                         
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
                </>) : (<></>)}

                
              </div>

          <br />
          <br />
          <br />
          <br />
          <br />
          <div class="h-[20px] relative bg-blue-600 flex flex-row place-content-center">
        <div className=" absolute flex flex-row mt-[-30px]">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/apphost-57318.appspot.com/o/bingo%2Fbingofranja.png?alt=media&token=04376ecb-82a1-4556-a511-b3eebc7b906b"
            alt=""
            height="80px"
            width="80px"
          />
          <div className="mt-[25px] p-1 h-8 font-bold text-blue-800 text-sm bg-blue-200 rounded-lg">
            Información
            
          </div>
         
         
         
          <Space wrap className="mt-[20px] ml-8 flex flex-row" >
            <Popover
              content={<div style={{ padding: 0 }}>{content2}</div>}
              overlayStyle={{ width: '132px' }} 
              trigger="click"
              
            >
              <div className="relative mt-[-26px]">
              <svg class="w-[32px] h-[32px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M5 3a2 2 0 0 0-2 2v5h18V5a2 2 0 0 0-2-2H5ZM3 14v-2h18v2a2 2 0 0 1-2 2h-6v3h2a1 1 0 1 1 0 2H9a1 1 0 1 1 0-2h2v-3H5a2 2 0 0 1-2-2Z" clip-rule="evenodd"/>
</svg>

                <span class=" absolute inline-flex items-center bg-slate-800 justify-center w-6 h-6 ml-[20px] mt-[-43px] text-xs font-semibold text-white  rounded-full">
                {sacada.length}
                </span>
              </div>
            </Popover>
          </Space>

          <Space wrap className="mt-[20px] ml-4 flex flex-row" >
            <Popover
            title="A Solo 1"
              content={<div style={{ padding: 0 }}>{content3}</div>}
              overlayStyle={{ width: '150px' }} 
              trigger="click"
              placement="topRight"
              
            >
              <div className="relative mt-[-26px]">
              <svg class="w-[32px] h-[32px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M12 20a7.966 7.966 0 0 1-5.002-1.756l.002.001v-.683c0-1.794 1.492-3.25 3.333-3.25h3.334c1.84 0 3.333 1.456 3.333 3.25v.683A7.966 7.966 0 0 1 12 20ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10c0 5.5-4.44 9.963-9.932 10h-.138C6.438 21.962 2 17.5 2 12Zm10-5c-1.84 0-3.333 1.455-3.333 3.25S10.159 13.5 12 13.5c1.84 0 3.333-1.455 3.333-3.25S13.841 7 12 7Z" clip-rule="evenodd"/>
</svg>



                <span class=" absolute inline-flex items-center bg-slate-800 justify-center w-6 h-6 ml-[20px] mt-[-43px] text-xs font-semibold text-white  rounded-full">
                {solouno.length}
                </span>
              </div>
            </Popover>
          </Space>
        </div>
      </div>

            </>
          )}
           </>
          )}

          <br />
          <br />
          <br />
        </>
      ) : (
        <></>
      )}

      <div class=" flex">
        {activar == true ? (
          <Confetti
            numberOfPieces={300}
            gravity={0.1}
            width={width}
            height={height}
          />
        ) : (
          <div></div>
        )}
      </div>
      <ToastContainer theme="dark" />
      {/*MODAL*/}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="h-20 bg-blue-800 rounded-t-lg place-content-center flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <img
                    className="mt-4 mb-4"
                    src={trofeow}
                    width="60px"
                    height="60px"
                  />
                  <span className="mt-6 pl-2">GANADORES</span>
                </div>
                <div className="relative p-1 flex-auto">
                  <div className="p-1 md:p-1 text-center">
                    <div>
                      <div className="p-2 md:p-2 text-center">
                        {ganadores.map((val, key) => {
                          return (
                            <>
                              <div className="flex flex-grow place-content-between items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                                <img
                                  class="w-10 h-10 rounded-full"
                                  src={obtenernombre(ganadores[key], "foto")}
                                  alt="Ganador"
                                />
                                <div class="ps-3">
                                  <div class="text-base font-semibold">
                                    {obtenernombre(ganadores[key], "nombre")}
                                  </div>
                                </div>
                                <div className="ml-10 flex flex-col">
                                  <span className=" w-24 text-xs bg-red-800 text-white rounded-t-lg">
                                    Premio
                                  </span>
                                  <span className=" w-24 font-bold bg-cyan-700 p-1 rounded-b-lg text-white">
                                    {premio} Bs
                                  </span>
                                </div>
                              </div>
                              <hr className="mt-2 p-2" />
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-18 bg-blue-800 rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  {cierre == true ? (
                    <>
                      <button
                        type="button"
                        onClick={() => {
                          setShowModal(false);
                        }}
                        data-modal-hide="popup-modal"
                        class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                      >
                        <svg
                          class="w-6 h-6 text-gray-800 "
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        &nbsp;Cerrar
                      </button>
                    </>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
  
  {showModal2 ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-2 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="flex flex-row place-content-between">
                <h3 className=" text-lg ml-4 mt-4 text-gray-500 font-bold">
                          Compra de Cartón
                        </h3>
                
                      <svg onClick={() => {
                        setShowModal2(false);
                      }} class="w-[32px] h-[32px] text-gray-800 mt-2 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z" clip-rule="evenodd"/>
</svg>


                   </div>
                <div className="relative p-2 flex-auto">
                  <div className=" text-center">
                    <div>
                      <div className=" text-center">
                       
                        
                      </div>
                      
            
                      <div className="flex">
                        {carton == "" ? (
                          <div></div>
                        ) : (
                          <>
                            <Mostrarcarton
                              email={email}
                              numeros={carton}
                            />
                            <div className="flex flex-col">
                              <div className="p-4 text-blue-900 font-bold text-xl">
                                Carton #{numcarton}
                              </div>
                              <div className="pl-4 flex flex-col">
                                <button
                                  onClick={() => {
                                    comprarcarton(numcarton, email);
                                  }}
                                  id="botoncomprar"
                                  type="button"
                                  class="text-white flex flex-row bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                                >
                                  <svg class="w-[24px] h-[24px] mt-[-4px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
</svg>

 <span>Comprar</span>
                                </button>
                                <button
                                  onClick={() => {
                                    regalarcarton(numcarton, email);
                                  }}
                                  id="botonregalar"
                                  type="button"
                                  class="text-white flex flex-row bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                                >
                                  <svg class="w-[24px] h-[24px] mt-[-4px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path d="M20 7h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C10.4 2.842 8.949 2 7.5 2A3.5 3.5 0 0 0 4 5.5c.003.52.123 1.033.351 1.5H4a2 2 0 0 0-2 2v2a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V9a2 2 0 0 0-2-2Zm-9.942 0H7.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM13 14h-2v8h2v-8Zm-4 0H4v6a2 2 0 0 0 2 2h3v-8Zm6 0v8h3a2 2 0 0 0 2-2v-6h-5Z"/>
</svg>

                                  Regalo
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                  
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Librenew;
