import React, { useState, useEffect } from "react";
import "../App.css";
import { useAuth } from "../context/AuthContext";
import logo from "../img/logo.png";
import imgcarton from "../img/tabla.png";
import useSound from "use-sound";
import { Button, Drawer, Radio, Space } from "antd";
import { Select } from "antd";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Swal from 'sweetalert2'


//IMPORTAR SONIDOS
import popoff from "../sound/pop-up-off.mp3";
import popon from "../sound/pop-up-on.mp3";
import like from "../sound/rising-pops.mp3";
import bite from "../sound/bite.mp3";
import menuopen from "../sound/menu-open.mp3";
import bienvenido from "../sound/clases/bienvenido.mp3";

import { db } from "../firebaseConfig/db";
import { collection } from "firebase/firestore";
import {
  useCollectionData,
  useCollection,
  useFirestore,
  useUpdateDoc,
} from "react-firebase-hooks/firestore";
import {
  doc,
  setDoc,
  addDoc,
  updateDoc,
  getDocs,
  deleteDoc,
} from "@firebase/firestore";

import Confetti from "react-confetti";
import Carton from "../componentes/carton";
import Mostrarcarton from "../componentes/mostrarcarton";
var todoslosc = [];

const Adminis = () => {
  const { width, height } = "100%";
  const [showModal, setShowModal] = useState(false)
  const [showModal2, setShowModal2] = useState(false)
  const [showModal3, setShowModal3] = useState(false)
  const [idpago, setIdpago] = useState(0);
  const [idretiro, setIdretiro] = useState(0);
  const [pagos, setPagos] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [retiros, setRetiros] = useState([]);
  const [idusuario, setIdUsuario] = useState(0);

  const [emailrecarga, setEmailrecarga] = useState("");
  const [referenciarecarga, setReferenciarecarga] = useState(0);
  const [montorecarga, setMontorecarga] = useState(0);

  

  const [email2, setEmail2] = useState("");

  const nuevoscartonessala7 = () => {

    Swal.fire({
      title: "Desea Reiniciar Cartones?",
      text: "Sala Pote Millonario",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar Cartones"
    }).then((result) => {
      if (result.isConfirmed) {
        eliminarcartones(7)
        Swal.fire({
          title: "Cartones Eliminados",
          text: "Hemos Reiniciado con Cartones Nuevos",
          icon: "success"
        });

      }
    });

  }

  const obtenerpagos = async () => {

  
    
    const emailu = email2

    try {

      const response = await fetch(`${process.env.REACT_APP_BASE}/obtenerpagos/`, {
        method: 'POST', // Especifica el método POST
        headers: {
            'Content-Type': 'application/json', // Indica que el cuerpo de la solicitud es JSON
        },
        body: JSON.stringify({ emailu }) // Convierte el objeto a una cadena JSON
    });

        if (!response.ok) {
            throw new Error('Error en la red');
        }
        const data = await response.json();
        if(data){
          if(data.message == "no hay"){
            setPagos([])
          }else
          {
            console.log(data)
            if(data.message == "No Existe"){

            }else{
              if(data.pagos){
                setPagos(data.pagos)
              }
              
            }
           
          }
        //console.log(data);
        }else{
          //console.log("ERROR");
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    //console.log("SE EJECUTO")
    // if(activaintervalo){
    //   setActivaintervalo(false)
    // }else{
    //   setActivaintervalo(true)
    // }
};

const obtenerretiros = async () => {

  
    
  const emailu = email2

  try {

    const response = await fetch(`${process.env.REACT_APP_BASE}/obtenerretiros/`, {
      method: 'POST', // Especifica el método POST
      headers: {
          'Content-Type': 'application/json', // Indica que el cuerpo de la solicitud es JSON
      },
      body: JSON.stringify({ emailu }) // Convierte el objeto a una cadena JSON
  });

      if (!response.ok) {
          throw new Error('Error en la red');
      }
      const data = await response.json();
      if(data){
        if(data.message == "no hay"){
          setRetiros([])
        }else
        {
          console.log(data)
          if(data.message == "No Existe"){

          }else{
            if(data.retiros){
              setRetiros(data.retiros)
            }
            
          }
         
        }
      //console.log(data);
      }else{
        //console.log("ERROR");
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
  //console.log("SE EJECUTO")
  // if(activaintervalo){
  //   setActivaintervalo(false)
  // }else{
  //   setActivaintervalo(true)
  // }
};

const obtenerusuarios = async () => {

  
    
  const emailu = email2

  try {

    const response = await fetch(`${process.env.REACT_APP_BASE}/obtenerusuarios/`, {
      method: 'POST', // Especifica el método POST
      headers: {
          'Content-Type': 'application/json', // Indica que el cuerpo de la solicitud es JSON
      },
      body: JSON.stringify({ emailu }) // Convierte el objeto a una cadena JSON
  });

      if (!response.ok) {
          throw new Error('Error en la red');
      }
      const data = await response.json();
      if(data){
        if(data.message == "no hay"){
          setUsuarios([])
        }else
        {
          if(data.usuarios){
            setUsuarios(data.usuarios)
          }
       
        }
      //console.log(data);
      }else{
        //console.log("ERROR");
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
  //console.log("SE EJECUTO")
  // if(activaintervalo){
  //   setActivaintervalo(false)
  // }else{
  //   setActivaintervalo(true)
  // }
};


const eliminarcartones = async (sala) => {

  const fecha = document.getElementById("fechainicio").value
  const hora = document.getElementById("horainicio").value


  // Expresión regular para validar la fecha en formato YYYY-MM-DD
const fechaRegex = /^\d{4}-\d{2}-\d{2}$/;
// Expresión regular para validar la hora en formato HH:MM:SS
const horaRegex = /^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/;


  const esFechaValida = fechaRegex.test(fecha);
  const esHoraValida = horaRegex.test(hora);

  if (!esFechaValida) {
    console.log("La fecha no tiene un formato válido (YYYY-MM-DD).");
    return
  }

  if (!esHoraValida) {
    console.log("La hora no tiene un formato válido (HH:MM:SS).");
    return
  }

  

  const emailu = email2

  try {

    const response = await fetch(`${process.env.REACT_APP_BASE}/nuevoscartones${sala}/`, {
      method: 'POST', // Especifica el método POST
      headers: {
          'Content-Type': 'application/json', // Indica que el cuerpo de la solicitud es JSON
      },
      body: JSON.stringify({ emailu, fecha, hora }) // Convierte el objeto a una cadena JSON
  });

      if (!response.ok) {
          throw new Error('Error en la red');
      }
      const data = await response.json();
      if(data){
        if(data.message == "cartones nuevos"){
          
        }
      //console.log(data);
      }else{
        //console.log("ERROR");
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
  //console.log("SE EJECUTO")
  // if(activaintervalo){
  //   setActivaintervalo(false)
  // }else{
  //   setActivaintervalo(true)
  // }
};

const confirmarpago = async (idpago, emailpago, montopago) => {


 const emailu = email2
  
    


  try {

    const response = await fetch(`${process.env.REACT_APP_BASE}/confirmarpago/`, {
      method: 'POST', // Especifica el método POST
      headers: {
          'Content-Type': 'application/json', // Indica que el cuerpo de la solicitud es JSON
      },
      body: JSON.stringify({idpago, emailpago, montopago, emailu}) // Convierte el objeto a una cadena JSON
  });

      if (!response.ok) {
          throw new Error('Error en la red');
      }
      const data = await response.json();
      if(data){
        if(data.message == "modificado"){
          setShowModal(false)
          msjsave("SALDO AGREGADO", "save")
          obtenerpagos()
          obtenerusuarios()
        }else{
          msjsave("ERROR DE CONEXION", "error")
        }
      //console.log(data);
      }
      
  } catch (error) {
      console.error('Error fetching data:', error);
  }
  //console.log("SE EJECUTO")
  // if(activaintervalo){
  //   setActivaintervalo(false)
  // }else{
  //   setActivaintervalo(true)
  // }
};

const cancelarretiro = async (idretiro, emailretiro, montoretiro) => {


  const emailu = email2
   
     
 
 
   try {
 
     const response = await fetch(`${process.env.REACT_APP_BASE}/cancelarretiro/`, {
       method: 'POST', // Especifica el método POST
       headers: {
           'Content-Type': 'application/json', // Indica que el cuerpo de la solicitud es JSON
       },
       body: JSON.stringify({idretiro, emailretiro, montoretiro, emailu}) // Convierte el objeto a una cadena JSON
   });
 
       if (!response.ok) {
           throw new Error('Error en la red');
       }
       const data = await response.json();
       if(data){
         if(data.message == "modificado"){
           setShowModal2(false)
           msjsave("SALDO DEVUELTO, POR CANCELACION", "save")
           obtenerretiros()
           obtenerusuarios()
         }else{
           msjsave("ERROR DE CONEXION", "error")
         }
       //console.log(data);
       }
       
   } catch (error) {
       console.error('Error fetching data:', error);
   }
   //console.log("SE EJECUTO")
   // if(activaintervalo){
   //   setActivaintervalo(false)
   // }else{
   //   setActivaintervalo(true)
   // }
 };

 const confirmarretiro = async (idretiro) => {


  const emailu = email2
   
     
 
 
   try {
 
     const response = await fetch(`${process.env.REACT_APP_BASE}/confirmarretiro/`, {
       method: 'POST', // Especifica el método POST
       headers: {
           'Content-Type': 'application/json', // Indica que el cuerpo de la solicitud es JSON
       },
       body: JSON.stringify({idretiro, emailu}) // Convierte el objeto a una cadena JSON
   });
 
       if (!response.ok) {
           throw new Error('Error en la red');
       }
       const data = await response.json();
       if(data){
         if(data.message == "modificado"){
           setShowModal2(false)
           msjsave("RETIRO PAGADO", "save")
           obtenerretiros()
           obtenerusuarios()
         }else{
           msjsave("ERROR DE CONEXION", "error")
         }
       //console.log(data);
       }
       
   } catch (error) {
       console.error('Error fetching data:', error);
   }
   //console.log("SE EJECUTO")
   // if(activaintervalo){
   //   setActivaintervalo(false)
   // }else{
   //   setActivaintervalo(true)
   // }
 };

const cancelarpago = async (idpago) => {


  const emailu = email2
   
     
 
 
   try {
 
     const response = await fetch(`${process.env.REACT_APP_BASE}/cancelarpago/`, {
       method: 'POST', // Especifica el método POST
       headers: {
           'Content-Type': 'application/json', // Indica que el cuerpo de la solicitud es JSON
       },
       body: JSON.stringify({idpago, emailu}) // Convierte el objeto a una cadena JSON
   });
 
       if (!response.ok) {
           throw new Error('Error en la red');
       }
       const data = await response.json();
       if(data){
         if(data.message == "modificado"){
           setShowModal(false)
           msjsave("PAGO CANCELADO", "save")
           obtenerpagos()
           
         }else{
           msjsave("ERROR DE CONEXION", "error")
         }
       //console.log(data);
       }
       
   } catch (error) {
       console.error('Error fetching data:', error);
   }
   //console.log("SE EJECUTO")
   // if(activaintervalo){
   //   setActivaintervalo(false)
   // }else{
   //   setActivaintervalo(true)
   // }
 };



const obtenerdatos = (id, tipo) => {

  for (let i = 0; i < usuarios.length; i++) {
    if (usuarios[i].email == id) {
      if(tipo == "nombre"){
        return usuarios[i].nombre
      }
      if(tipo == "foto"){
        return usuarios[i].photo
      }
    }
  }
}

const obtenerdatospago = (id, tipo) => {

  for (let i = 0; i < pagos.length; i++) {
    if (pagos[i].ID == id) {
      if(tipo == "referencia"){
        return pagos[i].referencia
      }
      if(tipo == "monto"){
        return pagos[i].monto
      }
      if(tipo == "fecha"){
        return pagos[i].fecha
      }
      if(tipo == "email"){
        return pagos[i].email
      }
    }
  }
}

const obtenerdatosretiro = (id, tipo) => {

  for (let i = 0; i < retiros.length; i++) {
    if (retiros[i].ID == id) {
      
      if(tipo == "monto"){
        return retiros[i].monto
      }
      if(tipo == "fecha"){
        return retiros[i].fecha
      }
      if(tipo == "email"){
        return retiros[i].email
      }
    }
  }
}

  const msjsave = (mensajesave, tipodemensaje) => {
    if (tipodemensaje == "save") {
      toast.success(mensajesave, {});
    }
    if (tipodemensaje == "error") {
      toast.error(mensajesave, {});
    }
    if (tipodemensaje == "warning") {
      toast.warning(mensajesave, {});
    }
    if (tipodemensaje == "info") {
      toast.info(mensajesave, {});
    }
  };

  const mensaje = (value, tipo) => {
    msjsave(value, tipo);
  };

  function formatearFecha(fechaISO) {
    // Crear un objeto Date a partir de la fecha ISO
    const fecha = new Date(fechaISO);
    
    // Obtener el día y el mes
    const dia = fecha.getUTCDate();
    const opcionesMes = { month: 'short' }; // 'short' para obtener el mes abreviado
    const mes = new Intl.DateTimeFormat('es-ES', opcionesMes).format(fecha);
    
    // Obtener la hora en formato 24 horas
    const horas = String(fecha.getUTCHours()).padStart(2, '0');
    const minutos = String(fecha.getUTCMinutes()).padStart(2, '0');
    
    // Formatear la salida
    return `${dia} ${mes} ${horas}:${minutos}`;
}


const recargamanual = async () => {


  const emailu = email2
   
     
    const emailuser = document.getElementById("emailrecarga").value
    const referenciauser = document.getElementById("referenciarecarga").value
    const montouser = document.getElementById("montorecarga").value

    console.log(emailuser)
    console.log(referenciauser)
    console.log(montouser)
    
 
   try {
 
     const response = await fetch(`${process.env.REACT_APP_BASE}/recargamanual/`, {
       method: 'POST', // Especifica el método POST
       headers: {
           'Content-Type': 'application/json', // Indica que el cuerpo de la solicitud es JSON
       },
       body: JSON.stringify({emailuser, referenciauser, montouser, emailu}) // Convierte el objeto a una cadena JSON
   });
 
       if (!response.ok) {
           throw new Error('Error en la red');
       }
       const data = await response.json();
       if(data){
         if(data.message == "guardado"){
           setShowModal3(false)
           msjsave("SALDO AGREGADO MANUALMENTE", "save")
           obtenerpagos()
           obtenerusuarios()
         }
         if(data.message == "duplicado"){
          msjsave("EL NUMERO DE REFERENCIA YA ESTA USADO", "error")
         }
         if(data.message == "No Existe"){
          msjsave("ERROR DE CONEXION", "error")
         }

       //console.log(data);
       }
       
   } catch (error) {
       console.error('Error fetching data:', error);
   }
   //console.log("SE EJECUTO")
   // if(activaintervalo){
   //   setActivaintervalo(false)
   // }else{
   //   setActivaintervalo(true)
   // }
 };

 const regalomanual = async (emailuser) => {


  const emailu = email2
   
     




 
   try {
 
     const response = await fetch(`${process.env.REACT_APP_BASE}/regalomanual/`, {
       method: 'POST', // Especifica el método POST
       headers: {
           'Content-Type': 'application/json', // Indica que el cuerpo de la solicitud es JSON
       },
       body: JSON.stringify({emailuser, emailu}) // Convierte el objeto a una cadena JSON
   });
 
       if (!response.ok) {
           throw new Error('Error en la red');
       }
       const data = await response.json();
       if(data){
         if(data.message == "guardado"){
    
          msjsave("REGALO OTORGADO A \n" + obtenerdatos(emailuser, "nombre"), "save");
       
           obtenerusuarios()
         }
      
         if(data.message == "No Existe"){
          msjsave("ERROR DE CONEXION", "error")
         }

       //console.log(data);
       }
       
   } catch (error) {
       console.error('Error fetching data:', error);
   }
   //console.log("SE EJECUTO")
   // if(activaintervalo){
   //   setActivaintervalo(false)
   // }else{
   //   setActivaintervalo(true)
   // }
 };


  // GENERAR CARTONES

  //

  const auth = useAuth();
  const { displayName } = auth.user;
  const { email } = auth.user;
  
  var primerNombre = "";
  if (displayName == undefined) {
  } else {
    let nombre = displayName;

    for (let i = 0; i < nombre.length; i++) {
      if (nombre[i] === " ") {
        i = nombre.length;
      } else {
        primerNombre += nombre[i];
      }
    }
  }
  const photoURL = auth.user.photoURL;
  //console.log(auth.user.photoURL);

  const handleGoogle = (e) => {
    e.preventDefault();
    auth.loginWithGoogle();
  };
  const handleLogout = () => {
    auth.logout();
  };


  useEffect(() => {
    console.log(email2)
    if(email2 !== undefined){
      obtenerpagos()
      obtenerusuarios()
      obtenerretiros()
    }
    
    
  }, [email2]);

  useEffect(() => {
    setEmail2(email)
  }, [email]);

  useEffect(() => {console.log(pagos)}, [pagos]);
  useEffect(() => {console.log(usuarios)}, [usuarios]);


  return (
    <>
      <div className="container w-screen">
        <div className=" p-2 h-28 w-full flex place-content-between items-center">
          <div className="m-0 flex flex-wrap flex-col place-content-around">
            <Link to="/">
              <img
                className="pt-2 mt-[-10px]"
                src={logo}
                width="300px"
                height="100%"
                alt="logo"
              />
            </Link>
            <p className="p-1"></p>
          </div>

          {displayName == undefined ? (
            <button
              type="button"
              onClick={(e) => handleGoogle(e)}
              class="text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 me-2 mb-2"
            >
              <svg
                class="w-4 h-4 me-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 19"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z"
                  clip-rule="evenodd"
                />
              </svg>
              Entrar
            </button>
          ) : (
            <div className="flex">
              <div className="p-2">
                <div className="flex flex-col items-end gap-4 ">
                  <img
                    onClick={handleLogout}
                    className="w-10 h-10  rounded-full border-2 border-blue-500"
                    src={`${photoURL}`}
                    alt=""
                  />
                  <div className="font-medium text-white mt-[-10px]">
                    <div className="text-white text-right text-sm">
                      {displayName && <> {primerNombre} </>}
                    </div>
                  </div>
                </div>
              </div>

              <div></div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer theme="dark" />
      <br />

      {email == "enmarycarrasco@gmail.com" ? (
        <>
   
   <div className="p-4 flex flex-col">
   <div className="mb-2 font-bold text-gray-700 text-xl">Reiniciar Modalidad Trebol</div>
        
   <div>
            <label for="fechainicio" class="block mb-2 text-sm font-medium text-gray-900">Fecha Inicio</label>
            <input type="text" id="fechainicio" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="2025-##-##" required />
        </div>

        <div>
            <label for="horainicio" class="block mb-2 text-sm font-medium text-gray-900">Hora Inicio</label>
            <input type="text" id="horainicio" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="00:00:00" required />
        </div>


        
        <button  onClick={() => {
        nuevoscartonessala7();
        }} type="button" class="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">CARTONES SECTION 2</button>
        
</div>
        <div className="p-4">
          <div className="mb-2 font-bold text-gray-700 text-xl">Solicitudes de Pagos</div>
          
        <table class="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
            
                <th scope="col" class="px-6 py-3">
                    Nombre
                </th>
                <th scope="col" class="px-6 py-3">
                    Fecha
                </th>
                <th scope="col" class="px-6 py-3">
                    Referencia
                </th>
                <th scope="col" class="px-6 py-3">
                    Monto
                </th>
                <th scope="col" class="px-6 py-3">
                    
                </th>
            </tr>
        </thead>
        <tbody>
          {pagos.length == 0 ? (<></>) : (<>{pagos.map((val, key) => {
          return (<tr class="bg-white border-b hover:bg-gray-50 ">
                
            <th scope="row" class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                <img class="w-10 h-10 rounded-full" src={obtenerdatos(val.email, "foto")} alt="Jese image" />
                <div class="ps-3">
                    <div class="text-base font-semibold text-gray-500">{obtenerdatos(val.email, "nombre")}</div>
                    <div class="font-normal text-gray-500">{val.email}</div>
                </div>  
            </th>
            <td class="px-6 py-4">
                {formatearFecha(val.fecha)}
            </td>
            <td class="px-6 py-4">
                <div class="flex items-center">
                    #{val.referencia}
                </div>
            </td>
            <td class="px-6 py-4">
                {val.monto} Bs
            </td>
            <td class="px-6 py-4">
            <button type="button" onClick={() => {
              setIdpago(val.ID)
            setShowModal(true);
            }} class="text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#F7BE38]/50 me-2 mb-2">
            <svg class="w-6 h-6 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
<path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
</svg>

Aceptar
</button>
            </td>
        </tr>);
          })}</>)}
          
            
         
           
        </tbody>
    </table>
        </div>
   
          <br />

          <div className="p-4">
          <div className="mb-2 font-bold text-gray-700 text-xl">Solicitudes de Retiros</div>
          
        <table class="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
            
                <th scope="col" class="px-6 py-3">
                    Nombre
                </th>
                <th scope="col" class="px-6 py-3">
                    Fecha
                </th>
             
                <th scope="col" class="px-6 py-3">
                    Monto
                </th>
                <th scope="col" class="px-6 py-3">
                    
                </th>
            </tr>
        </thead>
        <tbody>
          {retiros.length == 0 ? (<></>) : (<>{retiros.map((val, key) => {
          return (<tr class="bg-white border-b hover:bg-gray-50 ">
                
            <th scope="row" class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                <img class="w-10 h-10 rounded-full" src={obtenerdatos(val.email, "foto")} alt="Jese image" />
                <div class="ps-3">
                    <div class="text-base font-semibold text-gray-500">{obtenerdatos(val.email, "nombre")}</div>
                    <div class="font-normal text-gray-500">{val.email}</div>
                </div>  
            </th>
            <td class="px-6 py-4">
                {formatearFecha(val.fecha)}
            </td>
         
            <td class="px-6 py-4">
                {val.monto} Bs
            </td>
            <td class="px-6 py-4">
            <button type="button" onClick={() => {
              setIdretiro(val.ID)
            setShowModal2(true);
            }} class="text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#F7BE38]/50 me-2 mb-2">
            <svg class="w-6 h-6 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
<path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
</svg>

Aceptar
</button>
            </td>
        </tr>);
          })}</>)}
          
            
         
           
        </tbody>
    </table>
        </div>

        <div className="p-4">
          <div className="mb-2 font-bold text-gray-700 text-xl"> Usuarios</div>
          
        <table class="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
            
                <th scope="col" class="px-6 py-3">
                    Nombre
                </th>
                <th scope="col" class="px-6 py-3">
                    Saldo
                </th>
             
                <th scope="col" class="px-6 py-3">
                    Regalos
                </th>
                <th scope="col" class="px-6 py-3">
                    
                </th>
            </tr>
        </thead>
        <tbody>
          {usuarios.length == 0 ? (<></>) : (<>{usuarios.map((val, key) => {
          return (<tr class="bg-white border-b hover:bg-gray-50 ">
                
            <th scope="row" class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                <img class="w-10 h-10 rounded-full" src={val.photo} alt="Jese image" />
                <div class="ps-3">
                    <div class="text-base font-semibold text-gray-500">{val.nombre}</div>
                    <div class="font-normal text-gray-500">{val.email}</div>
                </div>  
            </th>
            <td class="px-6 py-4">
                {val.saldo}
            </td>
         
            <td class="px-6 py-4">
                {val.regalos}
            </td>
            <td class="px-6 py-4">
            <button type="button" onClick={() => {
              setEmailrecarga(val.email)
            setShowModal3(true);
            }} class="text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#F7BE38]/50 me-2 mb-2">
            <svg class="w-6 h-6 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
<path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
</svg>

Recargar
</button>
<button
                                  onClick={() => {
                                    
                                  regalomanual(val.email);
                                  }}
                                  id="configuramovil"
                                  type="button"
                                  class="text-white flex flex-row bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                                >
                                  <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path d="M20 7h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C10.4 2.842 8.949 2 7.5 2A3.5 3.5 0 0 0 4 5.5c.003.52.123 1.033.351 1.5H4a2 2 0 0 0-2 2v2a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V9a2 2 0 0 0-2-2Zm-9.942 0H7.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM13 14h-2v8h2v-8Zm-4 0H4v6a2 2 0 0 0 2 2h3v-8Zm6 0v8h3a2 2 0 0 0 2-2v-6h-5Z"/>
</svg>

                                </button>
            </td>
        </tr>);
          })}</>)}
          
            
         
           
        </tbody>
    </table>
        </div>
   
          <br />

    
        </>
      ) : (
        <div></div>
      )}

      {/*MODAL*/}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="p-4 md:p-5 text-center">
                    <div>
                      <div className="p-2 md:p-2 text-center">
                        <h3 className=" text-lg font-normal text-gray-500 dark:text-gray-400">
                          Confirmar Pago
                        </h3>
                      </div>
                      <div class="flex flex-col p-4 items-start bg-slate-300 border-2 border-gray-600 rounded-lg gap-2.5">
                      <div className="text-gray-700"><span className="font-medium">Fecha:</span> <span>{formatearFecha(obtenerdatospago(idpago, "fecha"))}</span></div>
                      <div className="text-gray-700"><span className="font-medium">Referencia:</span> <span>{obtenerdatospago(idpago, "referencia")}</span></div>
                      <div className="text-gray-700"><span className="font-medium">Monto:</span> <span>{obtenerdatospago(idpago, "monto")} Bs</span></div>

                      </div>

                      
                    </div>

                    <hr className="mt-2 p-2" />
                    <button
                      onClick={() => {
                        confirmarpago(idpago, obtenerdatospago(idpago, "email"), obtenerdatospago(idpago, "monto"))
                      }}
                      data-modal-hide="popup-modal"
                      type="button"
                      className="py-2.5 px-5 ms-3 text-sm font-bold text-white focus:outline-none bg-green-500 rounded-lg border border-gray-200 hover:bg-green-400  focus:z-10 focus:ring-4 "
                    >
                      Confirmar
                    </button>
                    <button
                      onClick={() => {
                        cancelarpago(idpago)
                      }}
                      data-modal-hide="popup-modal"
                      type="button"
                      className="py-2.5 px-5 ms-3 text-sm font-bold text-white focus:outline-none bg-red-500 rounded-lg border border-gray-200 hover:bg-red-400  focus:z-10 focus:ring-4 "
                    >
                      Cancelar
                    </button>
                    <button
                      onClick={() => {
                        setShowModal(false);
                      }}
                      data-modal-hide="popup-modal"
                      type="button"
                      className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

{showModal2 ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="p-4 md:p-5 text-center">
                    <div>
                      <div className="p-2 md:p-2 text-center">
                        <h3 className=" text-lg font-normal text-gray-500 dark:text-gray-400">
                          Confirmar Retiro
                        </h3>
                      </div>
                      <div class="flex flex-col p-4 items-start bg-slate-300 border-2 border-gray-600 rounded-lg gap-2.5">
                      <div className="text-gray-700"><span className="font-medium">Fecha:</span> <span>{formatearFecha(obtenerdatosretiro(idretiro, "fecha"))}</span></div>
                      <div className="text-gray-700"><span className="font-medium">Monto:</span> <span>{obtenerdatosretiro(idretiro, "monto")} Bs</span></div>

                      </div>

                      
                    </div>

                    <hr className="mt-2 p-2" />
                    <button
                      onClick={() => {
                        confirmarretiro(idretiro)
                      }}
                      data-modal-hide="popup-modal"
                      type="button"
                      className="py-2.5 px-5 ms-3 text-sm font-bold text-white focus:outline-none bg-green-500 rounded-lg border border-gray-200 hover:bg-green-400  focus:z-10 focus:ring-4 "
                    >
                      Confirmar
                    </button>
                    <button
                      onClick={() => {
                        cancelarretiro(idretiro, obtenerdatosretiro(idretiro, "email"), obtenerdatosretiro(idretiro, "monto"))
                      }}
                      data-modal-hide="popup-modal"
                      type="button"
                      className="py-2.5 px-5 ms-3 text-sm font-bold text-white focus:outline-none bg-red-500 rounded-lg border border-gray-200 hover:bg-red-400  focus:z-10 focus:ring-4 "
                    >
                      Cancelar
                    </button>
                    <button
                      onClick={() => {
                        setShowModal(false);
                      }}
                      data-modal-hide="popup-modal"
                      type="button"
                      className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

{showModal3 ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-2 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-yellow-300">
                {/*body*/}
                <div className="flex flex-row place-content-between">
                <h3 className=" text-lg ml-4 mt-4 text-gray-500 font-bold">
                          Recarga Manual
                        </h3>
                
                      <svg onClick={() => {
                        setShowModal3(false);
                      }} class="w-[32px] h-[32px] text-gray-800 mt-2 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z" clip-rule="evenodd"/>
</svg>


                   </div>
                <div className="relative p-2 flex-auto">
                  <div className=" text-center">
                    <div>
                      <div className=" text-center">
                       
                        
                      </div>
                      
            
                      <div className="flex flex-col m-2">
 
                      <label for="emailrecarga" class="flex flex-row mb-2 text-sm font-medium text-gray-800"> Banco

</label>
<div class="relative mb-2">
  <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
  <svg class="w-6 h-6 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M10.915 2.345a2 2 0 0 1 2.17 0l7 4.52A2 2 0 0 1 21 8.544V9.5a1.5 1.5 0 0 1-1.5 1.5H19v6h1a1 1 0 1 1 0 2H4a1 1 0 1 1 0-2h1v-6h-.5A1.5 1.5 0 0 1 3 9.5v-.955a2 2 0 0 1 .915-1.68l7-4.52ZM17 17v-6h-2v6h2Zm-6-6h2v6h-2v-6Zm-2 6v-6H7v6h2Z" clip-rule="evenodd"/>
  <path d="M2 21a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Z"/>
</svg>



  </div>
  <input type="text" id="emailrecarga"  defaultValue={emailrecarga} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5" />
</div>


<label for="referenciarecarga" class="flex flex-row mb-2 text-sm font-medium text-gray-800"> Referencia

</label>
<div class="relative mb-2">
  <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
  <svg class="w-6 h-6 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 9h3m-3 3h3m-3 3h3m-6 1c-.306-.613-.933-1-1.618-1H7.618c-.685 0-1.312.387-1.618 1M4 5h16a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Zm7 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"/>
</svg>



  </div>
  <input type="number" id="referenciarecarga" defaultValue={referenciarecarga} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5" />
</div>

<label for="montorecarga" class="flex flex-row mb-2 text-sm font-medium text-gray-800"> Monto

</label>
<div class="relative mb-2">
  <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
  <svg class="w-6 h-6 text-gray-800 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M5 4a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V4Zm12 12V5H7v11h10Zm-5 1a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z" clip-rule="evenodd"/>
</svg>



  </div>
  <input type="number" id="montorecarga"  defaultValue={montorecarga} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5" />
</div>


                            <div className="flex flex-row place-content-end mr-[-10px]">
                                <button
                                  onClick={() => {
                                  recargamanual();
                                  }}
                                  id="configuramovil"
                                  type="button"
                                  class="text-white flex flex-row bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                                >
                                  <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
</svg>

 <span>Recargar</span>
                                </button>
                              
                              </div>
                      </div>
                    </div>

                  
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Adminis;
