import React, {useEffect} from 'react'

const Listado = () => {
    const [cartones, setCartones] = React.useState([]);

    const buscarcartones = async () => {
        //console.log("BUSCANDO CARTONES")
          try {
            const response = await fetch(`${process.env.REACT_APP_BASE}/cartonessala8/`);
            if (!response.ok) {
                throw new Error('Error en la red');
            }
            const data = await response.json();
            if(data){
              setCartones(data);
            console.log(data);
            }else{
              //console.log("ERROR");
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        
          
        
        }

        useEffect(() => {
            buscarcartones();
        }, []);

  return (
    <>
    <div className='text-center font-bold'>Listado de Cartones - Juego de 4 Rondas</div>
    <div className='flex flex-row flex-wrap'>
        {cartones.length > 0 ? (<>{cartones.map((val, key) => {
       return (<>
       {val.estatus === 'comprado' ? (<div className='text-sm bg-[#00FF00] border-2 border-black w-8 y-8 text-center font-semibold'>{val.id}</div>) : (<></>)}
       {val.estatus === 'disponible' ? (<div className='text-sm bg-[#87CEEB] border-2 border-black w-8 y-8 text-center font-semibold'>{val.id}</div>) : (<></>)}
       {val.estatus === 'regalo' ? (<div className='text-sm bg-[#e79e9e] border-2 border-black w-8 y-8 text-center font-semibold'>{val.id}</div>) : (<></>)}
       </>);
       })}</>) : (<></>)}
       
      
      </div>
    </>
  )
}

export default Listado
