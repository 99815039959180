import FormsFirebase from "./page/formFirebase";
import Adminis from "./page/adminis";
import { AuthProvider } from "./context/AuthContext";
import { HashRouter, Route, Routes, Link } from "react-router-dom";
import Home from "./page/home";
import Maracaibo from "./page/maracaibo";
import Admingame from "./page/admingame";
import Caracas from "./page/caracas";
import Merida from "./page/merida";
import Saltoangel from "./page/saltoangel";
import Cartonesfijos from "./page/cartonesfijos";
import Asignarcarton from "./page/asignarcarton";
import Juego from "./page/juego";
import Lista from "./page/lista";
import Libre from "./page/libre";
import Adminlibre from "./page/adminlibre";
import Register from "./page/register";
import Librenew from "./page/librenew";
import Recarga from "./page/recarga";
import Retiros from "./page/retiros";
import Animalitos from "./page/animalitos";
import Whatsa from "./page/whatsa";
import Juegopote from "./page/juegopote";
import Sala4 from "./page/sala4";
import Sala1 from "./page/sala1";
import Sala2 from "./page/sala2";
import Sala3 from "./page/sala3";
import Adminuser from "./page/adminuser";
import Home2 from "./page/home2";
import Home1 from "./page/home1";
import Listado from "./page/listado";
import Lineal from "./page/lineal";

//const socket = io("/");

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <HashRouter>
          <Routes>
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/cartones" element={<FormsFirebase />} />
            <Route path="/cartonesfijos" element={<Cartonesfijos />} />
            <Route path="/asignarcarton" element={<Asignarcarton />} />
            <Route path="/lista" element={<Lista />} />
            <Route path="/adminis" element={<Adminis />} />
            {/* <Route path="/admingame" element={<Admingame />} />
            <Route path="/adminlibre" element={<Adminlibre />} /> */}
            <Route path="/juego" element={<Juego />} />
            <Route path="/juegopote" element={<Juegopote />} />
            <Route path="/marc" element={<Maracaibo />} />
            <Route path="/recarga" element={<Recarga />} />
            <Route path="/retiros" element={<Retiros />} />
            <Route path="/merc" element={<Merida />} />
            <Route path="/whatsaap" element={<Whatsa />} />
            <Route path="/animalitos" element={<Animalitos />} />
            {/* <Route path="/carc" element={<Caracas />} />
            <Route path="/merc" element={<Merida />} />
            <Route path="/salc" element={<Saltoangel />} />
            <Route path="/libre" element={<Libre />} /> */}
            <Route path="/libre" element={<Librenew />} />
            <Route path="/r/:referido" element={<Register />} />
            {/* <Route path="/sala4" element={<Sala4 />} />
            <Route path="/sala1" element={<Sala1 />} />
            <Route path="/sala2" element={<Sala2 />} />
            <Route path="/sala3" element={<Sala3 />} /> */}
            <Route path="/adminuser" element={<Adminuser />} />
            {/* <Route path="/modalidad1" element={<Home2 />} /> */}
            <Route path="/" element={<Home1 />} />
            <Route path="/listado" element={<Listado />} />
            <Route path="/lineal" element={<Lineal />} />
          </Routes>
        </HashRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
